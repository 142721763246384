
import * as React from "react";
import { I_SHORT_NOTE_ITEM_PROPS, I_SHORT_NOTE_ITEM_PROPS_STATE } from "../../../02_Interfaces/I_SHORT_NOTE_ITEM";
import { CupBoardCode, GetDateObjectFromString, GetDistinctListOfString, GetIconFromType, GetNoteComptaData, GetNoteCustFormData, GetNoteCustParentIdFromTitle, GoToElement, IsComptaForm, IsCustForm, IsTypeNoteIsAnCupBord, decodeUtf8 } from "../../../04_TOOLS/tools";
import { ENU_ICON_TYPE, ENU_SHARE_TYPE, I_APP_GLOBAL_DATA, I_KEY_VALUE_DATA, I_NAVIGATION_ITEM } from "../../../02_Interfaces/IGLOBAL";
import { I_NOTE_ITEM, I_TYPENOTE } from "../../../02_Interfaces/INOTE";
import { NOTE_ITEM } from "../NOTE_ITEM";
import { I_MSG_OPTION } from "../../../02_Interfaces/IMSGBOX";
import { DB_MONTH_NAME, NOTE_CUST_FORM_EMPTY_PARENT, NOTE_CUST_FORM_EMPTY_TITLE } from "../../../01_APPDATA/DB_APP";
import { SEARCHBOX } from "../SEARCHBOX";
import { CUST_FORM_BOX } from "../CUST_FORM";
import { COMPTA_FORM } from "../COMPTA_FORM";

export class SHORT_NOTE_ITEM extends React.Component<I_SHORT_NOTE_ITEM_PROPS, I_SHORT_NOTE_ITEM_PROPS_STATE> {
    constructor(props: I_SHORT_NOTE_ITEM_PROPS) {
        super(props);

        this.state = {
            colapsed: true,
            minimised: true,
            showTask: false,
            showNote: false,
            showAllParents: false,
            showView: false,
            filteredColumn: undefined,
            showNoteAnchorIndocator: false,
            //---------------------------------
            maxElementByPage: 10,
            currentPaginationIndex: 0
        }

        this.onColapseChild = this.onColapseChild.bind(this);
        this.onShowTask = this.onShowTask.bind(this);
        this.onShowNote = this.onShowNote.bind(this);
        this.onShowParent = this.onShowParent.bind(this);
        this.onShowView = this.onShowView.bind(this);
        this.onFilterColumn = this.onFilterColumn.bind(this);
        this.drag = this.drag.bind(this);
        this.getAllPagesFromResult = this.getAllPagesFromResult.bind(this);
        this.onSelectPage = this.onSelectPage.bind(this);
    }

    private ref_SearchInView = React.createRef<SEARCHBOX>();
    private ref_CustForm = React.createRef<CUST_FORM_BOX>();
    private ref_Note = React.createRef<NOTE_ITEM>();
    private CREATED_CODE: string = "CREATED_MODIFIED";
    private AUTHOR_CODE: string = "AUTHOR_EDITOR";

    onSelectPage = (pageIndex: number, withLoadNoteData?: boolean) => {

        this.setState({ currentPaginationIndex: pageIndex }, () => {

            if (this.props.GLOBAL_DATA.DB_NOTES !== undefined && this.props.note.associations !== null && this.props.GLOBAL_DATA.selectedNoteMenu === ENU_ICON_TYPE.form_parent) {
                let childs: number[] = this.props.note.associations.split(";").map(a => parseInt(a));

                let allChildItems: I_NOTE_ITEM[] = this.props.GLOBAL_DATA.DB_NOTES
                    .filter(n =>
                        (childs.filter(c => c === n.idnote).length > 0 &&
                            n.displayUnity !== 0 && IsCustForm(n.title)) || parseInt(GetNoteCustParentIdFromTitle(n.title)) === this.props.note.idnote);
                //chargement de ces notes

                if (withLoadNoteData === undefined &&
                    (withLoadNoteData !== undefined && withLoadNoteData)
                ) {
                    for (let x = 0; x < allChildItems.length; x++) {
                        if (allChildItems[x].description.length === 0 && this.props.GLOBAL_DATA.selectedNoteMenu !== undefined && this.props.GLOBAL_DATA.selectedNoteMenu === ENU_ICON_TYPE.form_parent) {
                            if (x >= this.state.currentPaginationIndex * this.state.maxElementByPage && x <= (this.state.currentPaginationIndex * this.state.maxElementByPage) + this.state.maxElementByPage)
                                (this.props.GLOBAL_FUNCTION.onLoadNoteData as Function)(allChildItems[x].idnote, true);
                        }

                    }
                }




            }
        });
    }

    getAllPagesFromResult = () => {
        let Pages: I_KEY_VALUE_DATA[] = [{
            key: 1,
            value: 1,
            data: undefined
        }];
        let count: number = 0;
        this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
            ((this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.displayUnity !== 0 && IsCustForm(n.title) && parseInt(GetNoteCustParentIdFromTitle(n.title)) !== n.idnote && parseInt(GetNoteCustParentIdFromTitle(n.title)) === this.props.note.idnote)
                .filter(f => this.ref_SearchInView.current !== null ?
                    (
                        GetNoteCustFormData(f.description as string, this.props.note.idnote).lines.filter(_l => _l.value !== undefined &&
                            this.ref_SearchInView.current !== null &&
                            _l.value.toUpperCase().indexOf(this.ref_SearchInView.current.state.searchValue.toUpperCase()) > -1

                        ).length > 0
                    )
                    : true)
                .sort((a, b) => this.props.GLOBAL_DATA.filterByLastChange !== undefined &&
                    this.props.GLOBAL_DATA.filterByLastChange ?
                    (GetDateObjectFromString(b.modified).dateObj.getTime() - GetDateObjectFromString(a.modified).dateObj.getTime())
                    :
                    GetDateObjectFromString(b.created).dateObj.getTime() - GetDateObjectFromString(a.created).dateObj.getTime())
                .sort((a, b) =>
                    this.state.filteredColumn !== undefined &&
                        (this.state.filteredColumn.key !== this.CREATED_CODE &&
                            this.state.filteredColumn.key !== this.AUTHOR_CODE) ?
                        (this.state.filteredColumn.value as boolean) ?
                            (
                                GetNoteCustFormData(this.props.note.description, this.props.note.idnote).lines.filter(_l => this.state.filteredColumn !== undefined &&
                                    _l.id === this.state.filteredColumn.key as string && _l.type === "F_NUM").length > 0 ?
                                    parseInt((GetNoteCustFormData(a.description, this.props.note.idnote).lines.filter(_l => this.state.filteredColumn !== undefined &&
                                        _l.id === this.state.filteredColumn.key)[0].value as string)) - parseInt((
                                            GetNoteCustFormData(b.description, this.props.note.idnote).lines.filter(_l => this.state.filteredColumn !== undefined &&
                                                _l.id === this.state.filteredColumn.key)[0].value as string
                                        ))
                                    :
                                    GetNoteCustFormData(this.props.note.description, this.props.note.idnote).lines.filter(_l => this.state.filteredColumn !== undefined &&
                                        _l.id === this.state.filteredColumn.key as string && _l.type === "F_NUM").length > 0 ?
                                        parseInt((GetNoteCustFormData(b.description, this.props.note.idnote).lines.filter(_l => this.state.filteredColumn !== undefined &&
                                            _l.id === this.state.filteredColumn.key)[0].value as string)) - parseInt((
                                                GetNoteCustFormData(a.description, this.props.note.idnote).lines.filter(_l => this.state.filteredColumn !== undefined &&
                                                    _l.id === this.state.filteredColumn.key)[0].value as string
                                            )) :
                                        (GetNoteCustFormData(a.description, this.props.note.idnote).lines.filter(_l => this.state.filteredColumn !== undefined &&
                                            _l.id === this.state.filteredColumn.key)[0].value as string).localeCompare(
                                                GetNoteCustFormData(b.description, this.props.note.idnote).lines.filter(_l => this.state.filteredColumn !== undefined &&
                                                    _l.id === this.state.filteredColumn.key)[0].value as string
                                            )
                            ) :
                            (GetNoteCustFormData(b.description, this.props.note.idnote).lines.filter(_l => this.state.filteredColumn !== undefined &&
                                _l.id === this.state.filteredColumn.key)[0].value as string).localeCompare(
                                    GetNoteCustFormData(a.description, this.props.note.idnote).lines.filter(_l => this.state.filteredColumn !== undefined &&
                                        _l.id === this.state.filteredColumn.key)[0].value as string
                                )
                        :
                        (
                            this.state.filteredColumn !== undefined &&
                                (this.state.filteredColumn.key as string === this.CREATED_CODE ||
                                    this.state.filteredColumn.key as string === this.AUTHOR_CODE) ?
                                (
                                    this.state.filteredColumn.key as string === this.CREATED_CODE ?

                                        (
                                            (this.state.filteredColumn.value as boolean) ?
                                                GetDateObjectFromString(a.created).dateObj.getTime() -
                                                GetDateObjectFromString(b.created).dateObj.getTime()
                                                :
                                                GetDateObjectFromString(b.created).dateObj.getTime() -
                                                GetDateObjectFromString(a.created).dateObj.getTime()
                                        )
                                        :
                                        (
                                            (this.state.filteredColumn.value as boolean) ?
                                                a.autor.localeCompare(b.autor)
                                                :
                                                b.autor.localeCompare(a.autor)
                                        )
                                )
                                :
                                a.idnote - b.idnote
                        )
                ))).forEach(n => {

                    if (count === this.state.maxElementByPage) {
                        Pages.push({
                            key: Pages.length + 1,
                            value: Pages.length + 1,
                            data: undefined
                        })

                        count = 0;
                    }

                    count++;
                });

        // console.log(Pages);

        return Pages;
    }

    onFilterColumn = (fieldId: string) => {
        if (this.state.filteredColumn !== undefined &&
            this.state.filteredColumn.key as string === fieldId) {
            this.setState({
                filteredColumn: {
                    key: this.state.filteredColumn.key,
                    value: !(this.state.filteredColumn.value as boolean),
                    data: undefined
                }
            })
        } else {
            this.setState({
                filteredColumn: {
                    key: fieldId,
                    value: true,
                    data: undefined
                }
            })
        }

        this.onSelectPage(0);
    }

    onShowView = (forceOpen?: undefined) => {
        this.setState({ showView: forceOpen !== undefined ? forceOpen : !this.state.showView }, () => {
            if (this.props.GLOBAL_DATA.DB_NOTES !== undefined && this.props.note.associations !== null &&
                this.props.GLOBAL_DATA.selectedNoteMenu === ENU_ICON_TYPE.form_parent) {
                let childs: number[] = this.props.note.associations.split(";").map(a => parseInt(a));

                let allChildItems: I_NOTE_ITEM[] = this.props.GLOBAL_DATA.DB_NOTES.filter(n =>
                    (childs.filter(c => c === n.idnote).length > 0 &&
                        n.displayUnity !== 0 && IsCustForm(n.title)) || parseInt(GetNoteCustParentIdFromTitle(n.title)) === this.props.note.idnote);

                // console.log(allChildItems);
                //chargement de ces notes

                for (let x = 0; x < allChildItems.length; x++) {
                    if (/*allChildItems[x].description.length === 0 && */this.props.GLOBAL_DATA.selectedNoteMenu !== undefined && this.props.GLOBAL_DATA.selectedNoteMenu === ENU_ICON_TYPE.form_parent) {
                        // if (x >= this.state.currentPaginationIndex * this.state.maxElementByPage && x <= (this.state.currentPaginationIndex * this.state.maxElementByPage) + this.state.maxElementByPage)
                        (this.props.GLOBAL_FUNCTION.onLoadNoteData as Function)(allChildItems[x].idnote, true);
                    }
                }


            }
        })
    }

    onShowParent = () => {
        this.setState({ showAllParents: !this.state.showAllParents })
    }

    onShowNote = () => {

        let tmpAppDATA: I_APP_GLOBAL_DATA = this.props.GLOBAL_DATA;
        if (tmpAppDATA.navigation.filter(nav => nav.noteId === this.props.note.idnote).length > 0) {
            let currentNoteNav: I_NAVIGATION_ITEM = tmpAppDATA.navigation.filter(nav => nav.noteId === this.props.note.idnote)[0];
            currentNoteNav.isNoteOpened = !currentNoteNav.isNoteOpened;
            if (this.ref_Note.current !== null && this.ref_Note.current.state.editMode) {
                this.ref_Note.current.onQuickSaveNote();
            }
            (this.props.GLOBAL_FUNCTION.onUpdateNavigationStatuts as Function)(currentNoteNav);
        } else {
            (this.props.GLOBAL_FUNCTION.onUpdateNavigationStatuts as Function)({
                noteId: this.props.note.idnote,
                isChildOpened: false,
                isNoteOpened: true
            } as I_NAVIGATION_ITEM);
            // this.setState({ showNote: !this.state.showNote }, () => {
            //     (this.props.GLOBAL_FUNCTION.onOpenedNote as Function)(this.props.note.idnote, false);
            //     if (this.props.GLOBAL_DATA.DB_TYPES_NOTES !== undefined && IsTypeNoteIsAnCupBord(this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(tn => tn.id === this.props.note.type)[0].title)) {
            //         (this.props.GLOBAL_FUNCTION.ShowCupBoardChild as Function)(this.props.note.idnote);
            //         this.onColapseChild();
            //         // this.setState({ colapsed: false });
            //     }
            // });
            GoToElement("SHORT_ITEM_" + this.props.note.idnote)
        }


        if (this.props.GLOBAL_DATA.DB_TYPES_NOTES !== undefined && IsTypeNoteIsAnCupBord(this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(tn => tn.id === this.props.note.type)[0].title)) {
            (this.props.GLOBAL_FUNCTION.ShowCupBoardChild as Function)(this.props.note.idnote);
            // this.onColapseChild();
            // (this.props.GLOBAL_FUNCTION.onOpenedNote as Function)(this.props.note.idnote, false);
        } else {
            (this.props.GLOBAL_FUNCTION.onOpenedNote as Function)(this.props.note.idnote, ((this.props.note.canBeShown !== undefined && this.props.note.canBeShown) || this.props.note.canBeShown === undefined) && this.state.minimised);
        }




    }

    onColapseChild = () => {
        if (this.props.GLOBAL_DATA.navigation.filter(nav => nav.noteId === this.props.note.idnote).length > 0) {
            let tmpAppDATA: I_APP_GLOBAL_DATA = this.props.GLOBAL_DATA;
            let currentNoteNav: I_NAVIGATION_ITEM = tmpAppDATA.navigation.filter(nav => nav.noteId === this.props.note.idnote)[0];
            currentNoteNav.isChildOpened = !currentNoteNav.isChildOpened;
            (this.props.GLOBAL_FUNCTION.onUpdateNavigationStatuts as Function)(currentNoteNav);
        } else {
            (this.props.GLOBAL_FUNCTION.onUpdateNavigationStatuts as Function)({
                noteId: this.props.note.idnote,
                isChildOpened: true,
                isNoteOpened: this.state.showNote
            } as I_NAVIGATION_ITEM);
        }

        // this.setState({ colapsed: !this.state.colapsed }, () => {
        //     (this.props.GLOBAL_FUNCTION.onUpdateNavigationStatuts as Function)({
        //         noteId: this.props.note.idnote,
        //         isChildOpened: true,
        //         isNoteOpened: true
        //     } as I_NAVIGATION_ITEM)
        // });
    }

    onShowTask = () => {
        this.setState({ showTask: !this.state.showTask });
    }

    componentDidMount(): void {
        try {
            if (this.props.note.title === "...") {
                this.setState({ showNote: true });
            }

            //Au premier chargement, filtrer sur la date de dernières modifications
            //----------------------------------------------------------------------
            if (this.props.GLOBAL_DATA.selectedNoteMenu === ENU_ICON_TYPE.form_parent)
                this.onFilterColumn(this.CREATED_CODE);


            //----------------------------------------------------------------------

            setInterval(() => {
                if (window.location.href.indexOf("NOTE_ITEM_" + this.props.note.idnote) > -1) {
                    this.setState({ showNoteAnchorIndocator: true }, () => {
                        setTimeout(() => {
                            this.setState({ showNoteAnchorIndocator: false });
                        }, 4000);
                    })
                }
            }, 1000)
        } catch (err) {
            console.log(err);
        }






    }

    componentDidUpdate(prevProps: Readonly<I_SHORT_NOTE_ITEM_PROPS>, prevState: Readonly<I_SHORT_NOTE_ITEM_PROPS_STATE>, snapshot?: any): void {
        // try {
        //     //Controle des enfants
        //     //-------------------
        //     if (this.props.note.associations !== null &&
        //         this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
        //         this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.displayUnity !== 0 && this.props.note.associations.split(";").indexOf(n.idnote.toString()) > -1 && n.title === "..." && this.state.colapsed).length > 0) {
        //         this.setState({ colapsed: !this.state.colapsed })
        //     }
        // } catch (err) {
        //     console.log(err)
        //     this.forceUpdate();
        // }



    }


    //Gestion du Drag&Drop
    //--------------------
    allowDrop(e: React.DragEvent) {
        e.preventDefault();
    }

    drag(e: React.DragEvent) {
        (this.props.GLOBAL_FUNCTION.onSelectElementToDrag as Function)(this.props.note);
    }

    drop(e: React.DragEvent) {
        e.preventDefault();
        if (this.props.GLOBAL_DATA.selectedDraggableElement !== undefined && this.props.GLOBAL_DATA.selectedDraggableElement.idnote !== this.props.note.idnote) {

            if (this.props.GLOBAL_DATA.sharedItemsWithMe.filter(si => si.canEdit &&
                (
                    (this.props.GLOBAL_DATA.selectedGroup !== undefined && si.idGroup === this.props.GLOBAL_DATA.selectedGroup.id) ||
                    (si.idType !== undefined && si.idType === this.props.note.type) ||
                    (si.idNote !== undefined && si.idNote === this.props.note.idnote)
                )
            ).length > 0 || this.props.note.isAuthor) {
                (this.props.GLOBAL_FUNCTION.onLinkItemToNote as Function)(this.props.GLOBAL_DATA.selectedDraggableElement.idnote, this.props.note);
            }



        }
    }
    //--------------------


    render(): JSX.Element {
        try {
            return <div
                id={"NOTE_ITEM_" + this.props.note.idnote}>
                {
                    //Fil d'ariane
                    //------------
                    this.props.parentNote === undefined &&
                    this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                    this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.displayUnity !== 0 && n.associations !== null && n.associations.split(";").filter(a => a.length > 0 && a !== "NaN" && this.props.note.idnote === parseInt(a)).length > 0).length > 0 &&
                    <span className="NOTE_ITEM_PARENT_PREVIEW_BOX">
                        <span title="Afficher ou masquer les parents" style={{ cursor: "pointer" }} onClick={() => {
                            this.onShowParent();
                        }} dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.reusedNote, 11) }}></span>
                        <span className="SPAN_SEP"></span>
                        {
                            this.state.showAllParents &&
                            this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                            this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.displayUnity !== 0 && n.associations !== null && (n.associations as string).split(";").filter(a => a.length > 0 && a !== "NaN" && this.props.note.idnote === parseInt(a)).length > 0).map(n => {
                                return <span onClick={() => {

                                    //Déplier les enfants
                                    //-------------------
                                    if (this.props.GLOBAL_DATA.navigation.filter(nav => nav.noteId === n.idnote).length === 0 ||
                                        this.props.GLOBAL_DATA.navigation.filter(nav => nav.noteId === n.idnote && !nav.isChildOpened).length === 0) {
                                        (this.props.GLOBAL_FUNCTION.onUpdateNavigationStatuts as Function)({
                                            noteId: n.idnote,
                                            isChildOpened: true,
                                            isNoteOpened: false
                                        } as I_NAVIGATION_ITEM)
                                    }

                                    (this.props.GLOBAL_FUNCTION.onLoadNoteAsMemory as Function)(n);
                                }} style={{
                                    backgroundColor:
                                        this.props.GLOBAL_DATA.DB_TYPES_NOTES !== undefined &&
                                            this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                            this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(tn => tn.id === n.type).length > 0 ?
                                            "#" + this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(tn => tn.id === n.type)[0].colorString as string : undefined,
                                    marginRight: "7px"
                                }} title="Aller au parent">
                                    <span className="APP_FOLDER_TABLE_BUTTON TEXT_TICKET" style={{ position: "relative", display: "inline-block", padding: "3px", top: "-2px" }}>
                                        {
                                            this.props.GLOBAL_DATA.DB_TYPES_NOTES !== undefined &&
                                            this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                            this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(tn => tn.id === n.type).length > 0 &&
                                            IsTypeNoteIsAnCupBord(this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(tn => tn.id === n.type)[0].title) &&
                                            <span>
                                                <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.cupBoard, 11) }}></span>
                                                <span className="SPAN_SEP"></span>
                                            </span>
                                        }

                                        {
                                            IsCustForm(n.title) &&
                                            <span>
                                                <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.form_child, 11) }}></span>
                                                <span className="SPAN_SEP"></span>
                                            </span>
                                        }

                                        <span>
                                            {
                                                this.props.GLOBAL_DATA.DB_TYPES_NOTES !== undefined &&
                                                (IsTypeNoteIsAnCupBord(this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(tn => tn.id === n.type)[0].title) ?
                                                    this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(tn => tn.id === n.type)[0].title.replace(CupBoardCode, "") :
                                                    this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(tn => tn.id === n.type)[0].title)
                                            }
                                        </span>
                                        <span className="SPAN_SEP"></span>
                                        <span>{
                                            IsCustForm(n.title) ?
                                                n.title.split("]-")[1] :
                                                n.title
                                        }</span>
                                    </span>
                                </span>
                            })
                        }

                        {
                            !this.state.showAllParents &&
                            <span className="APP_FOLDER_TABLE_BUTTON" onClick={() => {
                                this.onShowParent();
                            }} title="Afficher les parents">

                                <span>{
                                    this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.displayUnity !== 0 && n.associations !== null && n.associations.split(";").filter(a => a.length > 0 && a !== "NaN" && this.props.note.idnote === parseInt(a)).length > 0).length
                                }</span>
                                <span className="SPAN_SEP">

                                </span>
                                <span>
                                    Parent
                                    {
                                        this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.displayUnity !== 0 && n.associations !== null && n.associations.split(";").filter(a => a.length > 0 && a !== "NaN" && this.props.note.idnote === parseInt(a)).length > 0).length > 1 ? "s" : ""
                                    }
                                </span>
                            </span>
                        }
                    </span>
                }



                {
                    //La note et ses fonctionnalités
                    //------------------------------
                    this.props.GLOBAL_DATA.DB_TYPES_NOTES !== undefined &&
                    this.props.note.canBeShown !== undefined &&
                    this.state.minimised &&
                    <div>
                        <div >
                            <div
                                className='APP_NOTE_ITEM_BOX'
                                id={"SHORT_ITEM_" + this.props.note.idnote}
                                draggable={true}
                                onDrop={(e) => {
                                    this.drop(e);
                                }} onDragOver={(e) => {
                                    this.allowDrop(e);

                                }}
                                onDragStart={(e) => {
                                    e.dataTransfer.setData("text", (e.target as HTMLDivElement).id);
                                    this.drag(e);
                                }}>
                                {
                                    //menu d'action du type si mode normal
                                    !this.props.GLOBAL_DATA.linkItemMode &&
                                    // !this.state.showNote &&
                                    (this.props.showOnly === undefined || (this.props.showOnly !== undefined && !this.props.showOnly)) &&
                                    <div className='SN_CONTEXTUEL_MENU' >
                                        <span>

                                            {
                                                (
                                                    (
                                                        this.props.GLOBAL_DATA.selectedNoteMenu !== ENU_ICON_TYPE.dash &&
                                                        this.props.GLOBAL_DATA.sharedItemsWithMe.filter(siwm =>
                                                            this.props.GLOBAL_DATA.selectedGroup !== undefined &&
                                                            siwm.canEdit &&
                                                            siwm.idGroup === this.props.GLOBAL_DATA.selectedGroup.id &&
                                                            (
                                                                (
                                                                    (siwm.idNote !== null && siwm.idNote !== undefined && siwm.idNote === this.props.note.idnote) &&
                                                                    (siwm.idType === null || siwm.idType === undefined)
                                                                ) ||
                                                                (
                                                                    (siwm.idNote === null || siwm.idNote === undefined) &&
                                                                    (siwm.idType !== null && siwm.idType !== undefined && this.props.note.type === siwm.idType)
                                                                ) ||
                                                                (
                                                                    (siwm.idNote === null || siwm.idNote === undefined) &&
                                                                    (siwm.idType === null || siwm.idType === undefined)
                                                                )
                                                            )
                                                        ).length > 0
                                                    )
                                                    ||
                                                    (this.props.GLOBAL_DATA.DB_GROUPS !== undefined &&
                                                        this.props.GLOBAL_DATA.selectedNoteMenu !== ENU_ICON_TYPE.dash &&
                                                        this.props.GLOBAL_DATA.DB_GROUPS.filter(g => this.props.GLOBAL_DATA.selectedGroup !== undefined &&
                                                            g.id === this.props.GLOBAL_DATA.selectedGroup.id && g.isAuthor === 1).length > 0)
                                                ) === true &&
                                                <div title={"Ajouter la note '" + this.props.note.title + "' au classeur."} className='APP_NOTE_MENU_ITEM' dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.cupBoard, 11) }} onClick={() => {
                                                    (this.props.GLOBAL_FUNCTION.onPutNoteInCupBoard as Function)(this.props.note.idnote)
                                                }}></div>
                                            }



                                            {
                                                (
                                                    (
                                                        this.props.GLOBAL_DATA.sharedItemsWithMe.filter(siwm =>
                                                            this.props.GLOBAL_DATA.selectedGroup !== undefined &&
                                                            this.props.GLOBAL_DATA.selectedNoteMenu !== ENU_ICON_TYPE.dash &&
                                                            siwm.canEdit &&
                                                            siwm.idGroup === this.props.GLOBAL_DATA.selectedGroup.id &&
                                                            (
                                                                (
                                                                    (siwm.idNote !== null && siwm.idNote !== undefined && siwm.idNote === this.props.note.idnote) &&
                                                                    (siwm.idType === null || siwm.idType === undefined)
                                                                ) ||
                                                                (
                                                                    (siwm.idNote === null || siwm.idNote === undefined) &&
                                                                    (siwm.idType !== null && siwm.idType !== undefined && this.props.note.type === siwm.idType)
                                                                ) ||
                                                                (
                                                                    (siwm.idNote === null || siwm.idNote === undefined) &&
                                                                    (siwm.idType === null || siwm.idType === undefined)
                                                                )
                                                            )
                                                        ).length > 0
                                                    )
                                                    ||
                                                    (this.props.GLOBAL_DATA.DB_GROUPS !== undefined &&
                                                        this.props.GLOBAL_DATA.selectedNoteMenu !== ENU_ICON_TYPE.dash &&
                                                        this.props.GLOBAL_DATA.DB_GROUPS.filter(g => this.props.GLOBAL_DATA.selectedGroup !== undefined &&
                                                            g.id === this.props.GLOBAL_DATA.selectedGroup.id && g.isAuthor === 1).length > 0)
                                                ) === true && (IsCustForm(this.props.note.title) ? !GetNoteCustFormData(this.props.note.description, this.props.note.idnote).parent : true) &&
                                                <div title={"Lier la note '" + this.props.note.title + "' avec des notes enfants."} className='APP_NOTE_MENU_ITEM' dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.linkItemMode, 11) }} onClick={() => {
                                                    (this.props.GLOBAL_FUNCTION.onLinkItemMode as Function)(this.props.note)
                                                }}></div>
                                            }

                                            {
                                                this.props.parentNote !== undefined &&
                                                (this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                                    this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.displayUnity !== 0 && n.idnote === this.props.parentNote).length > 0 &&
                                                    this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.displayUnity !== 0 && n.idnote === this.props.parentNote)[0].associations !== null &&
                                                    this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.displayUnity !== 0 && n.idnote === this.props.parentNote)[0].associations.split(";").filter(a => a.length > 0 && parseInt(a) === this.props.note.idnote).length > 0) &&
                                                (
                                                    (
                                                        this.props.GLOBAL_DATA.sharedItemsWithMe.filter(siwm =>
                                                            this.props.GLOBAL_DATA.selectedGroup !== undefined &&
                                                            siwm.canEdit &&
                                                            siwm.idGroup === this.props.GLOBAL_DATA.selectedGroup.id &&
                                                            (
                                                                (
                                                                    (siwm.idNote !== null && siwm.idNote !== undefined && siwm.idNote === this.props.note.idnote) &&
                                                                    (siwm.idType === null || siwm.idType === undefined)
                                                                ) ||
                                                                (
                                                                    (siwm.idNote === null || siwm.idNote === undefined) &&
                                                                    (siwm.idType !== null && siwm.idType !== undefined && this.props.note.type === siwm.idType)
                                                                ) ||
                                                                (
                                                                    (siwm.idNote === null || siwm.idNote === undefined) &&
                                                                    (siwm.idType === null || siwm.idType === undefined)
                                                                )
                                                            )
                                                        ).length > 0
                                                    )
                                                    ||
                                                    (this.props.GLOBAL_DATA.DB_GROUPS !== undefined &&
                                                        this.props.GLOBAL_DATA.selectedNoteMenu !== ENU_ICON_TYPE.dash &&
                                                        this.props.GLOBAL_DATA.DB_GROUPS.filter(g => this.props.GLOBAL_DATA.selectedGroup !== undefined &&
                                                            g.id === this.props.GLOBAL_DATA.selectedGroup.id && g.isAuthor === 1).length > 0)
                                                ) === true &&
                                                <div title={"Délier la note '" + this.props.note.title + "' de son parent."} className='APP_NOTE_MENU_ITEM' dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.unLinkedItem, 11) }} onClick={() => {
                                                    let tmpMSG: I_MSG_OPTION = {
                                                        allMSG: [
                                                            {
                                                                key: "Délier la note ?",
                                                                value: "Voulez-vous vraiment délier la note?",
                                                                data: ENU_ICON_TYPE.unLinkedItem
                                                            },
                                                        ],
                                                        showCloseButton: true,
                                                        buttons: [
                                                            {
                                                                title: "Délier",
                                                                F_BUTTON_ACTION: () => {
                                                                    if (this.props.GLOBAL_DATA.selectedNoteToLink !== undefined) {
                                                                        (this.props.GLOBAL_FUNCTION.onLinkItemToNote as Function)(this.props.note.idnote, this.props.GLOBAL_DATA.selectedNoteToLink);

                                                                    } else if (this.props.parentNote !== undefined && this.props.GLOBAL_DATA.DB_NOTES !== undefined && this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.displayUnity !== 0 && this.props.parentNote === n.idnote).length > 0) {
                                                                        (this.props.GLOBAL_FUNCTION.onLinkItemToNote as Function)(this.props.note.idnote, this.props.GLOBAL_DATA.DB_NOTES.filter(n => this.props.parentNote === n.idnote)[0]);

                                                                    }
                                                                    (this.props.GLOBAL_FUNCTION.closePopup as Function)();
                                                                }
                                                            }
                                                        ]
                                                    };

                                                    (this.props.GLOBAL_FUNCTION.showMessage as Function)(tmpMSG);

                                                }}></div>
                                            }


                                            {
                                                (
                                                    (
                                                        this.props.GLOBAL_DATA.sharedItemsWithMe.filter(siwm =>
                                                            this.props.GLOBAL_DATA.selectedGroup !== undefined &&
                                                            this.props.GLOBAL_DATA.selectedNoteMenu !== ENU_ICON_TYPE.dash &&
                                                            siwm.canEdit &&
                                                            siwm.idGroup === this.props.GLOBAL_DATA.selectedGroup.id &&
                                                            (
                                                                (
                                                                    (siwm.idNote !== null && siwm.idNote !== undefined && siwm.idNote === this.props.note.idnote) &&
                                                                    (siwm.idType === null || siwm.idType === undefined)
                                                                ) ||
                                                                (
                                                                    (siwm.idNote === null || siwm.idNote === undefined) &&
                                                                    (siwm.idType !== null && siwm.idType !== undefined && this.props.note.type === siwm.idType)
                                                                ) ||
                                                                (
                                                                    (siwm.idNote === null || siwm.idNote === undefined) &&
                                                                    (siwm.idType === null || siwm.idType === undefined)
                                                                )
                                                            )
                                                        ).length > 0
                                                    )
                                                    ||
                                                    (this.props.GLOBAL_DATA.DB_GROUPS !== undefined &&
                                                        this.props.GLOBAL_DATA.selectedNoteMenu !== ENU_ICON_TYPE.dash &&
                                                        this.props.GLOBAL_DATA.DB_GROUPS.filter(g => this.props.GLOBAL_DATA.selectedGroup !== undefined &&
                                                            g.id === this.props.GLOBAL_DATA.selectedGroup.id && g.isAuthor === 1).length > 0)
                                                ) === true && (parseInt(GetNoteCustParentIdFromTitle(this.props.note.title)) !== this.props.note.idnote) &&
                                                <div
                                                    title={"Ajouter une note enfant à la note '" + this.props.note.title + "'"}
                                                    className='APP_NOTE_MENU_ITEM' dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.newItem, 11) }} 
                                                    onClick={() => {
                                                        
                                                        
                                                        (this.props.GLOBAL_FUNCTION.addNewNoteInGroup as Function)(undefined, this.props.note);
                                                        
                                                    }}></div>
                                            }

                                            {
                                                <div title="Mettre la note en évidence..." className='APP_NOTE_MENU_ITEM' dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.PutInFront, 11) }}
                                                    onClick={() => {
                                                        (this.props.GLOBAL_FUNCTION.onLoadNoteAsMemory as Function)(
                                                            {
                                                                idnote: this.props.note.idnote,
                                                                idgroupnote: this.props.note.idgroupnote,
                                                                title: this.props.note.title,
                                                                associations: this.props.note.associations
                                                            } as I_NOTE_ITEM
                                                        )
                                                    }}>

                                                </div>
                                            }



                                        </span>
                                        <span>

                                            {
                                                this.props.GLOBAL_DATA.DB_GROUPS !== undefined &&
                                                this.props.GLOBAL_DATA.selectedNoteMenu !== ENU_ICON_TYPE.dash &&
                                                (
                                                    this.props.GLOBAL_DATA.DB_GROUPS.filter(g => this.props.note.idgroupnote === g.id && g.isAuthor).length > 0
                                                ) &&
                                                <div
                                                    title={"Partager la note '" + this.props.note.title + "'"}
                                                    className='APP_NOTE_MENU_ITEM' dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.community, 11) }} onClick={() => {
                                                        (this.props.GLOBAL_FUNCTION.onShowShareBox as Function)(ENU_SHARE_TYPE.note, this.props.note)
                                                    }}></div>
                                            }


                                            {
                                                this.props.GLOBAL_DATA.selectedNoteMenu !== ENU_ICON_TYPE.dash &&
                                                <div
                                                    title={this.props.GLOBAL_DATA.localMemory.filter(lm => lm.idNote !== undefined && this.props.note.idnote === lm.idNote).length > 0 ?
                                                        "Supprimer la mémoire locale associée." :
                                                        "Mettre en mémoire temporaire la note '" + this.props.note.title + "'"}
                                                    className='APP_NOTE_MENU_ITEM' dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.saveAsLocal, 11) }} onClick={() => {
                                                        this.props.GLOBAL_DATA.localMemory.filter(lm => lm.idNote !== undefined && this.props.note.idnote === lm.idNote).length > 0 ?
                                                            (this.props.GLOBAL_FUNCTION.OnDeleteLocalCurrentMemory as Function)(this.props.GLOBAL_DATA.localMemory.filter(lm => lm.idNote !== undefined && this.props.note.idnote === lm.idNote)[0].id) :
                                                            (this.props.GLOBAL_FUNCTION.onSaveLocalMemory as Function)(this.props.note)
                                                    }}></div>
                                            }

                                            {
                                                this.props.GLOBAL_DATA.selectedNoteMenu !== ENU_ICON_TYPE.dash &&
                                                <div
                                                    title={this.props.note.pined ? "Retirer le marqueur" : "Ajouter un marqueur"}
                                                    className='APP_NOTE_MENU_ITEM' dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.markBook, 11) }} onClick={() => {
                                                        (this.props.GLOBAL_FUNCTION.piningNote as Function)(this.props.note.idnote, !this.props.note.pined)
                                                    }}></div>
                                            }

                                            <span className="SPAN_SEP">

                                            </span>
                                            {
                                                (
                                                    (
                                                        this.props.GLOBAL_DATA.sharedItemsWithMe.filter(siwm =>
                                                            this.props.GLOBAL_DATA.selectedGroup !== undefined &&
                                                            siwm.canEdit &&
                                                            siwm.idGroup === this.props.GLOBAL_DATA.selectedGroup.id &&
                                                            (
                                                                (
                                                                    (siwm.idNote !== null && siwm.idNote !== undefined && siwm.idNote === this.props.note.idnote) &&
                                                                    (siwm.idType === null || siwm.idType === undefined)
                                                                ) ||
                                                                (
                                                                    (siwm.idNote === null || siwm.idNote === undefined) &&
                                                                    (siwm.idType !== null && siwm.idType !== undefined && this.props.note.type === siwm.idType)
                                                                ) ||
                                                                (
                                                                    (siwm.idNote === null || siwm.idNote === undefined) &&
                                                                    (siwm.idType === null || siwm.idType === undefined)
                                                                )
                                                            )
                                                        ).length > 0
                                                    )
                                                    ||
                                                    (this.props.GLOBAL_DATA.DB_GROUPS !== undefined &&
                                                        this.props.GLOBAL_DATA.DB_GROUPS.filter(g => this.props.GLOBAL_DATA.selectedGroup !== undefined &&
                                                            g.id === this.props.GLOBAL_DATA.selectedGroup.id && g.isAuthor === 1).length > 0)
                                                ) === true &&
                                                <div
                                                    title={"Supprimer la note '" + this.props.note.title + "'"}
                                                    className='APP_NOTE_MENU_ITEM' dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.dash, 11) }} onClick={() => {
                                                        (this.props.GLOBAL_FUNCTION.deleteNote as Function)(this.props.note)
                                                    }}></div>
                                            }

                                        </span>

                                    </div>
                                }

                                {
                                    //menu d'action du type si mode liaison
                                    this.props.GLOBAL_DATA.linkItemMode &&
                                    this.props.GLOBAL_DATA.selectedNoteMenu !== ENU_ICON_TYPE.dash &&
                                    (
                                        this.props.GLOBAL_DATA.navigation.filter(nav => nav.noteId === this.props.note.idnote).length > 0 ?
                                            this.props.GLOBAL_DATA.navigation.filter(nav => nav.noteId === this.props.note.idnote)[0].isNoteOpened
                                            : false
                                    ) &&
                                    // !this.state.showNote &&
                                    (this.props.showOnly === undefined || (this.props.showOnly !== undefined && !this.props.showOnly)) &&
                                    <div className='TN_CONTEXTUEL_MENU' style={{ top: "28px" }}>

                                        <div className='APP_NOTE_MENU_ITEM' dangerouslySetInnerHTML={{
                                            __html: GetIconFromType(
                                                this.props.GLOBAL_DATA.selectedNoteToLink !== undefined &&
                                                    this.props.GLOBAL_DATA.selectedNoteToLink.idnote === this.props.note.idnote ?
                                                    ENU_ICON_TYPE.principalItem :
                                                    (
                                                        this.props.GLOBAL_DATA.selectedNoteToLink !== undefined &&
                                                            this.props.GLOBAL_DATA.selectedNoteToLink.idnote !== this.props.note.idnote &&
                                                            this.props.GLOBAL_DATA.selectedNoteToLink.associations !== null &&
                                                            ((this.props.GLOBAL_DATA.selectedNoteToLink.associations as any) as string).split(";").filter(a => a.length > 0 && parseInt(a) === this.props.note.idnote).length > 0 ?
                                                            ENU_ICON_TYPE.linkedItem : ENU_ICON_TYPE.unLinkedItem
                                                    )

                                                , 11
                                            )
                                        }} onClick={() => {
                                            if (this.props.GLOBAL_DATA.selectedNoteToLink !== undefined) {
                                                (this.props.GLOBAL_FUNCTION.onLinkItemToNote as Function)(this.props.note.idnote, this.props.GLOBAL_DATA.selectedNoteToLink);
                                            }

                                        }}></div>

                                    </div>
                                }





                                <span style={{ cursor: "pointer", fontSize: "26px", color: "#000000", fontWeight: "600" }}>




                                    {
                                        this.props.GLOBAL_DATA.DB_TYPES_NOTES !== undefined &&
                                        this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(tn => tn.id === this.props.note.type).length > 0 &&
                                        <span className='APP_NO_FOLDER_SELECTED_RESULT_TYPE' style={{
                                            backgroundColor: "#" + this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(tn => tn.id === this.props.note.type)[0].colorString,
                                            top: this.state.showNote ? "0px" : "initial"
                                        }}>


                                            <span className='TEXT_TICKET'>

                                                <span
                                                    title={
                                                        !this.state.showNote ? "Cliquez pour ouvrir la note ..." : "Cliquez pour fermer la note ..."
                                                    }
                                                    onClick={() => {
                                                        if (this.props.showOnly === undefined ||
                                                            (this.props.showOnly !== undefined && !this.props.showOnly)) {
                                                            this.onShowNote()
                                                        } else {

                                                            let tmpMSGOption: I_MSG_OPTION = {
                                                                allMSG: [
                                                                    {
                                                                        key: "Que voulez-vous faire ?",
                                                                        value: "Voulez-vous prévisualiser la note, ou simplement aller à la note",
                                                                        data: ENU_ICON_TYPE.information
                                                                    }
                                                                ],
                                                                buttons: [
                                                                    {
                                                                        title: "Aller à la note",
                                                                        F_BUTTON_ACTION: () => {
                                                                            (this.props.GLOBAL_FUNCTION.onLoadNoteAsMemory as Function)(this.props.note);
                                                                            (this.props.GLOBAL_FUNCTION.closePopup as Function)();

                                                                        }
                                                                    },
                                                                    {
                                                                        title: "Prévisualiser la note",
                                                                        F_BUTTON_ACTION: () => {
                                                                            (this.props.GLOBAL_FUNCTION.onPreviewNote as Function)(this.props.note);
                                                                        }
                                                                    }
                                                                ],
                                                                showCloseButton: true
                                                            };

                                                            (this.props.GLOBAL_FUNCTION.showMessage as Function)(tmpMSGOption);


                                                        }
                                                    }}
                                                    className="NOTE_TITLE_LINE"
                                                >

                                                    {
                                                        !this.state.showNote &&
                                                        <span className="APP_NOTE_ITEM_INDICATOR_ANCHOR" dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.anchorIndocator, 22, "EB6B16") }} style={{ opacity: this.state.showNoteAnchorIndocator ? "1" : "0" }}></span>

                                                    }



                                                    {
                                                        this.props.note.created !== undefined &&
                                                        <span title={this.props.note.created} className={this.props.parentNote !== undefined || this.props.asChild ? "APP_NOTE_ITEM_DATE_CHILD" : 'APP_NOTE_ITEM_DATE'}>

                                                            {
                                                                (this.props.note.created !== this.props.note.modified && this.props.GLOBAL_DATA.filterByLastChange !== undefined && this.props.GLOBAL_DATA.filterByLastChange && this.props.note.modified !== null && this.props.note.created !== null ?
                                                                    <span>
                                                                        {
                                                                            GetDateObjectFromString(this.props.note.modified).date + " " +
                                                                            (DB_MONTH_NAME[parseInt(GetDateObjectFromString(this.props.note.modified).month) - 1]).substring(0, 3) + ". "
                                                                        }

                                                                        <div>
                                                                            {
                                                                                GetDateObjectFromString(this.props.note.modified).year
                                                                            }
                                                                        </div>
                                                                    </span> :
                                                                    <span>
                                                                        {
                                                                            GetDateObjectFromString(this.props.note.created).date + " " +
                                                                            (DB_MONTH_NAME[parseInt(GetDateObjectFromString(this.props.note.created).month) - 1]).substring(0, 3) + ". "
                                                                        }
                                                                        <div>
                                                                            {
                                                                                GetDateObjectFromString(this.props.note.created).year
                                                                            }
                                                                        </div>
                                                                    </span>
                                                                )
                                                            }
                                                        </span>
                                                    }

                                                    {
                                                        this.props.note.displayUnity === 0 || this.props.note.displayUnity === 3 &&
                                                        <span>
                                                            <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.dash, 11) }}></span>
                                                            <span className="SPAN_SEP"></span>
                                                        </span>
                                                    }

                                                    {
                                                        (this.props.note.pined as any).toString() === "true" &&
                                                        <span>
                                                            <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.markBook, 11) }}></span>
                                                            <span className="SPAN_SEP"></span>
                                                        </span>
                                                    }

                                                    {
                                                        this.props.GLOBAL_DATA.localMemory.filter(lm => lm.idNote !== undefined && this.props.note.idnote === lm.idNote).length > 0 &&
                                                        <span style={{ cursor: "pointer" }}>
                                                            <span
                                                                title="Cliquez pour supprimer la mémoir locale associée."
                                                                dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.saveAsLocal, 18) }} onClick={() => {
                                                                    (this.props.GLOBAL_FUNCTION.OnDeleteLocalCurrentMemory as Function)(this.props.GLOBAL_DATA.localMemory.filter(lm => lm.idNote !== undefined && this.props.note.idnote === lm.idNote)[0].id)
                                                                }}></span>
                                                            <span className="SPAN_SEP"></span>
                                                        </span>
                                                    }

                                                    {
                                                        //Affichage de l'indi ateur de note de type COMPTA
                                                        //------------------------------------------------
                                                        IsComptaForm(this.props.note.description) &&
                                                        <span>
                                                            <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.euroDevise, 16) }}></span>
                                                            <span className="SPAN_SEP">
                                                            </span>
                                                        </span>
                                                    }

                                                    {
                                                        //Affichage de l'indi ateur de note de type form parent
                                                        //------------------------------------------------
                                                        IsCustForm(this.props.note.title) &&
                                                        this.props.note.title.indexOf(NOTE_CUST_FORM_EMPTY_PARENT.replace("PARENTNOTEID", this.props.note.idnote.toString())) > -1 &&
                                                        <span>
                                                            <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.form_parent, 16) }}></span>
                                                            <span className="SPAN_SEP">
                                                            </span>
                                                        </span>
                                                    }

                                                    {
                                                        //Affichage de l'indi ateur de note de type form parent
                                                        //------------------------------------------------
                                                        IsCustForm(this.props.note.title) &&
                                                        this.props.note.title.indexOf(NOTE_CUST_FORM_EMPTY_PARENT.replace("PARENTNOTEID", this.props.note.idnote.toString())) < 0 &&
                                                        <span>
                                                            <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.form_child, 16) }}></span>
                                                            <span className="SPAN_SEP">
                                                            </span>
                                                        </span>
                                                    }

                                                    {
                                                        //Affichage de l'indicateur de note de type vidéo
                                                        //------------------------------------------------
                                                        this.props.GLOBAL_DATA.DB_NOTES_VIDEO !== undefined &&
                                                        this.props.GLOBAL_DATA.DB_NOTES_VIDEO.filter(v => v === this.props.note.idnote).length > 0 &&
                                                        <span>
                                                            <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.video, 16) }}></span>
                                                            <span className="SPAN_SEP">
                                                            </span>
                                                        </span>
                                                    }

                                                    {
                                                        IsTypeNoteIsAnCupBord(this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(tn => tn.id === this.props.note.type)[0].title) &&
                                                        <span>
                                                            <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.cupBoard, 11) }}></span>
                                                            <span className='SPAN_SEP'></span>
                                                        </span>
                                                    }


                                                    {
                                                        // !this.state.showNote &&
                                                        <span >

                                                            <span style={{ fontSize: "26px", color:"#7C7C7C" }}>{
                                                                this.props.note.title !== undefined &&

                                                                (IsCustForm(this.props.note.title) ?
                                                                    (this.props.note.title.replace((NOTE_CUST_FORM_EMPTY_TITLE +
                                                                        (NOTE_CUST_FORM_EMPTY_PARENT.replace("PARENTNOTEID", GetNoteCustParentIdFromTitle(this.props.note.title).toString()))), "")) :
                                                                    this.props.note.title)
                                                            }</span>

                                                            <span className='SPAN_SEP'></span>

                                                        </span>
                                                    }



                                                    <span style={{ fontWeight: "bold", fontStyle: "italic" }}>
                                                        <span className="APP_NOTE_TYPE_INFO_TITLE">
                                                            {
                                                                IsTypeNoteIsAnCupBord(this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(tn => tn.id === this.props.note.type)[0].title) ?
                                                                    this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(tn => tn.id === this.props.note.type)[0].title.replace(CupBoardCode, "") :
                                                                    this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(tn => tn.id === this.props.note.type)[0].title
                                                            }
                                                        </span>
                                                        <span className="SPAN_SEP"></span>
                                                        <span className="APP_NOTE_INFO_ZONE"  >
                                                            <span>
                                                                <span>
                                                                    {
                                                                        this.props.note.created !== this.props.note.modified ?
                                                                            this.props.note.editor :
                                                                            this.props.note.autor

                                                                    }
                                                                    <span className="SPAN_SEP"></span>
                                                                    <span>
                                                                        {this.props.note.modified}
                                                                    </span>

                                                                    {
                                                                        this.props.note.isAuthor ?
                                                                            <span>
                                                                                <span className="SPAN_SEP"></span>
                                                                                <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.authorItem, 11) }}></span>
                                                                            </span>
                                                                            :
                                                                            (
                                                                                (
                                                                                    this.props.GLOBAL_DATA.sharedItemsWithMe.filter(siwm =>
                                                                                        this.props.GLOBAL_DATA.selectedGroup !== undefined &&
                                                                                        siwm.canEdit &&
                                                                                        siwm.idGroup === this.props.GLOBAL_DATA.selectedGroup.id &&
                                                                                        (
                                                                                            (
                                                                                                (siwm.idNote !== null && siwm.idNote !== undefined && siwm.idNote === this.props.note.idnote) &&
                                                                                                (siwm.idType !== null && siwm.idType !== undefined && this.props.note.type === siwm.idType)
                                                                                            ) ||
                                                                                            (
                                                                                                (siwm.idNote === null || siwm.idNote === undefined) &&
                                                                                                (siwm.idType === null || siwm.idType === undefined)
                                                                                            )
                                                                                        )
                                                                                    ).length > 0
                                                                                )
                                                                                ||
                                                                                (this.props.GLOBAL_DATA.DB_GROUPS !== undefined &&
                                                                                    this.props.GLOBAL_DATA.DB_GROUPS.filter(g => this.props.GLOBAL_DATA.selectedGroup !== undefined &&
                                                                                        g.id === this.props.GLOBAL_DATA.selectedGroup.id && g.isAuthor === 1).length > 0)
                                                                            ) === true &&
                                                                            <span>
                                                                                <span className="SPAN_SEP"></span>
                                                                                <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.editor, 11) }}></span>
                                                                            </span>
                                                                    }

                                                                    {
                                                                        (this.props.GLOBAL_DATA.allNotifs.filter(notif =>
                                                                            notif.typeId === this.props.note.type && notif.noteId === this.props.note.idnote
                                                                        ).length > 0) &&
                                                                        <span>
                                                                            <span className="SPAN_SEP" ></span>
                                                                            <span className="TN_MENU_SELECTED_INDICATOR" style={{ position: "relative", display: "inline-block", height: "16px", width: "16px", top: "0px", left: "0px" }}>
                                                                                <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.notif, 14, "ffffff") }}>

                                                                                </span>
                                                                            </span>
                                                                        </span>
                                                                    }





                                                                </span>
                                                            </span>

                                                        </span>
                                                    </span>

                                                    {
                                                        //Indicateur de présence d'une nouvelle note (délais 3jrs)
                                                        //--------------------------------------------
                                                        <span className="APP_NOTE_NEW_ITEM_INDICATOR" >

                                                            {
                                                                (GetDateObjectFromString(this.props.note.created).dateObj.setDate(GetDateObjectFromString(this.props.note.created).dateObj.getDate() + 3)) >
                                                                (new Date()).getTime() &&
                                                                <span title="Créée il y a moins de 3 jours." dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.newElement, 18, "1a7411") }}></span>
                                                            }

                                                            {
                                                                (this.props.note.title === "..." ||
                                                                    (
                                                                        this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                                                        this.props.GLOBAL_DATA.DB_NOTES.filter(n =>
                                                                            IsCustForm(this.props.note.title) &&
                                                                            parseInt(GetNoteCustParentIdFromTitle(this.props.note.title)) === n.idnote && n.title === this.props.note.title &&
                                                                            this.props.GLOBAL_DATA.selectedNoteMenu !== ENU_ICON_TYPE.form_parent
                                                                        ).length > 0
                                                                    )) &&
                                                                <span>
                                                                    &nbsp;
                                                                    <span title="La notes est créée mais n'a pas encore modifée." dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.information, 16, "E3630E") }}></span>
                                                                </span>
                                                            }

                                                            {
                                                                this.props.GLOBAL_DATA.allLockedNotes.filter(
                                                                    (notif) =>
                                                                        notif.noteId === this.props.note.idnote && !notif.locker
                                                                ).length > 0 &&
                                                                <span>
                                                                    &nbsp;
                                                                    <span title="La note est vérouillé pour le moment." dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.lock, 16, "E3630E") }}></span>
                                                                </span>
                                                            }

                                                            {
                                                                this.props.GLOBAL_DATA.allLockedNotes.filter(
                                                                    (notif) =>
                                                                        notif.noteId === this.props.note.idnote && notif.locker
                                                                ).length > 0 &&
                                                                <span>
                                                                    &nbsp;
                                                                    <span title="Vous avez " dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.authorItem, 16, "E3630E") }}></span>
                                                                </span>
                                                            }
                                                        </span>
                                                    }

                                                </span>

                                                {
                                                    
                                                    <div title="Cliquez sur le titre de la note pour l'ouvir ou la fermer" className="NOTE_PARAMS_ZONE" style={{ minHeight: "3px" }}>
                                                    {
                                                        ((!this.props.GLOBAL_DATA.loading &&
                                                            !this.props.GLOBAL_DATA.loadingPendingData &&
                                                            this.props.GLOBAL_DATA.DB_ATTACHMENT_LINKS !== undefined &&
                                                            this.props.GLOBAL_DATA.DB_ATTACHMENT_LINKS.filter(a => a.nodeId === this.props.note.idnote).length > 0) ||
                                                            this.props.note.tags.split(";").filter(t => t.length > 0).length > 0) &&
                                                        <div className='TEXT_TICKET' style={{ display: "block" }}>

                                                            {
                                                                !this.state.showNote &&
                                                                (this.props.showOnly !== undefined ? (this.props.showOnly) : true) &&
                                                                this.props.GLOBAL_DATA.DB_ATTACHMENT_LINKS !== undefined &&
                                                                this.props.GLOBAL_DATA.DB_ATTACHMENT_LINKS.filter(a => a.nodeId === this.props.note.idnote).length > 0 &&
                                                                <span title={
                                                                    this.props.GLOBAL_DATA.DB_ATTACHMENT_LINKS.filter(a => a.nodeId === this.props.note.idnote).map(a => { return a.title }).toString()
                                                                }>
                                                                    <span>{
                                                                        this.props.GLOBAL_DATA.DB_ATTACHMENT_LINKS.filter(a => a.nodeId === this.props.note.idnote).length
                                                                    }</span>
                                                                    <span className="SPAN_SEP"></span>
                                                                    <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.attachment, 11) }}></span>
                                                                    &nbsp;&nbsp;&nbsp;
                                                                </span>
                                                            }


                                                            {
                                                                this.props.note.tags.split(";").filter(t => t.length > 0).length > 0 &&
                                                                (this.props.showOnly !== undefined ? (this.props.showOnly) : true) &&
                                                                !this.state.showNote &&
                                                                <span title={
                                                                    this.props.note.tags.split(";").filter(t => t.length > 0).map(t => { return t }).toString()
                                                                }>
                                                                    <span>{
                                                                        this.props.note.tags.split(";").filter(t => t.length > 0).length
                                                                    }</span>
                                                                    <span className="SPAN_SEP"></span>
                                                                    <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.tag, 11) }}></span>
                                                                </span>
                                                            }


                                                            {
                                                                // this.state.showNote &&
                                                                (this.props.showOnly !== undefined ? (this.props.showOnly) : true) &&
                                                                this.props.GLOBAL_DATA.DB_ATTACHMENT_LINKS !== undefined &&
                                                                this.props.GLOBAL_DATA.DB_ATTACHMENT_LINKS.filter(a => a.nodeId === this.props.note.idnote).length > 0 &&
                                                                <div>
                                                                    <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.attachment, 11) }}></span>
                                                                    {
                                                                        this.props.GLOBAL_DATA.DB_ATTACHMENT_LINKS !== undefined &&
                                                                        this.props.GLOBAL_DATA.DB_ATTACHMENT_LINKS.filter(a => a.nodeId === this.props.note.idnote).map(a => {
                                                                            return <div className="APP_NOTE_PJ_ITEM_BOX" key={"assoc_" + a.id}>
                                                                                <div className="APP_NOTE_PJ_ITEM" onClick={() => {
                                                                                    (this.props.GLOBAL_FUNCTION.showAttahment as Function)(this.props.note, a.id);
                                                                                    (this.props.GLOBAL_FUNCTION.onShowLoader as Function)(false);
                                                                                }}>
                                                                                    <span>
                                                                                        <span>{
                                                                                            a.title
                                                                                        }</span>

                                                                                    </span>
                                                                                </div>
                                                                                <div className="APP_NOTE_DETELETE_LINK_BUTTON"
                                                                                    dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.close, 11, "ff0000") }}
                                                                                    onClick={() => {
                                                                                        (this.props.GLOBAL_FUNCTION.deleteAttachment as Function)(a)
                                                                                    }}></div>
                                                                            </div>
                                                                        })
                                                                    }
                                                                </div>
                                                            }

                                                            {
                                                                this.props.note.tags.split(";").filter(t => t.length > 0).length > 0 &&
                                                                (this.props.showOnly !== undefined ? (this.props.showOnly) : true) &&
                                                                // this.state.showNote &&
                                                                <div style={{ backgroundColor: "#ffffff" }}>
                                                                    <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.tag, 11) }}></span>
                                                                    {
                                                                        this.props.note.tags.split(";").filter(t => t.length > 0).map((t, index) => {
                                                                            return <span key={"TAG_" + index} className="APP_NOTE_PJ_ITEM_BOX">
                                                                                {
                                                                                    <span style={{
                                                                                        cursor: "pointer",
                                                                                    }} onClick={() => {
                                                                                        (this.props.GLOBAL_FUNCTION.onSelectTag as Function)(t)
                                                                                    }} className={this.props.GLOBAL_DATA.selectedTag !== undefined && this.props.GLOBAL_DATA.selectedTag.filter(st => st === t).length > 0 ? "APP_NOTE_TAG_ITEM APP_NOTE_TAG_ITEM_SELECTED" : "APP_NOTE_TAG_ITEM"}>{t}</span>
                                                                                }

                                                                                <span className="APP_NOTE_DETELETE_LINK_BUTTON"
                                                                                    dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.close, 11, "ff0000") }}
                                                                                    onClick={() => {

                                                                                        let tmpMSGOption: I_MSG_OPTION = {
                                                                                            allMSG: [
                                                                                                {
                                                                                                    key: "Suppression du tag",
                                                                                                    value: "Supprimer le tag '" + t + "' de la note '" + this.props.note.title + "' ?",
                                                                                                    data: ENU_ICON_TYPE.tag
                                                                                                }
                                                                                            ],
                                                                                            showCloseButton: true,
                                                                                            buttons: [
                                                                                                {
                                                                                                    title: "Supprimer le tag",
                                                                                                    F_BUTTON_ACTION: () => {
                                                                                                        let tmpNote: I_NOTE_ITEM = this.props.note;
                                                                                                        tmpNote.tags = tmpNote.tags.split(";").filter(_t => _t !== t).map(_t => { return (_t) }).toString().split(",").join(";");
                                                                                                        (this.props.GLOBAL_FUNCTION.updateNote as Function)(tmpNote,
                                                                                                            this.props.GLOBAL_DATA.DB_TASKS !== undefined ?
                                                                                                                this.props.GLOBAL_DATA.DB_TASKS.filter(_t => _t.idnote === this.props.note.idnote) : []
                                                                                                            , true);
                                                                                                        (this.props.GLOBAL_FUNCTION.closePopup as Function)();
                                                                                                    }
                                                                                                }
                                                                                            ]

                                                                                        };

                                                                                        (this.props.GLOBAL_FUNCTION.showMessage as Function)(tmpMSGOption)



                                                                                    }}></span>
                                                                            </span>
                                                                        })
                                                                    }
                                                                </div>
                                                            }
                                                        </div>
                                                    }
                                                        
                                                    </div>
                                                }





                                            </span>
                                        </span>
                                    }







                                </span>






                                {
                                    (
                                        this.props.GLOBAL_DATA.navigation.filter(nav => nav.isNoteOpened && nav.noteId === this.props.note.idnote).length === 0
                                    ) &&
                                    this.props.GLOBAL_DATA.DB_TASKS !== undefined &&
                                    this.props.GLOBAL_DATA.DB_TASKS.filter(t => !t.finished && this.props.note.idnote === t.idnote).length > 0 &&
                                    <div className="APP_ITEM_PENDING_TASK" style={{
                                        backgroundColor: "#" + this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(tn => tn.id === this.props.note.type)[0].colorString,
                                    }} onClick={() => {
                                        this.onShowTask();
                                    }}>
                                        <div className="TEXT_TICKET">
                                            <span className={!this.state.showTask ? "APP_ITEM_TASK_COLAPSE_BOX" : "APP_ITEM_TASK_COLAPSE_BOX APP_ITEM_TASK_UNCOLAPSE_BOX"}>
                                                <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.arrow_Left, 12, "c3c3c3") }}>
                                                </span>
                                            </span>

                                            <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.pendingtasks, 16, "ff0000") }}>

                                            </span>
                                            <span className="APP_ITEM_PENDING_TASK_COUNT">
                                                <span title="Nombre de taches totales">{
                                                    this.props.GLOBAL_DATA.DB_TASKS.filter(t => this.props.note.idnote === t.idnote).length
                                                }</span>
                                                <span className="SPAN_SEP"></span>
                                                <span className="APP_ITEM_TASK_NOT_DATED" title="Nombre de taches non datées">{
                                                    this.props.GLOBAL_DATA.DB_TASKS.filter(t => !t.finished && this.props.note.idnote === t.idnote && t.taskdate.length === 0).length
                                                }</span>
                                                <span className="SPAN_SEP"></span>
                                                <span className="APP_ITEM_TASK_DATED" title="Nombre de taches datées">{
                                                    this.props.GLOBAL_DATA.DB_TASKS.filter(t => !t.finished && this.props.note.idnote === t.idnote && t.taskdate.length > 0).length
                                                }</span>
                                            </span>
                                            {
                                                this.props.GLOBAL_DATA.DB_TASKS.filter(t => !t.finished && this.props.note.idnote === t.idnote && t.taskdate.length > 0).length > 0 &&
                                                <span className="APP_ITEM_TASK_PROGRESS_TIME">
                                                    {
                                                        this.props.GLOBAL_DATA.DB_TASKS.filter(t => !t.finished && this.props.note.idnote === t.idnote && t.taskdate.length > 0)
                                                            .sort((a, b) => { return GetDateObjectFromString(a.taskdate).dateObj.getDate() - GetDateObjectFromString(b.taskdate).dateObj.getDate() })
                                                            .map((tv, index) => {
                                                                return <span key={tv.idnote + "_TV_" + index} className="APP_ITEM_TASK_PROGRESS_TIME_VALUE"
                                                                    style={{
                                                                        width: (100 - (((((GetDateObjectFromString(tv.taskdate).dateObj.getTime()) * 100) / (new Date()).getTime()) - 100) * 1000)) + "%",
                                                                        backgroundColor: "#" +
                                                                            ((100 - (((((GetDateObjectFromString(tv.taskdate).dateObj.getTime()) * 100) / (new Date()).getTime()) - 100) * 1000)) < 50 ? "39db43" :
                                                                                ((100 - (((((GetDateObjectFromString(tv.taskdate).dateObj.getTime()) * 100) / (new Date()).getTime()) - 100) * 1000)) < 70 ? "ffa32b" :
                                                                                    "bc0000"))
                                                                    }} title={this.props.GLOBAL_DATA.DB_TASKS !== undefined ? this.props.GLOBAL_DATA.DB_TASKS.filter(_t => !_t.finished && this.props.note.idnote === _t.idnote && _t.taskdate.length > 0).map(_t => { return _t.taskdate }).toString() : undefined}>
                                                                </span>
                                                            })
                                                    }
                                                </span>
                                            }

                                            {
                                                this.state.showTask &&
                                                <div className="APP_SHOW_TASK_BOX">
                                                    {

                                                        // !this.state.showNote &&
                                                        this.props.GLOBAL_DATA.DB_TASKS !== undefined &&
                                                        this.props.GLOBAL_DATA.DB_TASKS.filter(t => this.props.note.idnote === t.idnote && !t.finished).length > 0 &&
                                                        this.props.GLOBAL_DATA.DB_TASKS.filter(t => this.props.note.idnote === t.idnote && !t.finished).map(uft => {
                                                            return <div key={"TASK_" + uft.idnote} onClick={() => {

                                                                if (this.props.note.isAuthor === 1 ||
                                                                    (this.props.GLOBAL_DATA.sharedItemsWithMe.filter(si =>
                                                                        (
                                                                            (
                                                                                this.props.GLOBAL_DATA.selectedGroup !== undefined &&
                                                                                this.props.GLOBAL_DATA.selectedGroup.id === si.idGroup &&
                                                                                si.idType === undefined &&
                                                                                si.idNote === undefined
                                                                            )
                                                                            ||
                                                                            (
                                                                                this.props.GLOBAL_DATA.selectedGroup !== undefined &&
                                                                                this.props.GLOBAL_DATA.selectedGroup.id === si.idGroup &&
                                                                                si.idType !== undefined &&
                                                                                this.props.note.type === si.idType &&
                                                                                si.idNote === undefined
                                                                            )
                                                                            ||
                                                                            (
                                                                                this.props.GLOBAL_DATA.selectedGroup !== undefined &&
                                                                                this.props.GLOBAL_DATA.selectedGroup.id === si.idGroup &&
                                                                                si.idType !== undefined &&
                                                                                this.props.note.type === si.idType &&
                                                                                si.idNote !== undefined && this.props.note.idnote
                                                                            )
                                                                        )
                                                                        && si.canEdit
                                                                    ).length > 0)) {
                                                                    let tmpMSG: I_MSG_OPTION = {
                                                                        allMSG: [
                                                                            {
                                                                                key: "Validation de la tache...",
                                                                                value: "Voulez-vous réellement valider la tache [" +
                                                                                    (
                                                                                        IsCustForm(this.props.note.title) ?
                                                                                            (uft.title.split("]:").length > 1 ? uft.title.split("]:")[1] : uft.title) :
                                                                                            uft.title
                                                                                    )
                                                                                    + "] ?",
                                                                                data: ENU_ICON_TYPE.pendingtasks
                                                                            }
                                                                        ],
                                                                        showCloseButton: true,
                                                                        buttons: [
                                                                            {
                                                                                title: "Valider",
                                                                                F_BUTTON_ACTION: () => {
                                                                                    (this.props.GLOBAL_FUNCTION.checkTaskNote as Function)(uft.idtask, true);
                                                                                    (this.props.GLOBAL_FUNCTION.closePopup as Function)();
                                                                                }
                                                                            }
                                                                        ]
                                                                    };

                                                                    (this.props.GLOBAL_FUNCTION.showMessage as Function)(tmpMSG);
                                                                } else {
                                                                    let tmpMSGOption: I_MSG_OPTION = {
                                                                        allMSG: [
                                                                            {
                                                                                key: "Gestion du statut de la tache...",
                                                                                value: "Vous ne disposez pas d'autorisations sufisantes pour gérer le statut de la tache.<br/>Pour en savoir plus contacter l'auteur du carnet. ",
                                                                                data: ENU_ICON_TYPE.lock
                                                                            },
                                                                        ],
                                                                        showCloseButton: true,
                                                                        buttons: []
                                                                    };
                                                                    (this.props.GLOBAL_FUNCTION.showMessage as Function)(tmpMSGOption);

                                                                }


                                                            }} className="APP_NOTE_ITEM_TASK_ITEM">

                                                                {
                                                                    this.props.note.isAuthor === 0 &&
                                                                    (this.props.GLOBAL_DATA.sharedItemsWithMe.filter(si =>
                                                                        (
                                                                            (
                                                                                this.props.GLOBAL_DATA.selectedGroup !== undefined &&
                                                                                this.props.GLOBAL_DATA.selectedGroup.id === si.idGroup &&
                                                                                si.idType === undefined &&
                                                                                si.idNote === undefined
                                                                            )
                                                                            ||
                                                                            (
                                                                                this.props.GLOBAL_DATA.selectedGroup !== undefined &&
                                                                                this.props.GLOBAL_DATA.selectedGroup.id === si.idGroup &&
                                                                                si.idType !== undefined &&
                                                                                this.props.note.type === si.idType &&
                                                                                si.idNote === undefined
                                                                            )
                                                                            ||
                                                                            (
                                                                                this.props.GLOBAL_DATA.selectedGroup !== undefined &&
                                                                                this.props.GLOBAL_DATA.selectedGroup.id === si.idGroup &&
                                                                                si.idType !== undefined &&
                                                                                this.props.note.type === si.idType &&
                                                                                si.idNote !== undefined && this.props.note.idnote
                                                                            )
                                                                        )
                                                                        && si.canEdit
                                                                    ).length === 0) &&
                                                                    <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.lock, 12, "c3c3c3") }} title="Vous ne disposez pas d'autorisations sufisantes pour gérer le statut de la tache..."></span>
                                                                }

                                                                <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.unCheckedTask, 12, "c3c3c3") }}></span>
                                                                &nbsp;&nbsp;&nbsp;
                                                                <span>{
                                                                    uft.title.indexOf("]:") > -1 ?
                                                                        uft.title.split("]:")[1] :
                                                                        uft.title
                                                                }</span>

                                                                {
                                                                    uft.taskdate.length > 0 &&
                                                                    <span>
                                                                        <span className="SPAN_SEP"></span>
                                                                        <span>
                                                                            {
                                                                                uft.taskdate
                                                                            }
                                                                        </span>
                                                                    </span>
                                                                }

                                                            </div>
                                                        })
                                                    }
                                                </div>
                                            }
                                        </div>


                                    </div>
                                }



                            </div>

                            {
                                (
                                    this.props.GLOBAL_DATA.navigation.filter(nav => nav.noteId === this.props.note.idnote).length > 0 ?
                                        this.props.GLOBAL_DATA.navigation.filter(nav => nav.noteId === this.props.note.idnote)[0].isNoteOpened
                                        : false
                                ) &&
                                (this.props.showOnly !== undefined ? (!this.props.showOnly || this.props.note.title === "...") : true && (
                                    this.props.GLOBAL_DATA.navigation.filter(nav => nav.noteId === this.props.note.idnote).length > 0 ?
                                        this.props.GLOBAL_DATA.navigation.filter(nav => nav.noteId === this.props.note.idnote)[0].isNoteOpened
                                        : false
                                )) &&
                                <div className="APP_NOTE_ITEM_OPENED_BOX" >
                                    <NOTE_ITEM
                                        currentThread={0}
                                        asChild={false}
                                        notesOnEditMode={this.props.GLOBAL_DATA.editNoteMode}
                                        asLinkMode={this.props.GLOBAL_DATA.linkItemMode}
                                        tags={this.props.GLOBAL_DATA.DB_TAGS as string[]}
                                        linkedItems={this.props.GLOBAL_DATA.selectedNoteToLink !== undefined && (this.props.GLOBAL_DATA.selectedNoteToLink as I_NOTE_ITEM).associations !== null ? (((this.props.GLOBAL_DATA.selectedNoteToLink as I_NOTE_ITEM).associations as any) as string).split(";") : undefined}
                                        note={this.props.note}
                                        typesNotes={this.props.GLOBAL_DATA.DB_TYPES_NOTES as I_TYPENOTE[]}
                                        APP_GLOBAL_FUNCTION={this.props.GLOBAL_FUNCTION}
                                        showTaskNote={true}
                                        APP_GLOBAL_DATA={this.props.GLOBAL_DATA}
                                        initialLinkedItem={this.props.GLOBAL_DATA.selectedNoteToLink !== undefined ? (this.props.GLOBAL_DATA.selectedNoteToLink as I_NOTE_ITEM).idnote : undefined}
                                        attachments={this.props.GLOBAL_DATA.DB_ATTACHMENT_LINKS !== undefined ? this.props.GLOBAL_DATA.DB_ATTACHMENT_LINKS.filter(l => l.nodeId === this.props.note.idnote) : []}
                                        withEditMenu={
                                            (
                                                (
                                                    this.props.GLOBAL_DATA.sharedItemsWithMe.filter(siwm =>
                                                        this.props.GLOBAL_DATA.selectedGroup !== undefined &&
                                                        siwm.canEdit &&
                                                        siwm.idGroup === this.props.GLOBAL_DATA.selectedGroup.id &&
                                                        (
                                                            (
                                                                (siwm.idNote !== null && siwm.idNote !== undefined && siwm.idNote === this.props.note.idnote) &&
                                                                (siwm.idType === null || siwm.idType === undefined)
                                                            ) ||
                                                            (
                                                                (siwm.idNote === null || siwm.idNote === undefined) &&
                                                                (siwm.idType !== null && siwm.idType !== undefined && this.props.note.type === siwm.idType)
                                                            ) ||
                                                            (
                                                                (siwm.idNote === null || siwm.idNote === undefined) &&
                                                                (siwm.idType === null || siwm.idType === undefined)
                                                            )
                                                        )
                                                    ).length > 0
                                                )
                                                ||
                                                (this.props.GLOBAL_DATA.DB_GROUPS !== undefined &&
                                                    this.props.GLOBAL_DATA.DB_GROUPS.filter(g => this.props.GLOBAL_DATA.selectedGroup !== undefined &&
                                                        g.id === this.props.GLOBAL_DATA.selectedGroup.id && g.isAuthor === 1).length > 0)
                                            ) === true ?
                                                (
                                                    true
                                                ) : false
                                        }
                                        ref={this.ref_Note}
                                        tasks={this.props.GLOBAL_DATA.DB_TASKS !== undefined ? this.props.GLOBAL_DATA.DB_TASKS.filter(t => t.idnote === this.props.note.idnote) : undefined} />
                                </div>
                            }


                            {
                                ((this.props.note.selectedTypeNote !== null &&
                                    this.props.note.selectedTypeNote !== undefined &&
                                    this.props.note.selectedTypeNote.length > 0) ||
                                    (this.props.note.selectedTags !== null &&
                                        this.props.note.selectedTags !== undefined &&
                                        this.props.note.selectedTags.length > 0
                                    )) &&
                                <div className="APP_NOTE_CUPBOARD_FILTER_INFO_BOX">
                                    <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.filtered, 12, "B50000") }}></span>
                                    &nbsp;
                                    <span>
                                        Attention, un ou plusieurs filtres sont actifs. certaines notes enfants pourraient ne pas s'afficher...
                                    </span>
                                    &nbsp;
                                    <span className="APP_FOLDER_TABLE_BUTTON" onClick={() => {
                                        (this.props.GLOBAL_FUNCTION.OnClearAllCupboardFilter as Function)(this.props.note.idnote)
                                    }}>
                                        Supprimer tous les filtres
                                    </span>
                                </div>
                            }
                        </div>



                        {
                            this.props.note.associations !== null &&
                            this.props.note.associations !== undefined &&
                            this.props.maxLevel > 0 &&
                            (
                                GetDistinctListOfString(this.props.note.associations.split(";")
                                    .filter(a => a.length > 0 && a !== "NaN" &&
                                        (this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                            this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === (parseInt(a) + 0) &&
                                                (
                                                    this.props.note.searInChildFromCubBoard !== undefined &&
                                                        this.props.GLOBAL_DATA.DB_TYPES_NOTES !== undefined &&
                                                        IsTypeNoteIsAnCupBord(this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(tn => tn.id === this.props.note.type)[0].title) ?
                                                        (
                                                            n.title.toUpperCase().indexOf(this.props.note.searInChildFromCubBoard.toUpperCase()) > -1
                                                        )
                                                        : true
                                                )
                                            ).length > 0)
                                    ).map(c => { return c })).length > 0 ||
                                (
                                    this.props.GLOBAL_DATA.DB_NOTES_OVER_GROUPS !== undefined &&
                                    this.props.GLOBAL_DATA.DB_NOTES_OVER_GROUPS.filter(nog =>
                                        (
                                            this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                            this.props.GLOBAL_DATA.DB_NOTES.filter(_n => _n.idgroupnote === nog.idOtherGroup && _n.idnote === nog.idOtherNote).length === 0
                                        ) &&
                                        nog.idNote === this.props.note.idnote && nog.noteItem !== undefined).map(nog => { return nog.idNote.toString() }).length > 0)
                            ) &&
                            <div className="APP_NOTE_ITEM_BOX" >


                                <div title={this.state.colapsed ? "Afficher les enfants de la note '" + this.props.note.title + "'." : "Masquer les enfants de la note '" + this.props.note.title + "'."} className="APP_NOTE_ITEM_CHILD_HEADER" onClick={() => {
                                    this.onColapseChild();
                                }}>
                                    <span className="CALENDAR_CHILD_COUNT">
                                        <span>{
                                            GetDistinctListOfString(this.props.note.associations.split(";")
                                                .filter(a => a.length > 0 && a !== "NaN" && this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                                    this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === (parseInt(a) + 0) &&
                                                        (
                                                            this.props.note.searInChildFromCubBoard !== undefined &&
                                                                this.props.GLOBAL_DATA.DB_TYPES_NOTES !== undefined &&
                                                                IsTypeNoteIsAnCupBord(this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(tn => tn.id === this.props.note.type)[0].title) ?
                                                                (
                                                                    n.title.toUpperCase().indexOf(this.props.note.searInChildFromCubBoard.toUpperCase()) > -1
                                                                )
                                                                : true
                                                        )
                                                    ).length > 0
                                                ).map(c => { return c })).length}</span>
                                        <span>
                                            {
                                                this.props.GLOBAL_DATA.DB_NOTES_OVER_GROUPS !== undefined &&
                                                this.props.GLOBAL_DATA.DB_NOTES_OVER_GROUPS.filter(nog =>
                                                    (
                                                        this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                                        this.props.GLOBAL_DATA.DB_NOTES.filter(_n => _n.idgroupnote === nog.idOtherGroup && _n.idnote === nog.idOtherNote).length === 0
                                                    ) &&
                                                    nog.idNote === this.props.note.idnote && nog.noteItem !== undefined).map(nog => { return nog.idNote.toString() }).length > 0 &&
                                                <div>
                                                    <span className="SPAN_SEP"></span>
                                                    <span>{
                                                        GetDistinctListOfString(this.props.GLOBAL_DATA.DB_NOTES_OVER_GROUPS
                                                            .filter(nog =>
                                                                (
                                                                    this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                                                    this.props.GLOBAL_DATA.DB_NOTES.filter(_n => _n.idgroupnote === nog.idOtherGroup && _n.idnote === nog.idOtherNote).length === 0
                                                                ) &&
                                                                nog.idNote === this.props.note.idnote).map(nog => { return nog.idOtherNote.toString() })).length
                                                    }</span>
                                                </div>
                                            }
                                        </span>

                                    </span>
                                    <div className={
                                        this.props.GLOBAL_DATA.navigation.filter(nav => nav.isChildOpened && nav.noteId === this.props.note.idnote).length > 0 ?
                                            "CALENDAR_CHILD_ARROW_BOX CALENDAR_CHILD_ARROW_BOX_COLAPSED" : "CALENDAR_CHILD_ARROW_BOX"} dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.arrow_Left, 11, "e1e1e1") }}></div>
                                </div>

                                <div className='APP_NOTE_CHILD_BOX'>
                                    {
                                        // !this.state.colapsed &&
                                        (
                                            this.props.GLOBAL_DATA.navigation.filter(nav => nav.noteId === this.props.note.idnote).length > 0 ?
                                                this.props.GLOBAL_DATA.navigation.filter(nav => nav.noteId === this.props.note.idnote)[0].isChildOpened
                                                : false
                                        ) &&
                                        !this.props.asChild &&
                                        this.props.note.associations !== null &&
                                        this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                        this.props.GLOBAL_DATA.DB_NOTES.filter(
                                            n =>
                                            (
                                                this.props.GLOBAL_DATA.DB_TYPES_NOTES !== undefined &&
                                                this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(n => n.id === this.props.note.type).length > 0 &&
                                                (
                                                    this.props.note.searInChildFromCubBoard !== undefined &&
                                                        IsTypeNoteIsAnCupBord(this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(tn => tn.id === this.props.note.type)[0].title) ?
                                                        (
                                                            n.title.toUpperCase().indexOf(this.props.note.searInChildFromCubBoard.toUpperCase()) > -1
                                                        )
                                                        : true
                                                )
                                            )
                                        ).length > 0 &&
                                        this.props.note.associations.split(";").filter(a => a.length > 0 && (parseInt(a) + 0) > 0 &&
                                            this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                            this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === (parseInt(a) + 0)).length > 0
                                        ).length > 0 &&
                                        this.props.note.associations.split(";").filter(a => a !== "NaN")
                                            .filter(a => this.props.GLOBAL_DATA.DB_NOTES !== undefined && a.length > 0 && this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === (parseInt(a) + 0)).length > 0 &&
                                                (
                                                    (
                                                        this.props.GLOBAL_DATA.DB_TYPES_NOTES !== undefined &&
                                                            this.props.note.searInChildFromCubBoard !== undefined &&
                                                            IsTypeNoteIsAnCupBord(this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(tn => tn.id === this.props.note.type)[0].title) ?
                                                            (
                                                                this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === (parseInt(a) + 0))[0].title.toUpperCase().indexOf(this.props.note.searInChildFromCubBoard.toUpperCase()) > -1
                                                            )
                                                            : true
                                                    ) &&
                                                    (
                                                        this.props.note.selectedTags !== undefined &&
                                                            this.props.note.selectedTags.length > 0 ?
                                                            this.props.note.selectedTags.filter(st => this.props.GLOBAL_DATA.DB_NOTES !== undefined && this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === parseInt(a) && n.tags.split(";").filter(nt => nt === st).length > 0).length > 0).length > 0 : true
                                                    ) &&
                                                    (
                                                        this.props.note.selectedTypeNote !== undefined &&
                                                            this.props.note.selectedTypeNote.length > 0 ?
                                                            this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === parseInt(a) && this.props.note.selectedTypeNote.filter(st => n.type === st).length > 0).length > 0 : true
                                                    )
                                                )
                                            )
                                            .map(a => { return this.props.GLOBAL_DATA.DB_NOTES !== undefined && this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === (parseInt(a) + 0)).length > 0 ? this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === (parseInt(a) + 0))[0] : undefined })
                                            .sort((a, b) => {
                                                return this.props.GLOBAL_DATA.filterByLastChange !== undefined && this.props.GLOBAL_DATA.filterByLastChange ?
                                                    GetDateObjectFromString((b as I_NOTE_ITEM).created).dateObj.getTime() -
                                                    GetDateObjectFromString((a as I_NOTE_ITEM).created).dateObj.getTime() :
                                                    GetDateObjectFromString((a as I_NOTE_ITEM).created).dateObj.getTime() -
                                                    GetDateObjectFromString((b as I_NOTE_ITEM).created).dateObj.getTime()
                                            })
                                            .map((a, index) => {
                                                return <div key={"CHILD_ASSOC_" + index + "_PN_" + this.props.note.idnote}>
                                                    {
                                                        this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                                        a !== undefined &&
                                                        <SHORT_NOTE_ITEM showNoteShortDate={
                                                            true
                                                        } parentNote={this.props.note.idnote} maxLevel={this.props.maxLevel - 1} asChild={this.props.maxLevel > 0 ? false : true} GLOBAL_DATA={this.props.GLOBAL_DATA} GLOBAL_FUNCTION={this.props.GLOBAL_FUNCTION} note={a} />
                                                    }
                                                </div>
                                            })
                                    }

                                    <div >
                                        {
                                            (
                                                this.props.GLOBAL_DATA.navigation.filter(nav => nav.noteId === this.props.note.idnote).length > 0 ?
                                                    this.props.GLOBAL_DATA.navigation.filter(nav => nav.noteId === this.props.note.idnote)[0].isChildOpened
                                                    : false
                                            ) &&
                                            this.props.GLOBAL_DATA.DB_NOTES_OVER_GROUPS !== undefined &&
                                            this.props.GLOBAL_DATA.DB_NOTES_OVER_GROUPS
                                                .filter(nog => this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                                    this.props.GLOBAL_DATA.DB_NOTES.filter(_n => _n.idnote === nog.idOtherNote && _n.idgroupnote === nog.idOtherGroup).length === 0 && nog.idNote === this.props.note.idnote && nog.noteItem !== undefined)
                                                .map(nog => {
                                                    return <div key={nog.idLinkedItem + '_PN_' + this.props.note.idnote} className="APP_NOTE_ITEM_BOX">
                                                        {
                                                            (
                                                                (
                                                                    this.props.GLOBAL_DATA.sharedItemsWithMe.filter(siwm =>
                                                                        this.props.GLOBAL_DATA.selectedGroup !== undefined &&
                                                                        siwm.canEdit &&
                                                                        siwm.idGroup === this.props.GLOBAL_DATA.selectedGroup.id &&
                                                                        (
                                                                            (
                                                                                (siwm.idNote !== null && siwm.idNote !== undefined && siwm.idNote === this.props.note.idnote) &&
                                                                                (siwm.idType !== null && siwm.idType !== undefined && this.props.note.type === siwm.idType)
                                                                            ) ||
                                                                            (
                                                                                (siwm.idNote === null || siwm.idNote === undefined) &&
                                                                                (siwm.idType === null || siwm.idType === undefined)
                                                                            )
                                                                        )
                                                                    ).length > 0
                                                                )
                                                                ||
                                                                (this.props.GLOBAL_DATA.DB_GROUPS !== undefined &&
                                                                    this.props.GLOBAL_DATA.DB_GROUPS.filter(g => this.props.GLOBAL_DATA.selectedGroup !== undefined &&
                                                                        g.id === this.props.GLOBAL_DATA.selectedGroup.id && g.isAuthor === 1).length > 0)
                                                            ) === true &&
                                                            <span className="NOG_DELETE_BUTTON" dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.close, 11, "ff0000") }} onClick={() => {
                                                                (this.props.GLOBAL_FUNCTION.onLinkItemToNote as Function)(nog.idOtherNote, this.props.note, nog.idOtherGroup)
                                                            }}>
                                                            </span>
                                                        }



                                                        <span className="APP_NOTE_ITEM_DATE" style={{ color: "#3c3c3c", backgroundColor: "#ffffff" }}>
                                                            {
                                                                (nog.noteItem !== undefined) &&
                                                                (nog.noteItem.created !== nog.noteItem.modified && nog.noteItem.modified !== null && nog.noteItem.created !== null ?
                                                                    <span>
                                                                        {
                                                                            GetDateObjectFromString(nog.noteItem.modified).date + " " +
                                                                            (DB_MONTH_NAME[parseInt(GetDateObjectFromString(nog.noteItem.modified).month) - 1]).substring(0, 3) + ". "
                                                                        }

                                                                        <div>
                                                                            {
                                                                                GetDateObjectFromString(nog.noteItem.created).year
                                                                            }
                                                                        </div>
                                                                    </span>
                                                                    :
                                                                    <span>
                                                                        {
                                                                            GetDateObjectFromString(nog.noteItem.created).date + " " +
                                                                            (DB_MONTH_NAME[parseInt(GetDateObjectFromString(nog.noteItem.created).month) - 1]).substring(0, 3) + ". "

                                                                        }

                                                                        <div>
                                                                            {
                                                                                GetDateObjectFromString(nog.noteItem.created).year
                                                                            }
                                                                        </div>



                                                                    </span>

                                                                )
                                                            }
                                                        </span>
                                                        <span style={{ cursor: "pointer" }} onClick={() => {
                                                            if (nog.noteItem !== undefined)
                                                                (this.props.GLOBAL_FUNCTION.OnShowNoteOverGroup as Function)(nog.idOtherNote)
                                                        }}>
                                                            <span >
                                                                <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.folder, 11) }}></span>
                                                                &nbsp;
                                                                <span>{
                                                                    this.props.GLOBAL_DATA.DB_GROUPS !== undefined &&
                                                                    this.props.GLOBAL_DATA.DB_GROUPS.filter(g => g.id === nog.idOtherGroup).length > 0 &&
                                                                    this.props.GLOBAL_DATA.DB_GROUPS.filter(g => g.id === nog.idOtherGroup)[0].title
                                                                }</span>
                                                                <span>
                                                                    <span>
                                                                        {
                                                                            nog.noteItem !== undefined &&
                                                                            this.props.GLOBAL_DATA.DB_GROUPS !== undefined &&
                                                                            this.props.GLOBAL_DATA.DB_GROUPS.filter(g => g.id === nog.idOtherGroup).length > 0 &&
                                                                            this.props.GLOBAL_DATA.DB_GROUPS.filter(g => g.id === nog.idOtherGroup)[0].isAuthor &&
                                                                            nog.noteItem.isAuthor === 1 &&
                                                                            <span>
                                                                                ( <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.authorItem, 11) }}></span> )
                                                                            </span>
                                                                        }

                                                                        {
                                                                            nog.noteItem !== undefined &&
                                                                            this.props.GLOBAL_DATA.DB_GROUPS !== undefined &&
                                                                            this.props.GLOBAL_DATA.DB_GROUPS.filter(g => g.id === nog.idGroup).length > 0 &&
                                                                            !this.props.GLOBAL_DATA.DB_GROUPS.filter(g => g.id === nog.idGroup)[0].isAuthor &&
                                                                            nog.noteItem.isAuthor === 0 &&
                                                                            <span>
                                                                                ( <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.community, 11) }}></span> )
                                                                            </span>
                                                                        }
                                                                    </span>
                                                                </span>
                                                                <span className="SPAN_SEP"></span>
                                                            </span>
                                                            <span>{
                                                                nog.noteItem !== undefined &&
                                                                nog.noteItem.title
                                                            }</span>

                                                            <span>
                                                                <span>
                                                                    {
                                                                        nog.noteItem !== undefined &&
                                                                        <span>
                                                                            <span>
                                                                                {
                                                                                    nog.noteItem !== undefined &&
                                                                                    nog.noteItem.isAuthor === 1 &&
                                                                                    <span>
                                                                                        ( <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.authorItem, 11) }}></span> )
                                                                                    </span>
                                                                                }

                                                                                {
                                                                                    nog.noteItem !== undefined &&
                                                                                    nog.noteItem.isAuthor === 0 &&
                                                                                    <span>
                                                                                        ( <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.community, 11) }}></span> )
                                                                                    </span>
                                                                                }
                                                                            </span>
                                                                        </span>
                                                                    }
                                                                </span>
                                                            </span>
                                                            <span className="SPAN_SEP"></span>

                                                        </span>
                                                        <span>
                                                            <span className="APP_FOLDER_TABLE_BUTTON" onClick={() => {
                                                                (this.props.GLOBAL_FUNCTION.OnShowNoteOverGroup as Function)(nog.idLinkedItem)
                                                            }}>Prévisualiser la note externe</span>
                                                        </span>
                                                        <span>
                                                            <span className="APP_FOLDER_TABLE_BUTTON" onClick={() => {
                                                                (this.props.GLOBAL_FUNCTION.onLoadNoteAsMemory as Function)(
                                                                    nog.noteItem
                                                                )
                                                            }}>Ouvrir la note externe</span>
                                                        </span>

                                                        {
                                                            nog.noteItem !== undefined &&
                                                            nog.isOpen !== undefined && !nog.isOpen &&
                                                            !IsCustForm(nog.noteItem.title) &&
                                                            !IsComptaForm(decodeUtf8((nog.noteItem.description as any).data as ArrayBuffer)) &&
                                                            <div>
                                                                <div dangerouslySetInnerHTML={{
                                                                    __html:
                                                                        decodeUtf8((nog.noteItem.description as any).data as ArrayBuffer)
                                                                }}></div>
                                                            </div>
                                                        }


                                                        {
                                                            nog.noteItem !== undefined &&
                                                            nog.isOpen !== undefined && !nog.isOpen &&
                                                            IsCustForm(nog.noteItem.title) &&
                                                            !IsComptaForm(decodeUtf8((nog.noteItem.description as any).data as ArrayBuffer)) &&
                                                            <div style={{ border: "1px solid #e1e1e1", padding: "14px", borderRadius: "14px" }}>
                                                                <CUST_FORM_BOX ref={this.ref_CustForm} canEditInLive={false} GLOBAL_DATA={this.props.GLOBAL_DATA} GLOBAL_FUNCTION={this.props.GLOBAL_FUNCTION} note={
                                                                    {
                                                                        idnote: nog.noteItem.idnote,
                                                                        title: nog.noteItem.title,
                                                                        idgroupnote: nog.noteItem.idgroupnote,
                                                                        description: decodeUtf8((nog.noteItem.description as any).data as ArrayBuffer)
                                                                    } as I_NOTE_ITEM
                                                                } editMode={false} />
                                                            </div>
                                                        }

                                                        {
                                                            nog.noteItem !== undefined &&
                                                            nog.isOpen !== undefined && !nog.isOpen &&
                                                            !IsCustForm(nog.noteItem.title) &&
                                                            IsComptaForm(decodeUtf8((nog.noteItem.description as any).data as ArrayBuffer)) &&
                                                            <div style={{ border: "1px solid #e1e1e1", padding: "14px", borderRadius: "14px" }}>
                                                                <COMPTA_FORM APP_DATA={this.props.GLOBAL_DATA} APP_FUNCTION={this.props.GLOBAL_FUNCTION} note={nog.noteItem} editMode={false} showTotalOnly={false} />
                                                            </div>
                                                        }


                                                    </div>
                                                })
                                        }
                                    </div>
                                </div>
                            </div>
                        }



                    </div>
                }



                {
                    this.props.GLOBAL_DATA.selectedNoteMenu === ENU_ICON_TYPE.form_parent &&
                    <div id="ADD_NEW_FORM" className="APP_FOLDER_TABLE_BUTTON" style={{ top: "0px", left: "137px" }} onClick={() => {
                        //Sélection du formulaire à prendre en compte
                        //-------------------------------------------
                        (this.props.GLOBAL_FUNCTION.onSelectNoteMenu as Function)(ENU_ICON_TYPE.note);
                        (this.props.GLOBAL_FUNCTION.onSelectFormToGenerate as Function)(this.props.note.idnote);
                        (this.props.GLOBAL_FUNCTION.addNewNoteInGroup as Function)();
                    }}>
                        <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.newItem, 16) }}>

                        </span>
                        <span className="SPAN_SEP"></span>
                        <span>
                            Nouvel élément
                        </span>
                    </div>
                }

                {
                    !this.state.showView &&
                    this.props.GLOBAL_DATA.selectedNoteMenu === ENU_ICON_TYPE.form_parent &&
                    this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                    this.props.GLOBAL_DATA.DB_NOTES.filter(n => IsCustForm(n.title) && parseInt(GetNoteCustParentIdFromTitle(n.title)) !== n.idnote && parseInt(GetNoteCustParentIdFromTitle(n.title)) === this.props.note.idnote).length > 0 &&
                    < div className="APP_FOLDER_TABLE_BUTTON" style={{ top: "0px", left: "137px" }} onClick={() => {
                        this.onShowView()
                    }}>
                        <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.unSee, 16) }}>
                        </span>
                        <span className="SPAN_SEP"></span>
                        <span>
                            {
                                this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.displayUnity !== 0 && IsCustForm(n.title) && parseInt(GetNoteCustParentIdFromTitle(n.title)) !== n.idnote && parseInt(GetNoteCustParentIdFromTitle(n.title)) === this.props.note.idnote).length
                            }
                        </span>
                        <span className="SPAN_SEP"></span>
                        <span>
                            Afficher la vue
                        </span>
                    </div>
                }

                {
                    this.state.showView &&
                    <div className="APP_FOLDER_TABLE_BUTTON" style={{ top: "0px", left: "137px" }} onClick={() => {
                        this.onShowView()
                    }}>
                        <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.see, 16) }}>
                        </span>
                        <span className="SPAN_SEP"></span>
                        <span>
                            {
                                this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                this.props.GLOBAL_DATA.DB_NOTES.filter(n =>
                                    IsCustForm(n.title) &&
                                    n.displayUnity !== 0 &&
                                    parseInt(GetNoteCustParentIdFromTitle(n.title)) !== n.idnote &&
                                    parseInt(GetNoteCustParentIdFromTitle(n.title)) === this.props.note.idnote).length
                            }
                        </span>
                        <span className="SPAN_SEP"></span>
                        <span>
                            Masquer la vue
                        </span>
                    </div>
                }



                {
                    //Vue du formulaire
                    //-----------------
                    this.state.showView &&
                    IsCustForm(this.props.note.title) &&
                    parseInt(GetNoteCustParentIdFromTitle(this.props.note.title)) === this.props.note.idnote &&
                    <div className="CUST_FORM_VIEW_BOX">
                        <div className="CUST_FORM_SEARCH_ZONE">
                            <SEARCHBOX ref={this.ref_SearchInView} placeHolder="Recherche dans la vue" forceUpdateEveryTime={true} F_UPDATE_SEARCHVALUE={() => {
                                (this.props.GLOBAL_FUNCTION.onShowLoader as Function)(false);
                                this.setState({
                                    currentPaginationIndex: 0
                                })
                            }} APPFUNCTION={this.props.GLOBAL_FUNCTION} />
                        </div>
                        <table className="CUST_FORM_VIEW_TABLE">
                            <tbody>
                                {/* <div className="CUST_VIEW_TYPE_TITLE_EMPTY_ZONE">

                                </div> */}
                                <tr>
                                    <th>

                                    </th>

                                    <th>

                                    </th>
                                    <th>

                                    </th>
                                    {
                                        GetNoteCustFormData(this.props.note.description, this.props.note.idnote).lines.map(l => {
                                            return <th className="CUST_FORM_VIEW_COLUMN" style={{ paddingRight: "22px" }} onClick={() => {
                                                this.onFilterColumn(l.id)
                                            }}>
                                                <span>{
                                                    l.title
                                                }</span>

                                                {
                                                    this.state.filteredColumn !== undefined &&
                                                    (this.state.filteredColumn.key as string) === l.id &&
                                                    <div className="CUST_FORM_VIEW_FILTER_INDICATOR">
                                                        <span dangerouslySetInnerHTML={{
                                                            __html: GetIconFromType(
                                                                this.state.filteredColumn !== undefined &&
                                                                    (this.state.filteredColumn.key as string) === l.id &&
                                                                    (this.state.filteredColumn.value as boolean) ?
                                                                    ENU_ICON_TYPE.filter_asc :
                                                                    ENU_ICON_TYPE.filter_desc, 11)
                                                        }}></span>
                                                    </div>
                                                }

                                            </th>
                                        })
                                    }
                                    <th className="CUST_FORM_VIEW_COLUMN" onClick={() => {
                                        this.onFilterColumn(this.CREATED_CODE)
                                    }}>
                                        <span>{
                                            this.props.GLOBAL_DATA.filterByLastChange !== undefined &&
                                                this.props.GLOBAL_DATA.filterByLastChange ? "Modifiée" :
                                                "Créée"
                                        }</span>

                                        {
                                            this.state.filteredColumn !== undefined &&
                                            (this.state.filteredColumn.key as string) === this.CREATED_CODE &&
                                            <div className="CUST_FORM_VIEW_FILTER_INDICATOR">
                                                <span dangerouslySetInnerHTML={{
                                                    __html: GetIconFromType(
                                                        this.state.filteredColumn !== undefined &&
                                                            (this.state.filteredColumn.key as string) === this.CREATED_CODE &&
                                                            (this.state.filteredColumn.value as boolean) ?
                                                            ENU_ICON_TYPE.filter_asc :
                                                            ENU_ICON_TYPE.filter_desc, 11)
                                                }}></span>
                                            </div>
                                        }
                                    </th>
                                    <th className="CUST_FORM_VIEW_COLUMN" onClick={() => {
                                        this.onFilterColumn(this.AUTHOR_CODE)
                                    }}>
                                        <span>Auteur</span>

                                        {
                                            this.state.filteredColumn !== undefined &&
                                            (this.state.filteredColumn.key as string) === this.AUTHOR_CODE &&
                                            <div className="CUST_FORM_VIEW_FILTER_INDICATOR">
                                                <span dangerouslySetInnerHTML={{
                                                    __html: GetIconFromType(
                                                        this.state.filteredColumn !== undefined &&
                                                            (this.state.filteredColumn.key as string) === this.AUTHOR_CODE &&
                                                            (this.state.filteredColumn.value as boolean) ?
                                                            ENU_ICON_TYPE.filter_asc :
                                                            ENU_ICON_TYPE.filter_desc, 11)
                                                }}></span>
                                            </div>
                                        }
                                    </th>
                                    <th></th>
                                    <th></th>
                                </tr>

                                <tr>
                                    <td colSpan={100}>
                                        {
                                            this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                            this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.displayUnity !== 0 && IsCustForm(n.title) && parseInt(GetNoteCustParentIdFromTitle(n.title)) !== n.idnote && parseInt(GetNoteCustParentIdFromTitle(n.title)) === this.props.note.idnote).length > 0 &&
                                            (
                                                this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.displayUnity !== 0 && IsCustForm(n.title) && parseInt(GetNoteCustParentIdFromTitle(n.title)) !== n.idnote && parseInt(GetNoteCustParentIdFromTitle(n.title)) === this.props.note.idnote && n.description.length === 0).length > 1
                                            ) &&
                                            <div className="LOADING_CHILD_FORM_INFO_BOX">
                                                Chargement des données, merci de patienter ...
                                            </div>
                                        }</td>
                                </tr>
                                {/* {
                                    this.props.GLOBAL_DATA.DB_NOTES!==undefined &&
                                    this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.displayUnity !== 0 && IsCustForm(n.title) && parseInt(GetNoteCustParentIdFromTitle(n.title)) !== n.idnote && parseInt(GetNoteCustParentIdFromTitle(n.title)) === this.props.note.idnote)
                                    .filter(f => this.ref_SearchInView.current !== null ?
                                        (
                                            GetNoteCustFormData(f.description as string, this.props.note.idnote).lines.filter(_l => _l.value !== undefined &&
                                                this.ref_SearchInView.current !== null &&
                                                _l.value.toUpperCase().indexOf(this.ref_SearchInView.current.state.searchValue.toUpperCase()) > -1

                                            ).length > 0
                                        )
                                        : true)
                                        .filter(n=>n.description.length === 0).map(n=>{
                                            return <div>
                                                {
                                                    n.title
                                                }
                                            </div>
                                        })
                                } */}
                                {
                                    this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                    this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.displayUnity !== 0 && IsCustForm(n.title) && parseInt(GetNoteCustParentIdFromTitle(n.title)) !== n.idnote && parseInt(GetNoteCustParentIdFromTitle(n.title)) === this.props.note.idnote).length > 0 &&
                                    (
                                        this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.displayUnity !== 0 && IsCustForm(n.title) && parseInt(GetNoteCustParentIdFromTitle(n.title)) !== n.idnote && parseInt(GetNoteCustParentIdFromTitle(n.title)) === this.props.note.idnote && n.description.length === 0).length === 0
                                    ) &&
                                    this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.displayUnity !== 0 && IsCustForm(n.title) && parseInt(GetNoteCustParentIdFromTitle(n.title)) !== n.idnote && parseInt(GetNoteCustParentIdFromTitle(n.title)) === this.props.note.idnote)
                                        .filter(f => this.ref_SearchInView.current !== null ?
                                            (
                                                GetNoteCustFormData(f.description as string, this.props.note.idnote).lines.filter(_l => _l.value !== undefined &&
                                                    this.ref_SearchInView.current !== null &&
                                                    _l.value.toUpperCase().indexOf(this.ref_SearchInView.current.state.searchValue.toUpperCase()) > -1

                                                ).length > 0
                                            )
                                            : true)
                                        .sort((a, b) => this.props.GLOBAL_DATA.filterByLastChange !== undefined &&
                                            this.props.GLOBAL_DATA.filterByLastChange ?
                                            (GetDateObjectFromString(b.modified).dateObj.getTime() - GetDateObjectFromString(a.modified).dateObj.getTime())
                                            :
                                            GetDateObjectFromString(b.created).dateObj.getTime() - GetDateObjectFromString(a.created).dateObj.getTime())
                                        .sort((a, b) =>
                                            this.state.filteredColumn !== undefined &&
                                                (this.state.filteredColumn.key !== this.CREATED_CODE &&
                                                    this.state.filteredColumn.key !== this.AUTHOR_CODE) ?
                                                (this.state.filteredColumn.value as boolean) ?
                                                    (
                                                        GetNoteCustFormData(this.props.note.description, this.props.note.idnote).lines.filter(_l => this.state.filteredColumn !== undefined &&
                                                            _l.id === this.state.filteredColumn.key as string && _l.type === "F_NUM").length > 0 ?
                                                            parseInt((GetNoteCustFormData(a.description, this.props.note.idnote).lines.filter(_l => this.state.filteredColumn !== undefined &&
                                                                _l.id === this.state.filteredColumn.key)[0].value as string)) - parseInt((
                                                                    GetNoteCustFormData(b.description, this.props.note.idnote).lines.filter(_l => this.state.filteredColumn !== undefined &&
                                                                        _l.id === this.state.filteredColumn.key)[0].value as string
                                                                ))
                                                            :
                                                            GetNoteCustFormData(this.props.note.description, this.props.note.idnote).lines.filter(_l => this.state.filteredColumn !== undefined &&
                                                                _l.id === this.state.filteredColumn.key as string && _l.type === "F_NUM").length > 0 ?
                                                                parseInt((GetNoteCustFormData(b.description, this.props.note.idnote).lines.filter(_l => this.state.filteredColumn !== undefined &&
                                                                    _l.id === this.state.filteredColumn.key)[0].value as string)) - parseInt((
                                                                        GetNoteCustFormData(a.description, this.props.note.idnote).lines.filter(_l => this.state.filteredColumn !== undefined &&
                                                                            _l.id === this.state.filteredColumn.key)[0].value as string
                                                                    )) :
                                                                (GetNoteCustFormData(a.description, this.props.note.idnote).lines.filter(_l => this.state.filteredColumn !== undefined &&
                                                                    _l.id === this.state.filteredColumn.key)[0].value as string).localeCompare(
                                                                        GetNoteCustFormData(b.description, this.props.note.idnote).lines.filter(_l => this.state.filteredColumn !== undefined &&
                                                                            _l.id === this.state.filteredColumn.key)[0].value as string
                                                                    )
                                                    ) :
                                                    (GetNoteCustFormData(b.description, this.props.note.idnote).lines.filter(_l => this.state.filteredColumn !== undefined &&
                                                        _l.id === this.state.filteredColumn.key)[0].value as string).localeCompare(
                                                            GetNoteCustFormData(a.description, this.props.note.idnote).lines.filter(_l => this.state.filteredColumn !== undefined &&
                                                                _l.id === this.state.filteredColumn.key)[0].value as string
                                                        )
                                                :
                                                (
                                                    this.state.filteredColumn !== undefined &&
                                                        (this.state.filteredColumn.key as string === this.CREATED_CODE ||
                                                            this.state.filteredColumn.key as string === this.AUTHOR_CODE) ?
                                                        (
                                                            this.state.filteredColumn.key as string === this.CREATED_CODE ?

                                                                (
                                                                    (this.state.filteredColumn.value as boolean) ?
                                                                        GetDateObjectFromString(a.created).dateObj.getTime() -
                                                                        GetDateObjectFromString(b.created).dateObj.getTime()
                                                                        :
                                                                        GetDateObjectFromString(b.created).dateObj.getTime() -
                                                                        GetDateObjectFromString(a.created).dateObj.getTime()
                                                                )
                                                                :
                                                                (
                                                                    (this.state.filteredColumn.value as boolean) ?
                                                                        a.autor.localeCompare(b.autor)
                                                                        :
                                                                        b.autor.localeCompare(a.autor)
                                                                )
                                                        )
                                                        :
                                                        a.idnote - b.idnote
                                                )
                                        )
                                        .slice(this.state.currentPaginationIndex * this.state.maxElementByPage, (this.state.currentPaginationIndex * this.state.maxElementByPage) + this.state.maxElementByPage)
                                        .map(f => {
                                            return <tr
                                                title={this.props.GLOBAL_DATA.DB_TYPES_NOTES !== undefined &&
                                                    this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(tn => tn.id === f.type).length > 0 ?
                                                    this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(tn => tn.id === f.type)[0].title
                                                    : undefined}
                                                className="CUST_FORM_TABLE_LINE"
                                                style={{
                                                    backgroundColor: this.props.GLOBAL_DATA.DB_TYPES_NOTES !== undefined &&
                                                        this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(tn => tn.id === f.type).length > 0 ?
                                                        "#" + this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(tn => tn.id === f.type)[0].colorString as string
                                                        : undefined,
                                                    display:
                                                        this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                                            this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.displayUnity !== 0 && IsCustForm(n.title) && parseInt(GetNoteCustParentIdFromTitle(n.title)) !== n.idnote && parseInt(GetNoteCustParentIdFromTitle(n.title)) === this.props.note.idnote).length > 0 &&
                                                            (
                                                                this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.displayUnity !== 0 && IsCustForm(n.title) && parseInt(GetNoteCustParentIdFromTitle(n.title)) !== n.idnote && parseInt(GetNoteCustParentIdFromTitle(n.title)) === this.props.note.idnote && n.description.length === 0).length > 1
                                                            ) ? "none" : undefined
                                                }}
                                            >
                                                <td className="CUST_FORM_VIEW_VALUE TEXT_TICKET" style={{ cursor: "pointer", display: "table-cell", paddingTop: "14px" }} onClick={() => {
                                                    (this.props.GLOBAL_FUNCTION.onLoadNoteAsMemory as Function)(f)
                                                }}>
                                                    <div dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.reusedNote, 18) }}></div>
                                                </td>
                                                <td className="CUST_FORM_VIEW_VALUE TEXT_TICKET" style={{ cursor: "pointer", display: "table-cell", paddingTop: "14px" }} onClick={() => {
                                                    (
                                                        GetNoteCustFormData(f.description, f.idnote).lines.length !==
                                                        GetNoteCustFormData(this.props.note.description, this.props.note.idnote).lines.length
                                                    ) &&
                                                        (
                                                            this.props.GLOBAL_FUNCTION.onUpdateAllCustFormChild as Function
                                                        )(this.props.note.idnote, f.idnote)
                                                }}>
                                                    {
                                                        (GetNoteCustFormData(f.description, f.idnote).lines.length !==
                                                            GetNoteCustFormData(this.props.note.description, this.props.note.idnote).lines.length) &&
                                                        <div dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.form_sync, 16, "EB6B16") }}>

                                                        </div>
                                                    }
                                                </td>
                                                {/*Affichage du titre du type*/}
                                                <td style={{ position: "relative", display: "table-cell", minWidth: "200px" }} className="TEXT_TICKET">
                                                    <div className="CUST_VIEW_TYPE_TITLE_ZONE" style={{
                                                        borderColor:
                                                            this.props.GLOBAL_DATA.DB_TYPES_NOTES !== undefined &&
                                                                this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(tn => tn.id === f.type).length > 0 ?
                                                                "#" + this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(tn => tn.id === f.type)[0].colorString as string : undefined
                                                    }}>
                                                        <span>{
                                                            this.props.GLOBAL_DATA.DB_TYPES_NOTES !== undefined &&
                                                            (this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(tn => tn.id === f.type).length > 0 &&
                                                                this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(tn => tn.id === f.type)[0].title.indexOf(CupBoardCode) > -1 ?
                                                                this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(tn => tn.id === f.type)[0].title.split(CupBoardCode)[1] :
                                                                this.props.GLOBAL_DATA.DB_TYPES_NOTES.filter(tn => tn.id === f.type)[0].title)
                                                        }</span>
                                                    </div>
                                                </td>

                                                {
                                                    GetNoteCustFormData(f.description, f.idnote).lines
                                                        .map(l => {
                                                            return <td className="CUST_FORM_VIEW_VALUE TEXT_TICKET" style={{
                                                                textAlign: l.type === "F_TXT" ? "left" : undefined,
                                                                border: (this.props.GLOBAL_DATA.DB_TASKS !== undefined &&
                                                                    this.props.GLOBAL_DATA.DB_TASKS.filter(t => f.idnote === t.idnote && t.title.indexOf(l.id) > -1).length > 0) ? "2px dashed #e1e1e1" : undefined
                                                            }}>
                                                                {
                                                                    l.type !== "F_RTX" && l.type !== "F_ML_TXT" && l.type !== "F_LINK" && l.type !== "F_BOOLEAN" &&
                                                                    (l.type === "F_CHOICE" && (l._config_value as string === "1" && (l.value as string).length > 0) ? (l.value as string).split("|").join(", ") : l.value)
                                                                }

                                                                {
                                                                    l.type === "F_LINK" &&
                                                                    <a target="_blank" href={l.value !== undefined && l.value.split(":").length > 1 ? decodeURIComponent(l.value.split(":")[1]) : ""}>
                                                                        {
                                                                            l.value !== undefined && l.value.split(":").length > 0 &&
                                                                            l.value.split(":")[0]
                                                                        }
                                                                    </a>
                                                                }

                                                                {
                                                                    l.type === "F_BOOLEAN" &&
                                                                    <span onClick={() => {
                                                                        //Recherche de la tache associée
                                                                        //------------------------------
                                                                        if (this.props.GLOBAL_DATA.DB_TASKS !== undefined &&
                                                                            this.props.GLOBAL_DATA.DB_TASKS.filter(t => f.idnote === t.idnote && t.title.indexOf(l.id) > -1).length > 0) {
                                                                            (this.props.GLOBAL_FUNCTION.checkTaskNote as Function)(this.props.GLOBAL_DATA.DB_TASKS.filter(t => f.idnote === t.idnote && t.title.indexOf(l.id) > -1)[0].idtask,
                                                                                !this.props.GLOBAL_DATA.DB_TASKS.filter(t => f.idnote === t.idnote && t.title.indexOf(l.id) > -1)[0].finished);

                                                                        }
                                                                    }} style={{
                                                                        cursor:
                                                                            (this.props.GLOBAL_DATA.DB_TASKS !== undefined &&
                                                                                this.props.GLOBAL_DATA.DB_TASKS.filter(t => f.idnote === t.idnote && t.title.indexOf(l.id) > -1).length > 0) ? "pointer" : undefined
                                                                    }} dangerouslySetInnerHTML={{
                                                                        __html: GetIconFromType((l.value as string) === "1" ? ENU_ICON_TYPE.YES : ENU_ICON_TYPE.NO, 24,
                                                                            (l.value as string) === "1" ? "16e038" : "ea1010")
                                                                    }}></span>
                                                                }

                                                                {
                                                                    l.type === "F_RTX" || l.type === "F_ML_TXT" &&
                                                                    <div style={{ textAlign: "left" }} dangerouslySetInnerHTML={{ __html: decodeURIComponent(l.value as string) }}>
                                                                    </div>
                                                                }
                                                            </td>
                                                        })
                                                }
                                                <td className="CUST_FORM_VIEW_VALUE TEXT_TICKET">
                                                    {
                                                        this.props.GLOBAL_DATA.filterByLastChange !== undefined &&
                                                            this.props.GLOBAL_DATA.filterByLastChange ?
                                                            f.modified :
                                                            f.created
                                                    }
                                                </td>
                                                <td className="CUST_FORM_VIEW_VALUE TEXT_TICKET">
                                                    {
                                                        f.autor
                                                    }
                                                </td>
                                                {
                                                    this.props.GLOBAL_DATA.DB_ATTACHMENT_LINKS !== undefined &&
                                                    this.props.GLOBAL_DATA.DB_ATTACHMENT_LINKS.filter(a => a.nodeId === f.idnote).length > 0 &&
                                                    <td className="CUST_FORM_VIEW_VALUE TEXT_TICKET" onClick={(() => {
                                                        (this.props.GLOBAL_FUNCTION.showAttahment as Function)(f)
                                                    })} title={this.props.GLOBAL_DATA.DB_ATTACHMENT_LINKS !== undefined ? this.props.GLOBAL_DATA.DB_ATTACHMENT_LINKS
                                                        .filter(a => a.nodeId === f.idnote)
                                                        .map(a => a.title).toString() : undefined}>

                                                        <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.attachment, 14) }}></span>

                                                    </td>
                                                }
                                                {
                                                    this.props.GLOBAL_DATA.DB_TASKS !== undefined &&
                                                    this.props.GLOBAL_DATA.DB_TASKS.filter(t => t.idnote === f.idnote && !t.finished).length > 0 &&
                                                    <td className="CUST_FORM_VIEW_VALUE TEXT_TICKET" onClick={() => {
                                                        (this.props.GLOBAL_FUNCTION.onLoadNoteAsMemory as Function)(f);
                                                    }}>

                                                        <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.pendingtasks, 14, "ff0000") }}></span>

                                                    </td>
                                                }
                                            </tr>
                                        })

                                }



                                <tr>
                                    <td colSpan={100} style={{ textAlign: "center" }}>
                                        {
                                            this.getAllPagesFromResult().map(p => {
                                                return <span key={p.key as string}
                                                    onClick={() => {
                                                        this.onSelectPage((p.key as number) - 1)
                                                    }}
                                                    className={this.state.currentPaginationIndex + 1 === (p.key as number) ? "PAGE_INDICATOR PAGE_INDICATOR_SELECTED" : "PAGE_INDICATOR"}>
                                                    {
                                                        p.key
                                                    }
                                                </span>
                                            })
                                        }
                                    </td>
                                </tr>

                            </tbody>

                        </table>

                    </div>
                }




            </div >
        } catch (ex) {
            return <div>
                {
                    (this.props.GLOBAL_FUNCTION.onShowLoader as Function)(false)
                }
            </div>
        }

    }
}