import * as React from "react";
import { I_FOLDER_BOX_PROPS, I_FOLDER_BOX_PROPS_STATE } from "../../../02_Interfaces/I_FOLDER_BOX";
import { Guid } from "guid-typescript";
import { ENU_ICON_TYPE, ENU_SHARE_TYPE, I_KEY_VALUE_DATA, KeyValuePair } from "../../../02_Interfaces/IGLOBAL";
import { SHORT_NOTE_ITEM } from "./NOTE_ITEM_NEW_01";
import { GetDateObjectFromString, GetDistinctListOfString, GetIconFromType, IsCustForm } from "../../../04_TOOLS/tools";
import { GLOBAL_SEARCH_BOX } from "./GLOBAL_SEARCH";
import { SEARCHBOX } from "../SEARCHBOX";
import { I_GROUPE_NOTE_ITEM, I_NOTE_ITEM } from "../../../02_Interfaces/INOTE";
import { I_MSG_OPTION } from "../../../02_Interfaces/IMSGBOX";
import { LOADER_BASIC } from "../LOADER_BASIC";
import { GROUP_BOX_ITEM } from "./GROUP_BOX_ITEM";
import { COMPTA_STAT } from "../COMPTA_STAT";
import { ANNUAIRE_POPUP } from "../../Popup/ANNUAIRE";
import { SHARER_BOX } from "../../Popup/SHARER";
import { FIELD_CHECK_BOX } from "../../Fields/FIELD_CHECK_BOX";


export class PANNEL_BOX extends React.Component<I_FOLDER_BOX_PROPS, I_FOLDER_BOX_PROPS_STATE> {
    constructor(props: I_FOLDER_BOX_PROPS) {
        super(props);
        this.state = {
            menu: [
                {
                    value: "Recherche globale",
                    key: "3",
                    data: ENU_ICON_TYPE.search
                },
                {
                    value: "Favoris",
                    key: "10",
                    data: ENU_ICON_TYPE.markBook
                },
                this.props.GLOBAL_DATA.DB_TASKS !== undefined &&
                    this.props.GLOBAL_DATA.DB_TASKS.filter(t => !t.finished).length > 0 ?
                    {
                        value: "Taches expirées et a venirs",
                        key: "2",
                        data: ENU_ICON_TYPE.pendingtasks
                    } : {
                        value: "",
                        key: "-1",
                        data: ENU_ICON_TYPE.empty
                    },
                this.props.GLOBAL_DATA.localMemory.length > 0 ?
                    {
                        value: "Sauvegardes",
                        key: "4",
                        data: ENU_ICON_TYPE.saveAsLocal
                    } : {
                        value: "",
                        key: "-1",
                        data: ENU_ICON_TYPE.empty
                    },

                {
                    value: "Pièces jointes",
                    key: "5",
                    data: ENU_ICON_TYPE.attachment
                },
                {
                    value: "Les notes ouvertes",
                    key: "6",
                    data: ENU_ICON_TYPE.reusedNote
                },
                {
                    value: "Dernière navigation",
                    key: "1",
                    data: ENU_ICON_TYPE.path
                },
                {
                    value: "Les autres carnets",
                    key: "7",
                    data: ENU_ICON_TYPE.folder
                },
                (this.props.GLOBAL_DATA._DB_COMPTA !== undefined &&
                    this.props.GLOBAL_DATA._DB_COMPTA.length > 0) ?
                    {
                        value: "Evolution des comptes",
                        key: "8",
                        data: ENU_ICON_TYPE.euroDevise
                    } : {
                        value: "",
                        key: "-1",
                        data: ENU_ICON_TYPE.empty
                    },
                (this.props.GLOBAL_DATA.selectedGroup !== undefined &&
                    this.props.GLOBAL_DATA.selectedGroup.isAuthor) ?
                    {
                        value: "Partager avec ...",
                        key: "9",
                        data: ENU_ICON_TYPE.community
                    } : {
                        value: "",
                        key: "-1",
                        data: ENU_ICON_TYPE.empty
                    }

                ,
                (this.props.GLOBAL_DATA.allLockedNotes.filter(notif => notif.groupId === (this.props.GLOBAL_DATA.selectedGroup as I_GROUPE_NOTE_ITEM).id).length === 0) ?
                    {
                        value: "",
                        key: "-1",
                        data: ENU_ICON_TYPE.empty
                    } :
                    {
                        value: "Notes vérouillées",
                        key: "11",
                        data: ENU_ICON_TYPE.admin
                    }

            ],
            pannelIsOpen: false
        }

        this.onSelectMenu = this.onSelectMenu.bind(this);
        this.onForceOpenOrClosePanel = this.onForceOpenOrClosePanel.bind(this);
    }

    private Ref_GloablSearch = React.createRef<SEARCHBOX>();

    onSelectMenu = (menu: I_KEY_VALUE_DATA) => {


        if (this.state.selectedMenu === undefined || (this.state.selectedMenu !== undefined && this.state.selectedMenu.key !== menu.key))

            (this.props.GLOBAL_FUNCTION.ShowInfoMessage as Function)({
                key: Guid.create(),
                value: "Affichage de la vue '" + menu.value + "'",
                data: menu.data as ENU_ICON_TYPE

            } as I_KEY_VALUE_DATA);

        this.setState({ selectedMenu: menu }, () => {
            if (menu.key === "9" && this.props.GLOBAL_DATA.selectedGroup !== undefined) {
                (this.props.GLOBAL_FUNCTION.onShowShareBox as Function)(ENU_SHARE_TYPE.groupe, this.props.GLOBAL_DATA.selectedGroup as I_GROUPE_NOTE_ITEM, true);

                (this.props.GLOBAL_FUNCTION.ShowInfoMessage as Function)({
                    key: Guid.create(),
                    value: "Chargement des données de partage du carnet...",
                    data: ENU_ICON_TYPE.searchResult

                } as I_KEY_VALUE_DATA);
            }
        })
    }

    onForceOpenOrClosePanel = () => {
        this.setState({ pannelIsOpen: !this.state.pannelIsOpen }, () => {

            (this.props.GLOBAL_FUNCTION.ShowInfoMessage as Function)({
                key: Guid.create(),
                value: this.state.pannelIsOpen ? "Verrouillage du panel" : "Déverrouillage du panel",
                data: ENU_ICON_TYPE.searchResult

            } as I_KEY_VALUE_DATA);

            (this.props.GLOBAL_FUNCTION.onShowLoader as Function)(false);
        });
    }
    componentDidMount(): void {
        this.onSelectMenu({
            value: "Recherche globale",
            key: "3",
            data: ENU_ICON_TYPE.search
        });


        //Controle des notes lockées
        if (this.props.GLOBAL_DATA.allLockedNotes.filter(notif => notif.groupId === (this.props.GLOBAL_DATA.selectedGroup as I_GROUPE_NOTE_ITEM).id && notif.locker).length > 0) {
            let tmpMSGOption: I_MSG_OPTION = {
                allMSG: [
                    {
                        key: "Note lockée détectée",
                        value: "Au moins une note lockée à été détectée ( " +
                            (this.props.GLOBAL_DATA.allLockedNotes.filter(notif => notif.groupId === (this.props.GLOBAL_DATA.selectedGroup as I_GROUPE_NOTE_ITEM).id && notif.locker).length) +
                            " lock" +
                            (
                                this.props.GLOBAL_DATA.allLockedNotes.filter(notif => notif.groupId === (this.props.GLOBAL_DATA.selectedGroup as I_GROUPE_NOTE_ITEM).id && notif.locker).length > 1 ?
                                    "s" : ""
                            ) +
                            " ) . Cliquez sur 'Voir' pour continuer ...",
                        data: ENU_ICON_TYPE.lock
                    }
                ],
                showCloseButton: true,
                buttons: [
                    {
                        title: "Voir",
                        F_BUTTON_ACTION: () => {
                            this.setState({ pannelIsOpen: true, selectedMenu: this.state.menu.filter(m => m.key as string === "11")[0] }, () => {
                                (this.props.GLOBAL_FUNCTION.closePopup as Function)()
                            });

                        }
                    }
                ]
            };

            (this.props.GLOBAL_FUNCTION.showMessage as Function)(tmpMSGOption)
        }
        //==========================

    }

    componentDidUpdate(prevProps: Readonly<I_FOLDER_BOX_PROPS>, prevState: Readonly<I_FOLDER_BOX_PROPS_STATE>, snapshot?: any): void {
        // console.log(this.props.GLOBAL_DATA.DB_TASKS?.filter(g=>g.taskGroupId as number === (this.props.GLOBAL_DATA.selectedGroup as I_GROUPE_NOTE_ITEM).id && !g.finished ));
        let menu: I_KEY_VALUE_DATA[] = [
            {
                value: "Recherche globale",
                key: "3",
                data: ENU_ICON_TYPE.search
            },
            {
                value: "Favoris",
                key: "10",
                data: ENU_ICON_TYPE.markBook
            },
            this.props.GLOBAL_DATA.DB_TASKS !== undefined &&
                this.props.GLOBAL_DATA.DB_TASKS.filter(t => !t.finished).length > 0 ?
                {
                    value: "Taches expirées et a venirs",
                    key: "2",
                    data: ENU_ICON_TYPE.pendingtasks
                } : {
                    value: "",
                    key: "-1",
                    data: ENU_ICON_TYPE.empty
                },
            this.props.GLOBAL_DATA.localMemory.length > 0 ?
                {
                    value: "Sauvegardes",
                    key: "4",
                    data: ENU_ICON_TYPE.saveAsLocal
                } : {
                    value: "",
                    key: "-1",
                    data: ENU_ICON_TYPE.empty
                },
            this.props.GLOBAL_DATA.DB_ATTACHMENT_LINKS !== undefined &&
                this.props.GLOBAL_DATA.DB_ATTACHMENT_LINKS.length > 0 ?
                {
                    value: "Pièces jointes",
                    key: "5",
                    data: ENU_ICON_TYPE.attachment
                } : {
                    value: "",
                    key: "-1",
                    data: ENU_ICON_TYPE.empty
                },
            {
                value: "Les notes ouvertes",
                key: "6",
                data: ENU_ICON_TYPE.reusedNote
            },
            {
                value: "Dernière navigation",
                key: "1",
                data: ENU_ICON_TYPE.path
            },
            {
                value: "Les autres carnets",
                key: "7",
                data: ENU_ICON_TYPE.folder
            },
            (this.props.GLOBAL_DATA._DB_COMPTA !== undefined &&
                this.props.GLOBAL_DATA._DB_COMPTA.length > 0) ?
                {
                    value: "Evolution des comptes",
                    key: "8",
                    data: ENU_ICON_TYPE.euroDevise
                } : {
                    value: "",
                    key: "-1",
                    data: ENU_ICON_TYPE.empty
                },
            (this.props.GLOBAL_DATA.selectedGroup !== undefined &&
                this.props.GLOBAL_DATA.selectedGroup.isAuthor) ?
                {
                    value: "Partager avec ...",
                    key: "9",
                    data: ENU_ICON_TYPE.community
                } : {
                    value: "",
                    key: "-1",
                    data: ENU_ICON_TYPE.empty
                }
            ,
            (this.props.GLOBAL_DATA.allLockedNotes.filter(notif => notif.groupId === (this.props.GLOBAL_DATA.selectedGroup as I_GROUPE_NOTE_ITEM).id && notif.locker).length === 0) ?
                {
                    value: "",
                    key: "-1",
                    data: ENU_ICON_TYPE.empty
                } :
                {
                    value: "Notes vérouillées",
                    key: "11",
                    data: ENU_ICON_TYPE.authorItem
                }
        ];
        if (menu.filter(m => parseInt(m.key) > 0).length !== this.state.menu.filter(m => parseInt(m.key) > 0).length) {
            this.setState({ menu: menu }, () => {
                if (menu.filter(m => parseInt(m.key) > 0 && this.state.selectedMenu !== undefined && parseInt(this.state.selectedMenu.key) === parseInt(m.key)).length === 0) {
                    this.onSelectMenu(menu[0])
                }
            });
        }
    }

    render(): JSX.Element {
        return <div>
            <div className="APP_PANEL_BOX">
                <div className="APP_PANEL_TITLE_BOX">
                    <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.searchResult, 22) }}></span>
                    &nbsp;&nbsp;&nbsp;
                    <span>Panneau de navigation et de recherche</span>
                </div>

                <div className='APP_FOLDER_MENU_EXTRA_BUTTON_ZONE'>
                    {
                        this.state.menu
                            .filter(m => parseInt(m.key) > 0)
                            .map(m => {
                                return <div key={m.key} className={this.state.selectedMenu !== undefined && this.state.selectedMenu.key === m.key ? 'APP_FOLDER_MENU_EXTRA_BUTTON APP_FOLDER_MENU_EXTRA_BUTTON_SELECTED' : 'APP_FOLDER_MENU_EXTRA_BUTTON'}
                                    onClick={() => {
                                        this.onSelectMenu(m)
                                    }} title={m.value}>
                                    <span dangerouslySetInnerHTML={{ __html: GetIconFromType(m.data as ENU_ICON_TYPE, 24) }}></span>

                                </div>
                            })
                    }
                </div>

                {
                    this.state.selectedMenu !== undefined &&
                    <div className="APP_PANEL_MENU_TITLE_ZONE">
                        <div><span dangerouslySetInnerHTML={{ __html: GetIconFromType(this.state.selectedMenu.data, 18) }}></span>
                            <span className="SPAN_SEP"></span>
                            <span>{
                                this.state.selectedMenu.value
                            }</span>
                        </div>

                        <div style={{ textAlign: "center", position: "relative", display: "block", top: "-14px" }}>
                            <span>
                                {/* <span dangerouslySetInnerHTML={{ __html: GetIconFromType(this.state.pannelIsOpen ? ENU_ICON_TYPE.lock : ENU_ICON_TYPE.see, 12) }}></span>
                            <span className="SPAN_SEP"></span> */}
                                <FIELD_CHECK_BOX forceDefaultValue={true} defaultValue={this.state.pannelIsOpen}
                                    F_Action={() => this.onForceOpenOrClosePanel()}
                                    title={!this.state.pannelIsOpen ? "Dévérouillé" : "Vérouillé"} />
                            </span>



                            {/* <span className="APP_FOLDER_TABLE_BUTTON" onClick={() => {
                            this.onForceOpenOrClosePanel();
                        }}>
                            <span dangerouslySetInnerHTML={{ __html: GetIconFromType(this.state.pannelIsOpen ? ENU_ICON_TYPE.lock : ENU_ICON_TYPE.see, 12) }}></span>
                            <span className="SPAN_SEP"></span>
                            {
                                !this.state.pannelIsOpen ? "Dévérouillé : se masque automatiquement à la sortie" : "Vérouillé : Reste affiché"
                            }
                        </span> */}
                        </div>
                    </div>
                }


                <div className="APP_ICON_NAVIGATION_INDICATOR" style={{ top: "1%", right: "1%" }}>
                    <span dangerouslySetInnerHTML={{ __html: GetIconFromType(this.state.pannelIsOpen ? ENU_ICON_TYPE.lock : ENU_ICON_TYPE.see, 145, "e1e1e1") }}></span>
                </div>

                <div className="APP_FOLDER_MENU_EXTRA_MAIN_DATA_ZONE">
                    {

                        this.state.selectedMenu !== undefined &&
                        this.state.selectedMenu.key === "1" &&
                        <div>
                            <div style={{ textAlign: "right", marginBottom: "14px", position: "relative", display: "block" }}>
                                {
                                    <SEARCHBOX ref={this.Ref_GloablSearch} placeHolder="Saisissez un mot clé" F_UPDATE_SEARCHVALUE={() => {
                                        (this.props.GLOBAL_FUNCTION.onShowLoader as Function)(false);
                                    }} forceUpdateEveryTime={true} APPFUNCTION={this.props.GLOBAL_FUNCTION} counter={this.props.GLOBAL_DATA.allPath
                                        .filter(rn =>
                                            this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                                this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === rn.noteId).length > 0 &&
                                                this.Ref_GloablSearch.current !== null ?
                                                this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === rn.noteId)[0].title.toUpperCase().indexOf(this.Ref_GloablSearch.current.state.searchValue.toUpperCase()) > -1 : true
                                        )
                                        .filter(rn => this.props.GLOBAL_DATA.selectedGroup !== undefined && rn.groupeId === this.props.GLOBAL_DATA.selectedGroup.id).length} />
                                }
                            </div>
                            {
                                this.props.GLOBAL_DATA.allPath
                                    .filter(rn =>
                                        this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                            this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === rn.noteId).length > 0 &&
                                            this.Ref_GloablSearch.current !== null ?
                                            this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === rn.noteId)[0].title.toUpperCase().indexOf(this.Ref_GloablSearch.current.state.searchValue.toUpperCase()) > -1 : true
                                    )
                                    .filter(rn => this.props.GLOBAL_DATA.selectedGroup !== undefined && rn.groupeId === this.props.GLOBAL_DATA.selectedGroup.id)
                                    .sort((a, b) => { return b.requested.localeCompare(a.requested) })
                                    .map(p => {
                                        return <div key={p.noteId} style={{ position: "relative", display: "block", paddingLeft: "54px" }}>
                                            {
                                                this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                                this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === p.noteId).length > 0 &&
                                                <SHORT_NOTE_ITEM
                                                    showNoteShortDate={true}
                                                    showOnly={true}
                                                    maxLevel={0}
                                                    asChild={true}
                                                    GLOBAL_DATA={this.props.GLOBAL_DATA}
                                                    GLOBAL_FUNCTION={this.props.GLOBAL_FUNCTION}
                                                    note={
                                                        this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === p.noteId)[0]
                                                    } />
                                            }
                                        </div>
                                    })
                            }
                        </div>
                    }



                    {
                        this.state.selectedMenu !== undefined &&
                        this.state.selectedMenu.key === "3" &&
                        <div >
                            <div style={{ textAlign: "right", marginBottom: "14px", position: "relative", display: "block" }}>
                                <SEARCHBOX ref={this.Ref_GloablSearch} placeHolder="Saisissez un mot clé puis lancer la recherhe à l'aide de la touche Entrée"
                                    F_UPDATE_SEARCHVALUE={() => {

                                        if (this.Ref_GloablSearch.current !== null) {
                                            (this.props.GLOBAL_FUNCTION.onSearchGlobal as Function)(this.Ref_GloablSearch.current.state.searchValue);

                                            if (!this.state.pannelIsOpen)
                                                this.onForceOpenOrClosePanel()
                                        }
                                    }} forceUpdateEveryTime={false} APPFUNCTION={this.props.GLOBAL_FUNCTION} />

                                {/* Affichage du chargement */}
                                {
                                    (this.props.GLOBAL_DATA.loading || this.props.GLOBAL_DATA.loadingPendingData) &&
                                    <LOADER_BASIC />
                                }

                            </div>
                            <div className="APP_NO_FOLDER_SELECTED_FOLDER_MAIN_BOX" style={{ left: "-300px", right: "0px" }}>

                                <GLOBAL_SEARCH_BOX GLOBAL_DATA={this.props.GLOBAL_DATA} GLOBAL_FUNCTION={this.props.GLOBAL_FUNCTION} />


                            </div>
                        </div>

                    }

                    {
                        this.state.selectedMenu !== undefined &&
                        this.state.selectedMenu.key === "5" &&
                        <div>
                            <div style={{ textAlign: "right", marginBottom: "14px", position: "relative", display: "block" }}>
                                {
                                    <SEARCHBOX ref={this.Ref_GloablSearch} placeHolder="Saisissez un mot clé" F_UPDATE_SEARCHVALUE={() => {
                                        (this.props.GLOBAL_FUNCTION.onShowLoader as Function)(false);
                                    }} forceUpdateEveryTime={true} APPFUNCTION={this.props.GLOBAL_FUNCTION} counter={
                                        this.props.GLOBAL_DATA.DB_ATTACHMENT_LINKS ?
                                            GetDistinctListOfString(this.props.GLOBAL_DATA.DB_ATTACHMENT_LINKS
                                                .filter(att => this.Ref_GloablSearch.current !== null ? att.title.toUpperCase().indexOf(this.Ref_GloablSearch.current.state.searchValue.toUpperCase()) > -1 : true)
                                                .sort((a, b) => { return b.nodeId - a.nodeId })
                                                .map(att => {
                                                    return att.nodeId.toString()
                                                })
                                            ).length : 0
                                    } />
                                }
                            </div>
                            <div className="APP_NO_FOLDER_SELECTED_FOLDER_MAIN_BOX" style={{ left: "0px" }} >
                                {
                                    this.props.GLOBAL_DATA.DB_ATTACHMENT_LINKS !== undefined &&
                                    GetDistinctListOfString(this.props.GLOBAL_DATA.DB_ATTACHMENT_LINKS
                                        .filter(att => this.Ref_GloablSearch.current !== null ? att.title.toUpperCase().indexOf(this.Ref_GloablSearch.current.state.searchValue.toUpperCase()) > -1 : true)
                                        .sort((a, b) => { return b.nodeId - a.nodeId })
                                        .map(att => {
                                            return att.nodeId.toString()
                                        })
                                    ).map(attS => {
                                        return <div key={attS} className="APP_ATTACHMENT_PANEL_ITEM_BOX">
                                            <div title="Ouvrir la note" onClick={() => {
                                                if (this.props.GLOBAL_DATA.DB_NOTES !== undefined)
                                                    (this.props.GLOBAL_FUNCTION.onLoadNoteAsMemory as Function)(this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === parseInt(attS))[0])
                                            }}>
                                                {
                                                    this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                                    this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === parseInt(attS)).length > 0 &&
                                                    <div>
                                                        <span className="APP_NOTE_ITEM_DATE" style={{ position: "relative", display: "inline-block", left: "0px", top: "0px" }}>
                                                            {
                                                                GetDateObjectFromString(this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === parseInt(attS))[0].created).dateString
                                                            }
                                                        </span>
                                                        &nbsp;
                                                        {
                                                            IsCustForm(this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === parseInt(attS))[0].title) &&
                                                            <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.form_child, 14) }}></span>
                                                        }
                                                        &nbsp;
                                                        <span className="APP_ATTACHMENT_NOTE_TITLE">{
                                                            IsCustForm(this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === parseInt(attS))[0].title) ?
                                                                this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === parseInt(attS))[0].title.split("]-")[1]
                                                                :
                                                                this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === parseInt(attS))[0].title
                                                        }</span>
                                                    </div>
                                                }
                                            </div>
                                            <div title="Ouvir les pièces jointes de la note">
                                                {
                                                    this.props.GLOBAL_DATA.DB_ATTACHMENT_LINKS !== undefined &&
                                                    this.props.GLOBAL_DATA.DB_ATTACHMENT_LINKS
                                                        .filter(att => att.nodeId === parseInt(attS))
                                                        .map(att => {
                                                            return <div key={att.id} className="APP_ATTACHMENT_PANEL_ITEM" onClick={() => {
                                                                if (this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                                                    this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === att.nodeId).length > 0)
                                                                    (this.props.GLOBAL_FUNCTION.showAttahment as Function)(this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === att.nodeId)[0], att.id)
                                                            }}>
                                                                <span className="APP_NOTE_PJ_ITEM" style={{ fontSize: "16px" }}>
                                                                    <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.attachment, 16) }}></span>
                                                                    &nbsp;&nbsp;&nbsp;
                                                                    <span>{
                                                                        att.title
                                                                    }</span>
                                                                </span>
                                                            </div>
                                                        })
                                                }

                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                        </div>
                    }

                    {
                        this.state.selectedMenu !== undefined &&
                        this.state.selectedMenu.key === "4" &&
                        <div>
                            <div style={{ textAlign: "right", marginBottom: "14px", position: "relative", display: "block" }}>
                                {
                                    <SEARCHBOX ref={this.Ref_GloablSearch} placeHolder="Saisissez un mot clé" F_UPDATE_SEARCHVALUE={() => {
                                        (this.props.GLOBAL_FUNCTION.onShowLoader as Function)(false);
                                    }} forceUpdateEveryTime={true} APPFUNCTION={this.props.GLOBAL_FUNCTION} />
                                }
                            </div>
                            {
                                GetDistinctListOfString(this.props.GLOBAL_DATA.localMemory
                                    .filter(lm => this.Ref_GloablSearch.current !== null &&
                                        this.Ref_GloablSearch.current.state.searchValue.length > 0 ? (lm.searchNoteValue as string).toUpperCase().indexOf(this.Ref_GloablSearch.current.state.searchValue.toUpperCase()) > -1 : true)
                                    .map(lm => { return (lm.selectedGroupNote as I_GROUPE_NOTE_ITEM).id.toString() }))
                                    .map(lm => {
                                        return <div key={lm} className="APP_PANEL_LOCAL_MEMORY_ITEM">
                                            <span className="APP_PANEL_HEADER_ITEM">
                                                <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.folder, 24) }} style={{ position: "relative", top: "4px" }}>

                                                </span>
                                                <span>{
                                                    this.props.GLOBAL_DATA.localMemory.filter(_lm => parseInt(lm) === (_lm.selectedGroupNote as I_GROUPE_NOTE_ITEM).id)[0].selectedGroupNote?.title
                                                }</span>
                                            </span>
                                            <span>
                                                {
                                                    this.props.GLOBAL_DATA.localMemory.filter(_lm => parseInt(lm) === (_lm.selectedGroupNote as I_GROUPE_NOTE_ITEM).id).map(_lm => {
                                                        return <div key={_lm.id} className="APP_DETAIL_MEMORY_ITEM" >
                                                            <span onClick={() => {
                                                                if (_lm.idNote !== undefined && _lm.selectedGroupNote !== undefined && this.props.GLOBAL_DATA.selectedGroup !== undefined && this.props.GLOBAL_DATA.selectedGroup.id !== _lm.selectedGroupNote.id) {
                                                                    let tmpMSGOption: I_MSG_OPTION = {
                                                                        allMSG: [
                                                                            {
                                                                                key: "Ouverture de la note externe...",
                                                                                value: "La note sélectionnée est située dans un autre carnet. Voulez-vous réellement quitter ce carnet pour ouvrir la note '" +
                                                                                    (
                                                                                        IsCustForm(_lm.searchNoteValue as string) ?
                                                                                            _lm.searchNoteValue?.split("]-")[1]
                                                                                            :
                                                                                            _lm.searchNoteValue
                                                                                    )
                                                                                    + "' ?",
                                                                                data: ENU_ICON_TYPE.folder
                                                                            }
                                                                        ],
                                                                        showCloseButton: true,
                                                                        buttons: [
                                                                            {
                                                                                title: "Ouvrir la note",
                                                                                F_BUTTON_ACTION: () => {
                                                                                    if (_lm.searchNoteValue !== undefined) {
                                                                                        (this.props.GLOBAL_FUNCTION.onLoadLocalMemory as Function)(_lm.id);
                                                                                        (this.props.GLOBAL_FUNCTION.closePopup as Function)();
                                                                                    }
                                                                                }
                                                                            }
                                                                        ]
                                                                    };

                                                                    (this.props.GLOBAL_FUNCTION.showMessage as Function)(tmpMSGOption)
                                                                } else {
                                                                    if (_lm.searchNoteValue !== undefined) {
                                                                        (this.props.GLOBAL_FUNCTION.onLoadLocalMemory as Function)(_lm.id);
                                                                        (this.props.GLOBAL_FUNCTION.closePopup as Function)();
                                                                    }
                                                                }
                                                            }}>
                                                                <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.saveAsLocal, 16) }}></span>
                                                                &nbsp;
                                                                &nbsp;
                                                                &nbsp;
                                                                <span className="APP_NO_FOLDER_SELECTED_RESULT_TYPE" style={{ backgroundColor: _lm.selectedTypeNote !== undefined ? "#" + _lm.selectedTypeNote[0].data : undefined }}>
                                                                    <span className="TEXT_TICKET">{
                                                                        _lm.selectedTypeNote !== undefined &&
                                                                        _lm.selectedTypeNote[0].value
                                                                    }</span>
                                                                </span>

                                                                <span className="SPAN_SEP"></span>
                                                                <span className="APP_DETAIL_NOTE_TITLE">
                                                                    {
                                                                        IsCustForm(_lm.searchNoteValue as string) ?
                                                                            _lm.searchNoteValue?.split("]-")[1]
                                                                            :
                                                                            _lm.searchNoteValue
                                                                    }
                                                                </span>
                                                                <span className="SPAN_SEP"></span>
                                                                <span className="APP_NOTE_ITEM_DATE" style={{ position: "relative", display: "inline-block", left: "0px", top: "0px" }}>
                                                                    {
                                                                        GetDateObjectFromString(_lm.created).dateString
                                                                    }
                                                                </span>
                                                            </span>
                                                            &nbsp;&nbsp;&nbsp;
                                                            <span className="APP_FOLDER_TABLE_BUTTON" style={{ opacity: "0" }} onClick={() => {
                                                                (this.props.GLOBAL_FUNCTION.OnDeleteLocalCurrentMemory as Function)(_lm.id)
                                                            }}>
                                                                Supprimer la mémoire
                                                            </span>
                                                            &nbsp;&nbsp;&nbsp;


                                                            {
                                                                _lm.idNote !== undefined &&
                                                                this.props.GLOBAL_DATA.linkItemMode &&
                                                                this.props.GLOBAL_DATA.selectedNoteToLink !== undefined &&
                                                                this.props.GLOBAL_DATA.selectedNoteToLink.associations !== null &&
                                                                <span className="APP_FOLDER_TABLE_BUTTON" onClick={() => {
                                                                    if (this.props.GLOBAL_DATA.selectedNoteToLink !== undefined &&
                                                                        _lm.selectedGroupNote !== undefined &&
                                                                        _lm.selectedGroupNote !== undefined &&
                                                                        _lm.idNote !== undefined) {

                                                                        if (this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                                                            this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === _lm.idNote).length > 0) {
                                                                            (this.props.GLOBAL_FUNCTION.onLinkItemToNote as Function)(
                                                                                _lm.idNote,
                                                                                this.props.GLOBAL_DATA.selectedNoteToLink
                                                                            )
                                                                        } else {
                                                                            (this.props.GLOBAL_FUNCTION.onLinkItemToNote as Function)(
                                                                                _lm.idNote,
                                                                                this.props.GLOBAL_DATA.selectedNoteToLink,
                                                                                _lm.selectedGroupNote.id
                                                                            )
                                                                        }



                                                                    }

                                                                }}>
                                                                    {
                                                                        this.props.GLOBAL_DATA.selectedNoteToLink.associations.split(";").filter(a => a.length > 0 && a !== "NaN" && _lm.idNote === parseInt(a)).length > 0 ? "Délier la note" : "Lier la note"
                                                                    }
                                                                </span>
                                                            }




                                                        </div>
                                                    })
                                                }
                                            </span>
                                        </div>
                                    })
                            }
                        </div>
                    }

                    {
                        this.state.selectedMenu !== undefined &&
                        this.state.selectedMenu.key === "7" &&
                        <div >
                            <div style={{ textAlign: "right", marginBottom: "14px", position: "relative", display: "block" }}>
                                {
                                    <SEARCHBOX ref={this.Ref_GloablSearch} placeHolder="Saisissez un mot clé" F_UPDATE_SEARCHVALUE={() => {
                                        (this.props.GLOBAL_FUNCTION.onShowLoader as Function)(false);
                                    }} forceUpdateEveryTime={true} APPFUNCTION={this.props.GLOBAL_FUNCTION} />
                                }
                            </div>
                            <div style={{ textAlign: "center" }}>
                                {
                                    this.props.GLOBAL_DATA.DB_GROUPS !== undefined &&
                                    this.props.GLOBAL_DATA.DB_GROUPS
                                        .filter(g =>
                                            this.props.GLOBAL_DATA.selectedGroup !== undefined && g.id !== this.props.GLOBAL_DATA.selectedGroup.id &&
                                            (this.Ref_GloablSearch.current !== null && this.Ref_GloablSearch.current.state.searchValue.length > 0 ?
                                                g.title.toUpperCase().indexOf(this.Ref_GloablSearch.current.state.searchValue.toUpperCase()) > -1 : true)
                                        )
                                        .sort((a, b) => { return a.title.localeCompare(b.title) })
                                        .map(g => {
                                            return <GROUP_BOX_ITEM key={g.id} forceSelected={false} GLOBAL_DATA={this.props.GLOBAL_DATA} GLOBAL_FUNCTION={this.props.GLOBAL_FUNCTION} groupItem={g} />
                                        })
                                }
                            </div>
                        </div>
                    }



                    {
                        this.state.selectedMenu !== undefined &&
                        this.state.selectedMenu.key === "6" &&
                        this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                        <div>
                            <div style={{ textAlign: "right", marginBottom: "14px", position: "relative", display: "block" }}>
                                {
                                    <SEARCHBOX ref={this.Ref_GloablSearch} placeHolder="Saisissez un mot clé" F_UPDATE_SEARCHVALUE={() => {
                                        (this.props.GLOBAL_FUNCTION.onShowLoader as Function)(false);
                                    }} forceUpdateEveryTime={true} APPFUNCTION={this.props.GLOBAL_FUNCTION} />
                                }
                            </div>

                            {
                                this.props.GLOBAL_DATA.navigation.filter(nav => nav.isNoteOpened &&
                                    this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                    this.props.GLOBAL_DATA.DB_NOTES.filter(n => nav.noteId === n.idnote).length > 0
                                )
                                    .map(nav => {
                                        return <div key={nav.noteId} style={{ position: "relative", display: "block", paddingLeft: "54px" }}>
                                            {
                                                this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                                <SHORT_NOTE_ITEM showNoteShortDate={true} showOnly={true} asChild={true} maxLevel={0} GLOBAL_DATA={this.props.GLOBAL_DATA} GLOBAL_FUNCTION={this.props.GLOBAL_FUNCTION} note={this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === nav.noteId)[0]} />
                                            }
                                        </div>
                                    })
                            }
                            {/* {
                            GetDistinctListOfString(
                                this.props.GLOBAL_DATA.openedNote
                                    .filter(_n => this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                        this.props.GLOBAL_DATA.DB_NOTES.filter(__n =>
                                            this.Ref_GloablSearch.current !== null &&
                                            (this.Ref_GloablSearch.current.state.searchValue.length > 0 ?
                                                __n.idnote === _n && __n.title.toUpperCase().indexOf(this.Ref_GloablSearch.current.state.searchValue.toUpperCase()) > -1 : true)
                                        ).length > 0)
                                    .map(_n => { return _n.toString() })
                            )
                                .map(on => {
                                    return <div key={on} style={{ position: "relative", display: "block", paddingLeft: "54px" }}>
                                        {
                                            this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                            <SHORT_NOTE_ITEM showNoteShortDate={true} showOnly={true} asChild={true} maxLevel={0} GLOBAL_DATA={this.props.GLOBAL_DATA} GLOBAL_FUNCTION={this.props.GLOBAL_FUNCTION} note={this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === parseInt(on))[0]} />
                                        }
                                    </div>
                                })
                        } */}
                        </div>
                    }



                    {
                        this.state.selectedMenu !== undefined &&
                        this.state.selectedMenu.key === "2" &&
                        this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                        this.props.GLOBAL_DATA.DB_TASKS !== undefined &&
                        this.props.GLOBAL_DATA.DB_TASKS.filter(t => !t.finished).length > 0 &&
                        <div>

                            <div>


                            </div>
                            <div style={{ textAlign: "right", marginBottom: "14px", position: "relative", display: "block" }}>
                                {
                                    <SEARCHBOX ref={this.Ref_GloablSearch} placeHolder="Saisissez un mot clé" F_UPDATE_SEARCHVALUE={() => {
                                        (this.props.GLOBAL_FUNCTION.onShowLoader as Function)(false);
                                    }} forceUpdateEveryTime={true} APPFUNCTION={this.props.GLOBAL_FUNCTION} />
                                }
                            </div>


                            {
                                GetDistinctListOfString(
                                    this.props.GLOBAL_DATA.DB_TASKS
                                        .filter(t =>
                                            !t.finished &&
                                            this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                            this.props.GLOBAL_DATA.DB_NOTES.filter(n =>
                                                t.idnote === n.idnote &&
                                                (this.Ref_GloablSearch.current !== null && this.Ref_GloablSearch.current.state.searchValue.length > 0 ? n.title.toUpperCase().indexOf(this.Ref_GloablSearch.current.state.searchValue.toUpperCase()) > -1 : true)).length > 0
                                        )
                                        .map(t => { return t.idnote.toString() }))
                                    .sort((a, b) => { return b.localeCompare(a) })
                                    .map(nt => {
                                        return <div key={nt} style={{ position: "relative", display: "block", /*paddingLeft: "54px"*/ }}>
                                            {
                                                this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                                <SHORT_NOTE_ITEM
                                                    showNoteShortDate={true}
                                                    GLOBAL_DATA={this.props.GLOBAL_DATA}
                                                    GLOBAL_FUNCTION={this.props.GLOBAL_FUNCTION}
                                                    asChild={true}
                                                    note={this.props.GLOBAL_DATA.DB_NOTES.filter(n => n.idnote === parseInt(nt))[0]}
                                                    maxLevel={0}
                                                    showOnly={true}
                                                />
                                            }
                                        </div>
                                    })}
                        </div>

                    }

                    {
                        this.state.selectedMenu !== undefined &&
                        this.state.selectedMenu.key === "8" &&
                        <div>

                            {
                                this.props.GLOBAL_DATA._DB_COMPTA !== undefined &&
                                this.props.GLOBAL_DATA._DB_COMPTA.length === 0 &&
                                <div className='APP_WASPNOTE_CAN_T_FOUND_ANY_THING_BOX'>
                                    <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.searchResult, 128) }}></span>
                                    <div>
                                        <span>
                                            Aucun formulaire compta trouvé dans ce carnet ...
                                        </span>
                                    </div>
                                </div>
                            }

                            {
                                this.props.GLOBAL_DATA._DB_COMPTA !== undefined &&
                                this.props.GLOBAL_DATA._DB_COMPTA.length > 0 &&
                                <COMPTA_STAT GLOBAL_DATA={this.props.GLOBAL_DATA} GLOBAL_FUNCTION={this.props.GLOBAL_FUNCTION} filteredNotes={this.props.GLOBAL_DATA.DB_FILTERED_NOTES as I_NOTE_ITEM[]} />
                            }

                        </div>

                    }

                    {
                        this.state.selectedMenu !== undefined &&
                        this.state.selectedMenu.key === "9" &&
                        <div>
                            <SHARER_BOX showLight={true} APP_GLOBAL_DATA={this.props.GLOBAL_DATA} APP_GLOBAL_FUNCTION={this.props.GLOBAL_FUNCTION} />
                        </div>
                    }

                    {
                        this.state.selectedMenu !== undefined &&
                        this.state.selectedMenu.key === "10" &&
                        <div>
                            <div style={{ textAlign: "right", marginBottom: "14px", position: "relative", display: "block" }}>
                                {
                                    <SEARCHBOX ref={this.Ref_GloablSearch} placeHolder="Saisissez un mot clé" F_UPDATE_SEARCHVALUE={() => {
                                        (this.props.GLOBAL_FUNCTION.onShowLoader as Function)(false);
                                    }} forceUpdateEveryTime={true} APPFUNCTION={this.props.GLOBAL_FUNCTION} />
                                }
                            </div>

                            {
                                this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                this.props.GLOBAL_DATA.DB_NOTES
                                    .filter(n => n.pined)
                                    .filter(n => this.Ref_GloablSearch.current !== null && this.Ref_GloablSearch.current.state.searchValue.length > 0 ? n.title.toUpperCase().indexOf(this.Ref_GloablSearch.current.state.searchValue.toUpperCase()) > -1 : true)
                                    .slice(0, 30)
                                    .map(pn => {
                                        return <SHORT_NOTE_ITEM showOnly={true} GLOBAL_DATA={this.props.GLOBAL_DATA} GLOBAL_FUNCTION={this.props.GLOBAL_FUNCTION} note={pn} asChild={false} maxLevel={0} showNoteShortDate={true} />
                                    })
                            }
                        </div>
                    }

                    {
                        this.state.selectedMenu !== undefined &&
                        this.state.selectedMenu.key === "11" &&
                        <div>
                            <div style={{ textAlign: "right", marginBottom: "14px", position: "relative", display: "block" }}>
                                {
                                    <SEARCHBOX ref={this.Ref_GloablSearch} placeHolder="Saisissez un mot clé" F_UPDATE_SEARCHVALUE={() => {
                                        (this.props.GLOBAL_FUNCTION.onShowLoader as Function)(false);
                                    }} forceUpdateEveryTime={true} APPFUNCTION={this.props.GLOBAL_FUNCTION} />
                                }
                            </div>
                            <div style={{ textAlign: "center" }}>
                                <div className="APP_FOLDER_TABLE_BUTTON" onClick={() => {

                                    let msgOption: I_MSG_OPTION = {
                                        allMSG: [
                                            {
                                                key: "Dévrouillage des notes ...",
                                                value: "Voulez-vous réellement supprimer les notes vérouillées ?",
                                                data: ENU_ICON_TYPE.lock
                                            }
                                        ],
                                        showCloseButton: true,
                                        buttons: [
                                            {
                                                title: "Dévérouiller",
                                                F_BUTTON_ACTION: () => {
                                                    this.props.GLOBAL_DATA.allLockedNotes.forEach((ln) => {
                                                        (this.props.GLOBAL_FUNCTION.onDeleteNoteLock as Function)(ln.noteId);
                                                    });

                                                    (this.props.GLOBAL_FUNCTION.closePopup as Function)();
                                                }
                                            }
                                        ]
                                    };

                                    (this.props.GLOBAL_FUNCTION.showMessage as Function)(msgOption);
                                }}>
                                    Tout dévérouiller
                                </div>
                            </div>
                            {
                                this.props.GLOBAL_DATA.DB_NOTES !== undefined &&
                                this.props.GLOBAL_DATA.DB_NOTES.filter(n =>
                                    (
                                        this.Ref_GloablSearch.current !== null &&
                                            this.Ref_GloablSearch.current.state.searchValue.length > 0 ?
                                            n.title.toUpperCase().indexOf(this.Ref_GloablSearch.current.state.searchValue.toUpperCase()) > -1
                                            : true
                                    )
                                    &&
                                    this.props.GLOBAL_DATA.allLockedNotes.filter(notif => notif.locker &&
                                        (
                                            notif.noteId === n.idnote
                                        )
                                    ).length > 0
                                ).map(n => {
                                    return <SHORT_NOTE_ITEM note={n} maxLevel={0} GLOBAL_DATA={this.props.GLOBAL_DATA} GLOBAL_FUNCTION={this.props.GLOBAL_FUNCTION} asChild={false} showOnly={true} showNoteShortDate={false} />
                                })

                            }
                        </div>
                    }
                </div >
            </div >
        </div>
    }
}