import * as React from "react";
import { ENU_ICON_TYPE, I_APP_GLOBAL_DATA, I_KEY_VALUE_DATA, I_RESULT, KeyValuePair } from "../../02_Interfaces/IGLOBAL";
import { APP_BUTTON } from "../Components/BUTTON";

import "../../99_Styles/MSGBOX.css";
import { I_MSGBOX_PROPS, I_MSGBOX_PROPS_STATE, I_MSG_OPTION } from "../../02_Interfaces/IMSGBOX";
import { CupBoardCode, encrypt, getCustFormNoteHTML, GetIconFromType, GetNoteCustFormData, GetNoteCustParentIdFromTitle, IsComptaForm, IsCustForm, IsTypeNoteIsAnCupBord } from "../../04_TOOLS/tools";
import { FIELD_TEXT } from "../Fields/FIELD_TEXT";
import { ConnectUser, CreateNewProfil, CreateUserProfil, DoesUserExist, GetUserProfil } from "../../04_TOOLS/DB_WS";
import { I_User } from "../../02_Interfaces/IUser";
import { I_ATTACHMENT_LINK_ITEM, I_GROUPE_NOTE_ITEM, I_NOTE_ITEM, I_TYPENOTE } from "../../02_Interfaces/INOTE";
import { FIELD_RETYPE_PASS } from "../Fields/FIELD_RETYPE_PASS";
import { SEARCHBOX } from "../Components/SEARCHBOX";
import { ENU_IMG } from "../../01_APPDATA/DB_IMG";
import { HELP_SHOWER } from "../Components/HELP_SHOWER";
import { DB_HELP } from "../../01_APPDATA/DB_HELP";
import { FOREST } from "../Components/FOREST_ANIMATION";
import { NOTE_CUST_FORM_EMPTY_PARENT, NOTE_CUST_FORM_EMPTY_TITLE } from "../../01_APPDATA/DB_APP";
import { CUST_FORM_BOX } from "../Components/CUST_FORM";
import { COMPTA_FORM } from "../Components/COMPTA_FORM";

export class MSG_BOX extends React.Component<I_MSGBOX_PROPS, I_MSGBOX_PROPS_STATE> {
    constructor(props: I_MSGBOX_PROPS) {
        super(props);

        this.state = {
            currentPage: 0,
            fileMaxSizeValue: 750000,
            formIsOk: true,
            uploadedFiles: [],
            keyValuePair: [],
            opacity: 0,
            createAccountMode: false,
            selectedTypeNote: undefined,
            searchOnTypeNote: "",
            selectedCupBoard: [],

        }

        //Binding
        //---------------------
        this.onNextMessage = this.onNextMessage.bind(this);
        this.onBackToMessage = this.onBackToMessage.bind(this);
        this.onSelectedPage = this.onSelectedPage.bind(this);
        this.onCheckAuthenForm = this.onCheckAuthenForm.bind(this);
        this.onUploadFile = this.onUploadFile.bind(this);
        this.updateOpacity = this.updateOpacity.bind(this);
        this.onCheckTypeNote = this.onCheckTypeNote.bind(this);
        this.onSelectedAttachment = this.onSelectedAttachment.bind(this);
        this.onCreateNewAccountMode = this.onCreateNewAccountMode.bind(this);
        this.onCreateUser = this.onCreateUser.bind(this);
        this.onSearchTypeNote = this.onSearchTypeNote.bind(this);
        this.onSelectCupBoard = this.onSelectCupBoard.bind(this);
        //---------------------
    }

    //Référence
    //=========
    private ref_Login = React.createRef<FIELD_TEXT>();
    private ref_PW = React.createRef<FIELD_TEXT>();
    private ref_RPW = React.createRef<FIELD_RETYPE_PASS>();
    private ref_SearchType = React.createRef<SEARCHBOX>();
    private ref_SelectForm = React.createRef<HTMLSelectElement>();
    private ref_PreviewCustForm = React.createRef<CUST_FORM_BOX>();

    //Méthodes et fonctions utiles
    //============================

    onSelectCupBoard(noteId: number) {
        if (this.state.selectedCupBoard.filter(cb => cb === noteId).length > 0) {
            this.setState({ selectedCupBoard: this.state.selectedCupBoard.filter(cb => cb !== noteId) }, () => {
                //-->
            });
        } else {
            this.setState({ selectedCupBoard: this.state.selectedCupBoard.concat([noteId]) })
        }
    }


    onSearchTypeNote() {
        if (this.ref_SearchType.current !== null) {
            this.setState({ searchOnTypeNote: this.ref_SearchType.current.state.searchValue });
        }
    }

    onCreateNewAccountMode() {
        this.setState({ createAccountMode: !this.state.createAccountMode });
    }

    onCreateUser() {
        if (this.ref_Login.current !== null && this.ref_RPW.current !== null) {
            this.ref_Login.current.onCheckFieldStatus();
            this.ref_RPW.current.onValidateForm();
            if (this.ref_Login.current.state.fieldIsOk && this.ref_RPW.current.state.fieldIsOk) {
                if (this.ref_RPW.current.getFinalPW() !== undefined)
                    CreateNewProfil(this.ref_Login.current.state.value, encrypt(this.ref_RPW.current.getFinalPW() as string), (data: I_RESULT) => {
                        if (data.status) {

                            //Information sur la création du compte
                            let tmpMSG: I_MSG_OPTION = {
                                allMSG: [
                                    {
                                        key: "Création du compte",
                                        value: "Votre compte à bien été créer. Connectez-vous pour conternuer.",
                                        data: ENU_ICON_TYPE.user
                                    }
                                ],
                                buttons: [
                                    {
                                        title: "Me connecter",
                                        F_BUTTON_ACTION: () => {
                                            window.location.href = window.location.href;
                                        }
                                    }
                                ],
                                showCloseButton: false
                            };
                            (this.props.APP_GLOBAL_FUNCTION.showMessage as Function)(tmpMSG);

                            //-------------------
                        } else {
                            let tmpMSG: I_MSG_OPTION = {
                                allMSG: [
                                    {
                                        key: "Création du compte",
                                        value: "Impossible de créer le compte. Consulter les logs.",
                                        data: ENU_ICON_TYPE.user
                                    }
                                ],
                                buttons: [
                                    {
                                        title: "Recommencer",
                                        F_BUTTON_ACTION: () => {
                                            window.location.href = window.location.href;
                                        }
                                    }
                                ],
                                showCloseButton: false
                            };

                            (this.props.APP_GLOBAL_FUNCTION.showMessage as Function)(tmpMSG);
                        }
                    })
            }
        } else {
            //-->
        }
    }

    onSelectedAttachment(idAttachment: number) {
        this.setState({ selectedFile: idAttachment, keyValuePair: [] }, () => {
            //Ajustement de la sélection de la page
            let tmpAttachement: I_ATTACHMENT_LINK_ITEM[] = ((this.props.msgOption.postedData)[0] as I_ATTACHMENT_LINK_ITEM[]);
            for (let x: number = 0; x < tmpAttachement.length; x++) {
                if (tmpAttachement[x].id === idAttachment) {
                    this.setState({ currentPage: x });
                }
            }
        });
    }


    onCheckTypeNote(idType: number) {
        this.setState({ selectedTypeNote: idType });
    }

    updateOpacity() {
        setTimeout(() => {
            this.setState({ opacity: 1 })
        }, 300);
    }

    onUploadFile(e: React.FormEvent) {
        let tmpFile: FileList = (e.target as HTMLInputElement).files as FileList;
        // console.log(tmpFile);

        if (this.props.msgOption.postedData !== undefined) {
            //Vérification de la taille des fichiers sélectionnés
            var canUpload: boolean = true;
            let filesCanNotUpload: I_KEY_VALUE_DATA[] = [];
            for (let x = 0; x < tmpFile.length; x++) {
                const file = tmpFile[x];
                if (parseInt(file.size.toString()) > this.state.fileMaxSizeValue) {
                    filesCanNotUpload.push(
                        {
                            key: file.name,
                            value: file.size,
                            data: ENU_ICON_TYPE.close
                        }
                    );
                    canUpload = false;
                } else {
                    filesCanNotUpload.push(
                        {
                            key: file.name,
                            value: file.size,
                            data: ENU_ICON_TYPE.validate
                        }
                    );
                }
            }

            this.setState({ keyValuePair: filesCanNotUpload });//Mise à jour de la liste de fichiers
            //---------------------------------------------------
            if (!canUpload) {
                //-->
            } else {
                for (let x = 0; x < tmpFile.length; x++) {

                    let file = tmpFile[x];
                    let reader = new FileReader();
                    reader.onload = (e) => {
                        let file = (e.target as FileReader).result;

                        let tmpFiles: I_ATTACHMENT_LINK_ITEM[] = this.state.uploadedFiles as I_ATTACHMENT_LINK_ITEM[];
                        tmpFiles.push({
                            id: -1,
                            nodeId: (this.props.msgOption.postedData as I_NOTE_ITEM).idnote,
                            title: tmpFile[x].name,
                            body: file
                        } as I_ATTACHMENT_LINK_ITEM);
                        this.setState({
                            uploadedFiles: tmpFiles
                        }, () => {

                        });
                    };
                    reader.readAsDataURL(file);
                }


            }
        }
    }


    onCheckAuthenForm() {
        if (this.ref_Login.current !== null && this.ref_PW.current !== null) {
            let loginIsOK: FIELD_TEXT = this.ref_Login.current;
            let pwIsOK: FIELD_TEXT = this.ref_PW.current;

            //Forcer le contrôle des composants
            this.ref_Login.current.forceUpdate();
            this.ref_PW.current.forceUpdate();
            //------------------

            if (loginIsOK.state.fieldIsOk && pwIsOK.state.fieldIsOk) {
                //Vérification du login
                DoesUserExist(this.ref_Login.current.state.value, (data: I_RESULT) => {
                    if (data.status) {
                        //Authentification de l'utilisateur
                        ConnectUser(loginIsOK.state.value, encrypt(pwIsOK.state.value), (data: I_RESULT) => {
                            if (data.status && (data.data as I_User).login !== "") {
                                (this.props.APP_GLOBAL_FUNCTION.authentUser as Function)(data.data as I_User);
                            } else {
                                (this.props.APP_GLOBAL_FUNCTION.showMessage as Function)({
                                    allMSG: [{
                                        key: "Connection ...",
                                        value: data.message as string,
                                        data: ENU_ICON_TYPE.error
                                    }] as I_KEY_VALUE_DATA[],
                                    buttons: [
                                        {
                                            title: "Je réessais !",
                                            F_BUTTON_ACTION: () => {
                                                window.location.href = window.location.href;
                                            }
                                        }
                                    ],
                                    showCloseButton: false,
                                } as I_MSG_OPTION)
                            }
                        })
                    } else {
                        // Utilisateur inconnu
                        (this.props.APP_GLOBAL_FUNCTION.showMessage as Function)({
                            allMSG: [{
                                key: "Connection ...",
                                value: "Impossible de trouver le compte utilisateur, spécifié .... merci de réessayer.",
                                data: ENU_ICON_TYPE.error
                            }] as I_KEY_VALUE_DATA[],
                            buttons: [
                                {
                                    title: "C'est compris !",
                                    F_BUTTON_ACTION: () => {
                                        window.location.href = window.location.href;
                                    }
                                }
                            ],
                            showCloseButton: false,
                        } as I_MSG_OPTION)
                    }
                });
            }
        }
    }

    onNextMessage() {
        if (this.props.msgOption.allMSG.length > this.state.currentPage + 1) {
            this.setState({ currentPage: this.state.currentPage + 1 }, () => {
                //selection de la pièce jointe
                if (this.props.msgOption.asAttachmentViewer !== undefined) {
                    (this.props.msgOption.postedData as I_ATTACHMENT_LINK_ITEM[]).forEach((a, index) => {
                        if (index === this.state.currentPage) {
                            this.setState({ selectedFile: a.id })
                        }
                    });
                }
            })
        }
    }

    onBackToMessage() {
        if (this.state.currentPage > 0) {
            this.setState({ currentPage: this.state.currentPage - 1 }, () => {
                //selection de la pièce jointe
                if (this.props.msgOption.asAttachmentViewer !== undefined) {
                    (this.props.msgOption.postedData as I_ATTACHMENT_LINK_ITEM[]).forEach((a, index) => {
                        if (index === this.state.currentPage) {
                            this.setState({ selectedFile: a.id })
                        }
                    });
                }
            })
        }
    }

    onSelectedPage(pageIndex: number) {
        this.setState({ currentPage: pageIndex }, () => {
            //selection de la pièce jointe
            if (this.props.msgOption.asAttachmentViewer !== undefined) {
                (this.props.msgOption.postedData as I_ATTACHMENT_LINK_ITEM[]).forEach((a, index) => {
                    if (index === pageIndex) {
                        this.setState({ selectedFile: a.id });
                    }
                });
            }
        });
    }

    componentDidMount() {


        this.updateOpacity();

        //Sélection par défaut dans le cas de visualisation des pièces jointes
        if (this.props.msgOption.asAttachmentViewer !== undefined) {
            //Recherche de l'indexe courant
            let attachmentIndex: number = 0;
            for (let x = 0; x < (this.props.msgOption.postedData[0] as I_ATTACHMENT_LINK_ITEM[]).length; x++) {

                if ((this.props.msgOption.postedData[0] as I_ATTACHMENT_LINK_ITEM[])[x].id === (this.props.msgOption.postedData[1] as number)) {
                    this.setState({
                        selectedFile: this.props.msgOption.postedData[1] as number,
                        currentPage: x
                    }, () => {
                        this.onSelectedAttachment((this.props.msgOption.postedData[0] as I_ATTACHMENT_LINK_ITEM[])[x].id);
                    });
                }
            }
            //------------------------------
        }
    }
    //============================

    render(): JSX.Element {
        return <div className="MSGBOX_BOX" style={{ opacity: this.state.opacity, }}>
            {/* Layer de fond */}
            <div className="MSGBOX_LAYER_BOX" />

            {
                this.props.APP_GLOBAL_DATA.connectedUser === undefined &&
                <FOREST />
            }


            {/* Popup */}
            <div className="MSGBOX_POPUP_BOX" style={{
                bottom:
                    (this.props.msgOption.asAttachmentViewer === undefined && this.props.msgOption.asAuthenForm === undefined && this.props.msgOption.asTypeNoteSelector === undefined && this.props.msgOption.asUploadAttachment === undefined) ?
                        ((this.props.msgOption.postedData !== undefined && ENU_IMG[this.props.msgOption.postedData] !== undefined) ? "14%" :
                            (this.props.msgOption.postedData !== undefined && this.props.msgOption.asNoteChildAssociation !== undefined && this.props.msgOption.asNoteChildAssociation) ?
                                "22%" :
                                (
                                    (this.props.msgOption.postedData as I_NOTE_ITEM) !== undefined ? "20%" :
                                        "54%"
                                )
                        ) :
                        (this.props.msgOption.asAuthenForm !== undefined ? (this.state.createAccountMode ? "40%" : "44%") : "20%"),
                left:
                    (
                        (
                            this.props.APP_GLOBAL_DATA.selectedFormToGenerate !== undefined &&
                            this.props.APP_GLOBAL_DATA.selectedFormToGenerate <= 0) ||
                        this.props.APP_GLOBAL_DATA.selectedFormToGenerate === undefined
                    ) ?
                        ((this.props.msgOption.asAttachmentViewer !== undefined) ? "20%" : "30%") : "10%",

                right: (
                    (
                        this.props.APP_GLOBAL_DATA.selectedFormToGenerate !== undefined &&
                        this.props.APP_GLOBAL_DATA.selectedFormToGenerate <= 0) ||
                    this.props.APP_GLOBAL_DATA.selectedFormToGenerate === undefined
                ) ? (this.props.msgOption.asAttachmentViewer !== undefined) ? "20%" : "30%" : "42%",
                top: (this.props.msgOption.asAttachmentViewer !== undefined) ? "5%" : "15%"
            }} >
                {/* Boutons de fermeture */}
                {
                    this.props.msgOption.showCloseButton &&
                    <div onClick={() => (this.props.msgOption.F_ClosePopup as Function)()} className="MSGBOX_POPUP_CLOSE_BUTTON" dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.close, 18, "D22828") }}>
                    </div>
                }


                {/* Titre */}
                <div className="MSGBOX_POPUP_TITLE">

                    {
                        // Affichage d'une icone pour le message
                        <div dangerouslySetInnerHTML={{ __html: GetIconFromType(this.props.msgOption.allMSG[this.state.currentPage].data, 48) }} />
                    }

                    {
                        //Affichage du message en fonction de la page sélectionnée
                        <div style={{ top: "-20px", maxWidth: "80%" }} dangerouslySetInnerHTML={{
                            __html:
                                IsCustForm(this.props.msgOption.allMSG[this.state.currentPage].key) ?
                                    this.props.msgOption.allMSG[this.state.currentPage].key.split("]-")[1] :
                                    this.props.msgOption.allMSG[this.state.currentPage].key
                        }}>
                        </div>
                        //--------------------------------------------------------

                    }
                </div>

                {/* Gestion des pièces jointes */}
                {
                    (this.props.msgOption.asAttachmentViewer !== undefined && this.props.msgOption.asAttachmentViewer) &&
                    <div className="MSGBOX_ATTACHMENTS_ZONE">
                        {
                            ((this.props.msgOption.postedData)[0] as I_ATTACHMENT_LINK_ITEM[]).map(a => {
                                return <span key={a.id} className={(a.id === (this.state.selectedFile as number)) ? "MSGBOX_ATTACHMENTS_ITEM MSGBOX_ATTACHMENTS_ITEM_SELECTED" : "MSGBOX_ATTACHMENTS_ITEM"} onClick={() => this.onSelectedAttachment(a.id)}>{a.title}</span>
                            })
                        }
                    </div>
                }

                {/* Zone de recherche si sélection d'un type de notes */}
                {
                    (this.props.msgOption.postedData !== undefined && this.props.msgOption.asTypeNoteSelector !== undefined && this.props.msgOption.asTypeNoteSelector) &&
                    <div style={{ width: "100%", textAlign: "center" }}>
                        <SEARCHBOX APPFUNCTION={this.props.APP_GLOBAL_FUNCTION} ref={this.ref_SearchType} placeHolder="Saisissez un mot clé..." forceUpdateEveryTime={true}
                            F_UPDATE_SEARCHVALUE={this.onSearchTypeNote} />
                    </div>
                }
                {
                    //Affichage du Helper si demandé
                    (this.props.msgOption.postedData !== undefined && this.props.msgOption.asNoteChildAssociation !== undefined && this.props.msgOption.asNoteChildAssociation) &&
                    <div style={{ width: "100%", textAlign: "center" }}>
                        <SEARCHBOX APPFUNCTION={this.props.APP_GLOBAL_FUNCTION} ref={this.ref_SearchType} placeHolder="Saisissez un mot clé..." forceUpdateEveryTime={true}
                            F_UPDATE_SEARCHVALUE={() => {
                                (this.props.APP_GLOBAL_FUNCTION.onShowLoader as Function)(false);
                            }} />
                    </div>
                    //------------------------------
                }



                {/* contenu de la popup */}
                <div className="MSGBOX_POPUP_CONTAIN" style={{
                    overflow: this.props.msgOption.asAttachmentViewer !== undefined ? "hidden" : "auto",
                    top: (this.props.msgOption.postedData !== undefined && this.props.msgOption.asTypeNoteSelector !== undefined && this.props.msgOption.asTypeNoteSelector) ? "97px" : undefined
                }}>
                    {
                        //Affichage du message en fonction de la page sélectionnée
                        (this.props.msgOption.asAttachmentViewer === undefined) &&
                        !IsComptaForm((this.props.msgOption.allMSG[this.state.currentPage].value as I_NOTE_ITEM).description) &&
                        !IsCustForm(this.props.msgOption.allMSG[this.state.currentPage].key) &&
                        <div dangerouslySetInnerHTML={{ __html: this.props.msgOption.allMSG[this.state.currentPage].value }}>
                        </div>
                        //-------------------------------------------------------
                    }

                    {
                        //Affichage du message en fonction de la page sélectionnée
                        (this.props.msgOption.asAttachmentViewer === undefined) &&
                        IsCustForm(this.props.msgOption.allMSG[this.state.currentPage].key) &&
                        this.props.APP_GLOBAL_DATA.DB_NOTES !== undefined &&
                        <div>
                            <CUST_FORM_BOX note={this.props.msgOption.allMSG[this.state.currentPage].value as I_NOTE_ITEM}
                                GLOBAL_DATA={this.props.APP_GLOBAL_DATA}
                                GLOBAL_FUNCTION={this.props.APP_GLOBAL_FUNCTION} editMode={false} canEditInLive={false} />
                        </div>
                        //-------------------------------------------------------
                    }

                    {
                        //Affichage du message en fonction de la page sélectionnée
                        (this.props.msgOption.asAttachmentViewer === undefined) &&
                        IsComptaForm((this.props.msgOption.allMSG[this.state.currentPage].value as I_NOTE_ITEM).description) &&
                        this.props.APP_GLOBAL_DATA.DB_NOTES !== undefined &&
                        <div>
                            <COMPTA_FORM note={this.props.msgOption.allMSG[this.state.currentPage].value as I_NOTE_ITEM}
                                APP_DATA={this.props.APP_GLOBAL_DATA}
                                APP_FUNCTION={this.props.APP_GLOBAL_FUNCTION} editMode={false} showTotalOnly={false} />
                        </div>
                        //-------------------------------------------------------
                    }

                    {
                        //Affichage du Helper si demandé
                        (this.props.msgOption.postedData !== undefined && ENU_IMG[this.props.msgOption.postedData] !== undefined) &&
                        <HELP_SHOWER IMG={this.props.msgOption.postedData as ENU_IMG} />
                        //------------------------------
                    }

                    {
                        (this.props.msgOption.asAttachmentViewer !== undefined && this.props.msgOption.asAttachmentViewer) &&
                        <iframe title={"..."} style={{ width: "100%", height: "100%" }} src={this.props.msgOption.allMSG[this.state.currentPage].value} />
                    }

                    {/* Gestion de la connexion */}
                    {
                        (this.props.msgOption.asAuthenForm !== undefined && this.props.msgOption.asAuthenForm && !this.state.createAccountMode) &&
                        <form>
                            <div className="MSGBOX_PW_ZONE">
                                <FIELD_TEXT
                                    title={"Login"}
                                    value={""}
                                    placeholder={"Saisissez votre login"}
                                    required={true}
                                    ref={this.ref_Login}
                                />

                                <FIELD_TEXT
                                    title={"Mot de passe"}
                                    value={""}
                                    placeholder={"Saisissez vote mot de passe"}
                                    required={true}
                                    asPassword={true}
                                    ref={this.ref_PW}
                                    keyAction={
                                        {
                                            keyboardValue: "13",
                                            F_Action: () => {
                                                //Controle des champs
                                                this.onCheckAuthenForm();
                                                //------------------
                                            }
                                        }
                                    }
                                    minLength={6}
                                    maxLength={12}
                                />
                            </div>
                        </form>
                    }

                    {/* Gestion de la création d'un nouveau compte */}
                    {
                        (this.props.msgOption.asAuthenForm !== undefined && this.props.msgOption.asAuthenForm && this.state.createAccountMode) &&
                        <form>
                            <div className="MSGBOX_PW_ZONE">
                                <FIELD_TEXT
                                    title={"Login"}
                                    value={""}
                                    placeholder={"Nouveau login"}
                                    required={true}
                                    ref={this.ref_Login}
                                    minLength={8}
                                    maxLength={12}
                                />

                                <FIELD_RETYPE_PASS
                                    ref={this.ref_RPW}
                                    title="Mot de passe"
                                    APP_GLOBAL_DATA={this.props.APP_GLOBAL_DATA}
                                    APP_GLOBAL_FUNCTION={this.props.APP_GLOBAL_FUNCTION} />
                            </div>
                        </form>
                    }


                    {/* Gestion des pièces jointes */}
                    {
                        (this.props.msgOption.asUploadAttachment !== undefined && this.props.msgOption.asUploadAttachment) &&
                        < form >
                            <div>
                                <span>
                                    <input type={"file"} multiple={true} onChange={(e) => { this.onUploadFile(e) }} />
                                </span>
                            </div>

                            {
                                this.state.keyValuePair.length > 0 && this.state.keyValuePair.filter(kvp => (kvp.data as ENU_ICON_TYPE) === ENU_ICON_TYPE.close) &&
                                <div>
                                    <b>Attention:</b> certains fichier sélectionné dépassent la taille autorisée ({this.state.fileMaxSizeValue / 1000000}Mb)
                                </div>
                            }

                            {/* Liste des fichiers sélectionnés */}
                            {
                                this.state.keyValuePair.length > 0 &&
                                <div className="MSGBOX_SELECTED_FILES_BOX">
                                    {
                                        this.state.keyValuePair.map((f, index) => {
                                            return <div key={index} className="MSGBOX_FILE_ITEM_BOX">
                                                <span dangerouslySetInnerHTML={{ __html: GetIconFromType(f.data as ENU_ICON_TYPE, 16, ((f.value as number) > this.state.fileMaxSizeValue ? "FF282C" : "267F00")) }}></span>
                                                <span>{f.key}</span>
                                                <span style={{ color: "#" + ((f.value as number) > this.state.fileMaxSizeValue ? "FF282C" : "267F00") }}>{(f.value as number) / 1000000} Mb</span>
                                            </div>
                                        })
                                    }
                                </div>
                            }

                        </form>
                    }

                    {/* Selection d'un type de note */}
                    {
                        (this.props.msgOption.postedData !== undefined && this.props.msgOption.asTypeNoteSelector !== undefined && this.props.msgOption.asTypeNoteSelector) &&
                        <form>
                            {
                                (
                                    (this.props.msgOption.postedData) as I_TYPENOTE[]
                                )
                                    .filter(tn =>
                                        (this.props.APP_GLOBAL_DATA.DB_GROUPS as I_GROUPE_NOTE_ITEM[]).filter(gn => gn.isAuthor === 1 && tn.idGroupNote === gn.id).length > 0 ?
                                            true :
                                            this.props.APP_GLOBAL_DATA.sharedItemsWithMe.filter(si =>
                                                (si.idType !== null && (si.idType as number) === tn.id && si.canEdit) ||
                                                (si.idType === null && si.idGroup === tn.idGroupNote && si.canEdit)
                                            ).length > 0
                                    )
                                    .filter(tn => this.state.searchOnTypeNote.length > 0 ? (tn.title.toUpperCase().indexOf(this.state.searchOnTypeNote.toUpperCase()) > -1) : true)
                                    .sort((a, b) => { return a.title.localeCompare(b.title) })
                                    .map(tn => {
                                        return <div key={tn.id} className="MSGBOX_POPUP_TYPENOTE_ITEM" onClick={() => this.onCheckTypeNote(tn.id)}
                                            style={{ backgroundColor: "#" + tn.colorString as string }}>
                                            <div style={{ backgroundColor: "#ffffff", padding: "2px", opacity: 0.8 }}>
                                                <span dangerouslySetInnerHTML={{ __html: GetIconFromType((this.state.selectedTypeNote as number) === tn.id ? ENU_ICON_TYPE.checkedTask : ENU_ICON_TYPE.unCheckedTask, 16) }}>
                                                </span>

                                                {
                                                    //Si l'intercalaire est un classeur
                                                    tn.title.split(CupBoardCode).length > 1 &&
                                                    <span>
                                                        <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.cupBoard, 14) }}></span>
                                                        <span className="SPAN_SEP"></span>
                                                    </span>
                                                }

                                                <span>{
                                                    (tn.title.split(CupBoardCode).length > 1 ? tn.title.split(CupBoardCode)[1] : tn.title)
                                                }</span>
                                            </div>
                                        </div>
                                    })
                            }
                        </form>
                    }



                    {/* Selection du classeur */}
                    {
                        (this.props.msgOption.postedData !== undefined && this.props.msgOption.asNoteChildAssociation !== undefined && this.props.msgOption.asNoteChildAssociation) &&
                        <form>
                            {
                                this.props.msgOption.postedData !== undefined &&
                                (this.props.msgOption.postedData as I_NOTE_ITEM[])
                                    .filter(n => this.ref_SearchType.current !== null &&
                                        n.title.toUpperCase().indexOf(this.ref_SearchType.current.state.searchValue.toUpperCase()) > -1)
                                    .map(n => {
                                        return <div className="CUPBOARD_LINE_ITEM" onClick={() => {
                                            this.onSelectCupBoard(n.idnote);
                                        }}>
                                            <span dangerouslySetInnerHTML={{ __html: GetIconFromType(this.state.selectedCupBoard.filter(cb => cb === n.idnote).length > 0 ? ENU_ICON_TYPE.checkedTask : ENU_ICON_TYPE.unCheckedTask, 12) }}></span>
                                            &nbsp;
                                            <span className="SPAN_SEP"></span>
                                            &nbsp;
                                            <span style={{
                                                position: "relative", display: "inline-block", padding: "1px", paddingLeft: "3px", paddingRight: "3px",
                                                border: (this.props.APP_GLOBAL_DATA.DB_TYPES_NOTES !== undefined ?
                                                    "1px solid #" + this.props.APP_GLOBAL_DATA.DB_TYPES_NOTES.filter(tn => tn.id === n.type)[0].colorString : undefined)
                                            }}>
                                                {
                                                    this.props.APP_GLOBAL_DATA.DB_TYPES_NOTES !== undefined &&
                                                    this.props.APP_GLOBAL_DATA.DB_TYPES_NOTES.filter(tn => tn.id === n.type).length > 0 &&
                                                    this.props.APP_GLOBAL_DATA.DB_TYPES_NOTES.filter(tn => tn.id === n.type)[0].title.split(CupBoardCode)[1]
                                                }
                                            </span>
                                            &nbsp;
                                            <span className="SPAN_SEP"></span>
                                            &nbsp;
                                            <span>{n.title}</span>
                                        </div>
                                    })
                            }
                        </form>
                    }

                    {/* Pagination */}
                    {
                        (
                            (this.props.msgOption.allMSG.length > 1) &&
                            this.props.msgOption.asAttachmentViewer === undefined &&
                            this.props.msgOption.asAuthenForm === undefined &&
                            this.props.msgOption.asTypeNoteSelector === undefined &&
                            this.props.msgOption.asUploadAttachment === undefined
                        ) &&
                        <div className="MSGBOX_POPUP_PAGINATION">
                            {
                                this.props.msgOption.allMSG.map((m, index) => {
                                    return <div onClick={() => this.onSelectedPage(index)}
                                        key={index} className={this.state.currentPage === index ? "MSGBOX_POPUP_PAGINATION_BUTTON MSGBOX_POPUP_PAGINATION_BUTTON_SELECTED" : "MSGBOX_POPUP_PAGINATION_BUTTON"}>
                                    </div>
                                })
                            }
                        </div>
                    }
                </div>




                {/* zone de bouton */}
                <div className="MSGBOX_POPUP_ACTIONS">
                    {
                        this.state.selectedTypeNote !== undefined &&
                        this.props.APP_GLOBAL_DATA.DB_TYPES_NOTES !== undefined &&
                        this.props.msgOption.postedData !== undefined &&
                        this.props.msgOption.asTypeNoteSelector !== undefined &&
                        this.props.msgOption.asTypeNoteSelector &&
                        this.props.msgOption.buttons.filter(b => b.title !== "Changer").length > 0 &&
                        <div style={{
                            backgroundColor:
                                this.props.APP_GLOBAL_DATA.DB_TYPES_NOTES !== undefined &&
                                    this.props.APP_GLOBAL_DATA.DB_TYPES_NOTES.filter(tn => this.state.selectedTypeNote !== undefined && this.state.selectedTypeNote === tn.id).length > 0 ?
                                    "#" + this.props.APP_GLOBAL_DATA.DB_TYPES_NOTES.filter(tn => this.state.selectedTypeNote !== undefined && this.state.selectedTypeNote === tn.id)[0].colorString as string : undefined
                        }}>
                            <div className="TEXT_TICKET">
                                {
                                    this.state.selectedTypeNote !== undefined &&
                                    this.props.APP_GLOBAL_DATA.DB_TYPES_NOTES.filter(tn => this.state.selectedTypeNote === tn.id).length > 0 &&
                                    <span>
                                        <span>{
                                            this.props.APP_GLOBAL_DATA.DB_TYPES_NOTES.filter(tn => this.state.selectedTypeNote === tn.id)[0].title
                                        }</span>
                                        <span className="SPAN_SEP">

                                        </span>

                                        {
                                            this.ref_SelectForm.current !== null &&
                                            parseInt(this.ref_SelectForm.current.value) > 0 &&
                                            <span>
                                                <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.form_parent, 11) }}></span>
                                                <span className="SPAN_SEP"></span>
                                            </span>
                                        }
                                    </span>
                                }


                                <select ref={this.ref_SelectForm} onChange={(e) => {

                                    const tmpSelectedValue: string = (e.target as HTMLSelectElement).value;
                                    (this.props.APP_GLOBAL_FUNCTION.onSelectFormToGenerate as Function)(-1);
                                    setTimeout(() => {
                                        (this.props.APP_GLOBAL_FUNCTION.onLoadNoteData as Function)(parseInt(tmpSelectedValue), true)
                                        setTimeout(async () => {
                                            (this.props.APP_GLOBAL_FUNCTION.onSelectFormToGenerate as Function)(tmpSelectedValue);
                                        }, 400);
                                    }, 100);



                                    (this.props.APP_GLOBAL_FUNCTION.onShowLoader as Function)(false);
                                    // console.log((e.target as HTMLSelectElement).value)
                                }} value={this.props.APP_GLOBAL_DATA.selectedFormToGenerate !== undefined ? this.props.APP_GLOBAL_DATA.selectedFormToGenerate : undefined}
                                    className="FIELD_INPUT_COMPONENT">
                                    <option value="0">
                                        Note simple
                                    </option>
                                    {
                                        this.props.APP_GLOBAL_DATA.DB_NOTES !== undefined &&
                                        this.props.APP_GLOBAL_DATA.DB_NOTES.filter(n => n.title.indexOf(NOTE_CUST_FORM_EMPTY_TITLE) > -1 && n.displayUnity === 1 && GetNoteCustParentIdFromTitle(n.title) === n.idnote.toString()).map(n => {
                                            return <option className="CUSTOM_TYPE_NOTE_OPTION"
                                                style={{
                                                    // borderBottom: "2px solid #ffffff",
                                                    background:
                                                        this.props.APP_GLOBAL_DATA.DB_TYPES_NOTES !== undefined &&
                                                            this.props.APP_GLOBAL_DATA.DB_TYPES_NOTES.filter(tn => n.type === tn.id).length > 0 ?
                                                            "linear-gradient(0deg, " + "#" + this.props.APP_GLOBAL_DATA.DB_TYPES_NOTES.filter(tn => n.type === tn.id)[0].colorString as string + ", transparent)" : undefined
                                                }}
                                                key={n.idnote} value={n.idnote}>
                                                {
                                                    n.title.replace(NOTE_CUST_FORM_EMPTY_TITLE, "").replace("-PARENT-", "")
                                                }
                                            </option>
                                        })
                                    }
                                </select>
                            </div>


                        </div>
                    }

                    {
                        this.props.msgOption.buttons.length > 1 &&
                        <span className="MSGBOX_POPUP_ACTION_MORE_BUTTON" dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.seeMore, 16, "EB6B16") }} ></span>
                    }

                    {/* Boutons personnalisés */}
                    {
                        this.props.msgOption.asNoteChildAssociation === undefined &&
                        this.props.msgOption.buttons
                            .map((b, index) => {
                                return <span key={index} className={index > 0 ? "MSGBOX_POPUP_BUTTON_HIDDEN" : undefined}>
                                    <APP_BUTTON buttonProps={b} />
                                </span>
                            })
                    }

                    {
                        (this.props.msgOption.asNoteChildAssociation !== undefined && this.props.msgOption.asNoteChildAssociation) &&
                        <APP_BUTTON buttonProps={{
                            title: "Ajouter au(x) classeur(s)",
                            F_BUTTON_ACTION: () => {
                                this.props.msgOption.buttons[0].F_BUTTON_ACTION(this.state.selectedCupBoard);
                            }
                        }} />
                    }

                    {/* Connexion utilisateur */}
                    {
                        (
                            this.props.msgOption.asAuthenForm !== undefined &&
                            (this.props.msgOption.asAuthenForm as boolean)
                        ) &&
                        <div>

                            {/* Gestion de la connexion */}
                            {
                                !this.state.createAccountMode &&
                                <APP_BUTTON buttonProps={{
                                    F_BUTTON_ACTION: () => {

                                        //Controle des champs
                                        this.onCheckAuthenForm();
                                        //------------------
                                    },
                                    title: "Connexion"
                                }} />
                            }
                            {/* {
                                !this.state.createAccountMode &&
                                <APP_BUTTON buttonProps={{
                                    F_BUTTON_ACTION: () => {
                                        this.onCreateNewAccountMode();
                                    },
                                    title: "Nouveau compte"
                                }} />
                            } */}
                            {/* ------------------------------------- */}

                            {/* Gestion de la création d'un nouveau compte */}
                            {
                                this.state.createAccountMode &&
                                <APP_BUTTON buttonProps={{
                                    F_BUTTON_ACTION: () => {

                                        //Controle des champs
                                        this.onCreateUser();
                                        //------------------
                                    },
                                    title: "Créer"
                                }} />
                            }
                            {
                                this.state.createAccountMode &&
                                <APP_BUTTON buttonProps={{
                                    F_BUTTON_ACTION: () => {
                                        this.onCreateNewAccountMode();
                                    },
                                    title: "Connexion"
                                }} />
                            }
                            {/* ------------------------------------- */}

                        </div>
                    }

                    {/* Avancer ou reculer dans les messages */}
                    {
                        (
                            this.props.msgOption.allMSG.length > 1 &&
                            this.state.currentPage > 0
                        ) &&
                        <APP_BUTTON buttonProps={{
                            F_BUTTON_ACTION: () => {
                                this.onBackToMessage();
                            },
                            title: "Précédent",
                        }
                        } />
                    }
                    {
                        (
                            this.props.msgOption.allMSG.length > 1 &&
                            this.props.msgOption.allMSG.length > this.state.currentPage + 1
                        ) &&
                        <APP_BUTTON buttonProps={{
                            F_BUTTON_ACTION: () => {
                                this.onNextMessage();
                            },
                            title: "Suivant",
                        }
                        } />
                    }
                    {/* =========================================> */}

                    {/* Bouton fermer par défaut */}
                    {
                        this.props.msgOption.showCloseButton &&
                        <APP_BUTTON buttonProps={{
                            F_BUTTON_ACTION: () => {
                                (this.props.msgOption.F_ClosePopup as Function)()
                            },
                            title: "Fermer",
                            hover_backgroundColor: "#D12727",
                            hover_textColor: "#ffffff"
                        }
                        } />
                    }

                </div>
            </div>

            {
                this.props.APP_GLOBAL_DATA.DB_TYPES_NOTES !== undefined &&
                this.props.msgOption.postedData !== undefined &&
                this.props.msgOption.asTypeNoteSelector !== undefined &&
                this.props.msgOption.asTypeNoteSelector &&
                this.props.msgOption.buttons.filter(b => b.title !== "Changer").length > 0 &&
                this.props.APP_GLOBAL_DATA.selectedFormToGenerate !== undefined &&
                this.props.APP_GLOBAL_DATA.selectedFormToGenerate > 0 &&
                this.ref_SelectForm.current !== null &&
                parseInt(this.ref_SelectForm.current.value) > 0 &&
                <div className="CUST_FORM_PREVIEW_BOX">
                    {
                        (this.props.APP_GLOBAL_DATA.DB_NOTES !== undefined &&
                            this.props.APP_GLOBAL_DATA.DB_NOTES.filter(n =>
                                this.ref_SelectForm.current !== null &&
                                n.idnote === parseInt(this.ref_SelectForm.current.value)
                            ).length > 0) &&
                        <div>
                            <div className="CUST_FORM_PARENT_INFORMATION_BOX">
                                <span>Formulaire parent N°</span>
                                <span>
                                    {
                                        this.props.APP_GLOBAL_DATA.DB_NOTES.filter(n =>
                                            this.ref_SelectForm.current !== null &&
                                            n.idnote === parseInt(this.ref_SelectForm.current.value)
                                        )[0].idnote
                                    }
                                </span>
                                &nbsp;
                                <span>
                                    {
                                        this.props.APP_GLOBAL_DATA.DB_NOTES.filter(n =>
                                            this.ref_SelectForm.current !== null &&
                                            n.idnote === parseInt(this.ref_SelectForm.current.value)
                                        )[0].title.split(
                                            "[" +
                                            this.props.APP_GLOBAL_DATA.DB_NOTES.filter(n =>
                                                this.ref_SelectForm.current !== null &&
                                                n.idnote === parseInt(this.ref_SelectForm.current.value)
                                            )[0].idnote +
                                            "]-"
                                        )[1]
                                    }
                                </span>
                            </div>
                            <CUST_FORM_BOX
                                ref={this.ref_PreviewCustForm}
                                note={
                                    this.props.APP_GLOBAL_DATA.DB_NOTES.filter(n =>
                                        this.ref_SelectForm.current !== null &&
                                        n.idnote === parseInt(this.ref_SelectForm.current.value)
                                    )[0]
                                }
                                editMode={false}
                                canEditInLive={false}
                                GLOBAL_DATA={this.props.APP_GLOBAL_DATA}
                                GLOBAL_FUNCTION={this.props.APP_GLOBAL_FUNCTION}
                            />
                        </div>
                    }
                </div>
            }


        </div >
    }
}