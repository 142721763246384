import * as React from "react";
import { ENU_ICON_TYPE, I_NOTIF, I_RESULT } from "../../02_Interfaces/IGLOBAL";
import { I_HOME_STATE_PROPS, I_HOME_STATE_PROPS_STATE } from "../../02_Interfaces/IHOMESTATE";
import { I_COMMENT_NOTE_ITEM, I_GROUPE_NOTE_ITEM, I_NOTE_ITEM, I_TASK_ITEM } from "../../02_Interfaces/INOTE";
import { I_User } from "../../02_Interfaces/IUser";
import { GetAllTask, GetComentById, GetComentsOfNote, GetNote } from "../../04_TOOLS/DB_WS";
import { GetDistinctListOfString, GetIconFromType } from "../../04_TOOLS/tools";
import { LOADER_BASIC } from "./LOADER_BASIC";
import { VERSION_CHECKER } from "./VERSION_CHECKER";

export class HOME_STATS extends React.Component<I_HOME_STATE_PROPS, I_HOME_STATE_PROPS_STATE> {

    constructor(props: I_HOME_STATE_PROPS) {
        super(props);

        this.state = {
            stateNotes: [],
            stateComments: [],
            stateTasks: [],
            notifCount: 0,
            selectedGroupNoteTask: [],
            selectedGroupNoteNotif: []
        }

        this.getAllStateNotes = this.getAllStateNotes.bind(this);
        this.getAllStateComments = this.getAllStateComments.bind(this);
        this.computeNotifCount = this.computeNotifCount.bind(this);
        this.getTaskForGroup = this.getTaskForGroup.bind(this);
        this.onSelectGroupNote = this.onSelectGroupNote.bind(this);
        this.loadingDataApp = this.loadingDataApp.bind(this);
    }

    onSelectGroupNote = (idGroup: number) => {
        if (this.state.selectedGroupNoteTask.filter(g => g === idGroup).length === 0) {
            this.setState({ selectedGroupNoteTask: this.state.selectedGroupNoteTask.concat([idGroup]) });
        } else {
            this.setState({ selectedGroupNoteTask: this.state.selectedGroupNoteTask.filter(g => g !== idGroup) })
        }
    }

    getTaskForGroup =  () => {
        if (this.props.APP_DATA.DB_GROUPS !== undefined) {
            setTimeout( async () => {
                (this.props.APP_DATA.DB_GROUPS as I_GROUPE_NOTE_ITEM[])
                    .filter(g => g.isAuthor === 1)
                    .forEach(g => {
                        GetAllTask(g.id, (data: I_RESULT) => {
                            if (data.status) {
                                const tmpTaskOfGroup: I_TASK_ITEM[] = data.data as I_TASK_ITEM[];
                                (tmpTaskOfGroup).forEach(_t => {
                                    if (!_t.finished && _t.taskdate.length > 0 && this.state.stateTasks.filter(t => t.idtask === _t.idtask).length === 0) {
                                        this.setState({ stateTasks: this.state.stateTasks.concat([_t]) });
                                    }
                                });

                                (this.props.APP_FUNCTION.onShowLoader as Function)(false);//Force la mise à jour du state global
                            } else {
                                //-->
                            }
                        })
                    });
            }, 3000);
        }

    }

    computeNotifCount =  () => {
        if (this.props.APP_DATA.allNotifs !== undefined) {
            const allNotif: I_NOTIF[] = (this.props.APP_DATA.allNotifs as I_NOTIF[]);
            let nbEffectiveNoteNotif: number = this.state.stateNotes.filter(sn => sn !== undefined).length;//allNotif.filter(notif => !notif.readed).length;
            let nbEffectiveComents: number = allNotif.filter(notif =>
                notif.readed
                && allNotif.filter(_notif =>
                    _notif.noteId === notif.noteId &&
                    _notif.readed && this.state.stateComments.filter(c => c.idNote === notif.noteId).length > 0).length === 0
            ).length

            this.setState({ notifCount: nbEffectiveNoteNotif + nbEffectiveComents }, () => {
                // this.getAllStateComments();
            });
        }
    }

    getAllStateNotes =  () => {
        return;
        (this.props.APP_DATA.allNotifs).filter(notif => !notif.readed && this.state.stateNotes.filter(sn => sn !== undefined && sn.idnote === notif.noteId).length === 0).forEach(notif => {
            
            setTimeout(async ()=>{
                GetNote(notif.groupId, (this.props.APP_DATA.connectedUser as I_User).id, notif.noteId, (data: I_RESULT) => {
                    if (data.status) {
                        let tmpStateNotes: I_NOTE_ITEM[] = this.state.stateNotes;
                        let tmpNote: I_NOTE_ITEM = data.data as I_NOTE_ITEM;
                        if (tmpNote !== undefined && tmpStateNotes.filter(sn => sn !== undefined && sn.idnote === tmpNote.idnote && sn.isAuthor).length === 0) {
                            tmpStateNotes.push(tmpNote);
                            this.setState({ stateNotes: tmpStateNotes });
                        }
    
                    } else {
                        //-->
                    }
                }, true)
            },500)
           
        });


    }

    getAllStateComments = (noteId?: number) => {
        if (noteId !== undefined) {
            GetComentsOfNote(noteId, (data: I_RESULT) => {
                if (data.status) {
                    //Recherche du commentaire ne question
                    const noteComments: I_COMMENT_NOTE_ITEM[] = data.data as I_COMMENT_NOTE_ITEM[];
                    let tmpComments: I_COMMENT_NOTE_ITEM[] = this.state.stateComments;

                    (noteComments.filter(nc => this.props.APP_DATA.allNotifs.filter(n => n.noteId === nc.idComent && n.readed).length > 0)).forEach(nc => {
                        if (this.state.stateComments.filter(sc => sc.idComent === nc.idComent).length === 0) {
                            tmpComments.push(nc);
                        }
                    });


                    this.setState({ stateComments: tmpComments });
                } else {
                    //-->   
                }
            })
        }
        else {
            (this.props.APP_DATA.allNotifs).filter(notif => notif.readed).forEach(notif => {
                GetComentById(notif.noteId, (data: I_RESULT) => {
                    if (data.status) {

                        let tmpComent: I_COMMENT_NOTE_ITEM = data.data[0] as I_COMMENT_NOTE_ITEM;

                        if (tmpComent === undefined) {
                            return
                        }


                        const noteId: number = tmpComent.idNote;
                        if (this.state.stateComments.filter(sc => sc.idComent === tmpComent.idComent).length === 0) {
                            let tmpAllComents: I_COMMENT_NOTE_ITEM[] = this.state.stateComments;
                            tmpAllComents.push(tmpComent);
                            this.setState({ stateComments: tmpAllComents });
                        }


                        GetNote(notif.groupId, (this.props.APP_DATA.connectedUser as I_User).id, noteId, (data: I_RESULT) => {
                            if (data.status) {
                                let tmpNote: I_NOTE_ITEM = data.data as I_NOTE_ITEM;
                                if (tmpNote !== undefined) {
                                    let tmpStateNote: I_NOTE_ITEM[] = this.state.stateNotes;
                                    if (this.state.stateNotes.filter(n => n.idnote === tmpNote.idnote).length === 0) {
                                        tmpStateNote.push(tmpNote);
                                        this.setState({ stateNotes: tmpStateNote });
                                    }
                                }
                            } else {
                                //-->
                            }
                        }, true)
                    } else {
                        //-->
                    }
                })
            })
        }
    }

    openElementAsMemory = (noteElement: I_NOTE_ITEM) => {
        (this.props.APP_FUNCTION.onLoadNoteAsMemory as Function)(noteElement, undefined, ENU_ICON_TYPE.note);
    }

    componentDidUpdate(prevProps: Readonly<I_HOME_STATE_PROPS>, prevState: Readonly<I_HOME_STATE_PROPS_STATE>, snapshot?: any): void {
        if ((prevProps.APP_DATA.allNotifs as I_NOTIF[]).length > (this.props.APP_DATA.allNotifs as I_NOTIF[]).length) {
            this.setState({ stateNotes: [], stateComments: [], stateTasks: [] })
        }
    }

    loadingDataApp = () => {
        if (this.props.APP_DATA.selectedGroup === undefined) {

            
            this.getTaskForGroup();
        }

        this.getAllStateNotes();

        setTimeout(async () => {
            this.computeNotifCount();
            this.loadingDataApp();
            this.getAllStateNotes();
        }, 4000)
    }

    componentDidMount(): void {
        // (this.props.APP_FUNCTION.onShowLoader as Function)(true);
        this.getAllStateNotes();
        // this.getAllStateComments();
        // this.computeNotifCount();
        // this.getTaskForGroup();

        // setTimeout(() => {
            this.loadingDataApp();
        // }, 1000);
    }


    render(): JSX.Element {
        return <div className="HOME_STATE_BOX">
            {
                this.props.APP_DATA.DB_CONFIG !== undefined &&
                <VERSION_CHECKER GLOBAL_DATA={this.props.APP_DATA} GLOBAL_FUNCTION={this.props.APP_FUNCTION} />
            }


            {/* les taches */}
            <div>
                <span className="HOME_STATE_TITLE_ZONE">Tache(s)</span>
                <span>
                    {
                        this.state.stateTasks.length > 0 &&
                        <span className="STATE_COUNT_DOT">{this.state.stateTasks.length}</span>
                    }
                </span>
                <div className="HOME_STATE_NOTIF_MAIN">
                    {
                        this.state.stateTasks.length === 0 &&
                        <div style={{ textAlign: "center" }} dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.task, 125, "e1e1e1") }}>

                        </div>
                    }
                    {
                        //Chargement des données
                        this.props.APP_DATA.loadingPendingData &&
                        this.props.APP_DATA.connectedUser !== undefined &&
                        <LOADER_BASIC />
                    }

                    {
                        this.state.stateTasks.length > 0 &&
                        this.props.APP_DATA.DB_GROUPS !== undefined &&
                        (GetDistinctListOfString(this.state.stateTasks.map(t => { return t.taskGroupId as any })).map((g, index) => {
                            return <div key={g + "-" + index} style={{ marginBottom: "14px" }}>
                                <div onClick={() => { this.onSelectGroupNote(parseInt(g)) }} className="HOME_STATE_GROUP_NAME">
                                    <span dangerouslySetInnerHTML={{
                                        __html: GetIconFromType(
                                            this.state.selectedGroupNoteTask.filter(t => t === parseInt(g)).length > 0 ? ENU_ICON_TYPE.openedFolder : ENU_ICON_TYPE.folder
                                            , 16)
                                    }}></span>
                                    &nbsp;
                                    <span >
                                        {
                                            this.props.APP_DATA.DB_GROUPS !== undefined &&
                                            this.props.APP_DATA.DB_GROUPS.filter(_g => _g.id === parseInt(g)).length > 0 &&
                                            this.props.APP_DATA.DB_GROUPS.filter(_g => _g.id === parseInt(g))[0].title
                                        }
                                    </span>
                                    <span className="SPAN_SEP">
                                    </span>
                                    <span style={{ color: "red" }}>
                                        {
                                            this.state.stateTasks
                                                .filter(_t => parseInt(_t.taskGroupId as any) === parseInt(g)).length
                                        }
                                    </span>
                                </div>
                                <div>
                                    {
                                        this.state.stateTasks
                                            .filter(_t => _t.taskNoteTitle !== undefined && parseInt(_t.taskGroupId as any) === parseInt(g) && this.state.selectedGroupNoteTask.filter(_g => _g === parseInt(g)).length > 0)
                                            .sort((a, b) => {
                                                return (
                                                    (new Date(parseInt(b.taskdate.split("/")[2]), parseInt(b.taskdate.split("/")[1]), parseInt(b.taskdate.split("/")[0]))).getTime()
                                                    -
                                                    (new Date(parseInt(a.taskdate.split("/")[2]), parseInt(a.taskdate.split("/")[1]), parseInt(a.taskdate.split("/")[0]))).getTime()
                                                )
                                            })
                                            .map((t, index) => {
                                                return <div key={t.idtask + "-" + index} className="HOME_STATE_ITEM" onClick={() => {
                                                    //Controle de la note partiel et chargement des données relatives
                                                    (this.props.APP_FUNCTION.onLoadNoteAsMemory as Function)(
                                                        {
                                                            idgroupnote: t.taskGroupId !== undefined ? t.taskGroupId : undefined,

                                                            associations: "",
                                                            idnote: t.idnote,
                                                            title: this.props.APP_DATA.DB_NOTES !== undefined ? (this.props.APP_DATA.DB_NOTES as I_NOTE_ITEM[]).filter(n => n.idnote === t.idnote)[0].title : "",
                                                            typeNoteString: "",
                                                            colorString: "",
                                                            color: 0,
                                                            type: this.props.APP_DATA.DB_NOTES !== undefined ? (this.props.APP_DATA.DB_NOTES as I_NOTE_ITEM[]).filter(n => n.idnote === t.idnote)[0].type : 0,
                                                            tags: this.props.APP_DATA.DB_NOTES !== undefined ? (this.props.APP_DATA.DB_NOTES as I_NOTE_ITEM[]).filter(n => n.idnote === t.idnote)[0].tags : "",
                                                            displayUnity: this.props.APP_DATA.DB_NOTES !== undefined ? (this.props.APP_DATA.DB_NOTES as I_NOTE_ITEM[]).filter(n => n.idnote === t.idnote)[0].displayUnity : 1,
                                                            pined: false,
                                                            created: "", modified: "", description: "", descriptionraw: "", isAuthor: 0, autor: "", editor: ""
                                                        } as I_NOTE_ITEM, undefined, ENU_ICON_TYPE.task
                                                    );
                                                }}>
                                                    <span className="HOME_STATE_NOTE_TITLE">
                                                        <div>
                                                            <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.note, 11) }}></span>
                                                            &nbsp;
                                                            <span style={{ textDecoration: "underLine", fontWeight: "bold" }}>{t.taskNoteTitle as string}</span>
                                                        </div>
                                                        <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.pendingtasks, 11) }}></span>
                                                        &nbsp;
                                                        <span style={{ color: "#f30000" }}>{t.taskdate}</span>
                                                        <span className="SPAN_SEP"></span>
                                                        <span style={{ fontStyle: "italic" }}>{t.title}</span>


                                                    </span>

                                                </div>
                                            })
                                    }
                                </div>
                            </div>
                        }))
                    }

                </div>
            </div>

            {/* les notification */}
            <div>
                <span className="HOME_STATE_TITLE_ZONE">Notification(s)</span>
                <span>
                    {
                        (this.props.APP_DATA.allNotifs !== undefined &&
                            (this.props.APP_DATA.allNotifs as I_NOTIF[]).length > 0 && this.state.stateNotes.filter(sn => sn !== undefined).length > 0) &&
                        <span className="STATE_COUNT_DOT">{this.state.notifCount}</span>
                    }
                </span>

                <div className="HOME_STATE_NOTIF_MAIN">
                    {
                        // Si rien à afficher
                        this.state.stateNotes.filter(sn => sn !== undefined).length === 0 &&
                        <div style={{ textAlign: "center" }}>
                            <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.notif, 125, "e1e1e1") }}></span>
                        </div>
                    }

                    {
                        //Chargement des données
                        this.props.APP_DATA.loadingPendingData &&
                        this.props.APP_DATA.connectedUser !== undefined &&
                        <LOADER_BASIC />
                    }

                    {/* Toutes les notifications */}
                    {
                        this.state.stateNotes.length > 0 &&
                        this.state.stateNotes
                            .sort((a, b) => {
                                return (
                                    (new Date(parseInt(b.created.split("/")[2].split(" ")[0]), parseInt(b.created.split("/")[1]), parseInt(b.created.split("/")[0]))).getTime()
                                    -
                                    (new Date(parseInt(a.created.split("/")[2].split(" ")[0]), parseInt(a.created.split("/")[1]), parseInt(a.created.split("/")[0]))).getTime()
                                )
                            })
                            .filter(sn => sn !== undefined)
                            .map((sn, index) => {
                                return <div key={sn.idnote + "-" + index} className="HOME_STATE_ITEM" style={{ cursor: "pointer" }} onClick={() => {
                                    this.openElementAsMemory(sn);
                                }}>
                                    <div>
                                        {
                                            (sn.isAuthor === 1 &&
                                                (this.props.APP_DATA.allNotifs as I_NOTIF[]).filter(n => n.noteId === sn.idnote && !n.readed).length > 0) &&
                                            <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.authorItem, 11) }}>

                                            </span>
                                        }

                                        {
                                            (sn.isAuthor === 0 &&
                                                (this.props.APP_DATA.allNotifs as I_NOTIF[]).filter(n => n.noteId === sn.idnote && !n.readed).length > 0) &&
                                            <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.editor, 11) }}>

                                            </span>
                                        }
                                        &nbsp;
                                        <span className="HOME_STATE_GROUP_NAME">
                                            {
                                                this.props.APP_DATA.DB_GROUPS !== undefined &&
                                                this.props.APP_DATA.DB_GROUPS.filter(g => g.id === sn.idgroupnote).length > 0 &&
                                                this.props.APP_DATA.DB_GROUPS.filter(g => g.id === sn.idgroupnote)[0].title
                                            }
                                        </span>
                                        <span className="SPAN_SEP"></span>
                                        <span className="HOME_STATE_NOTE_TITLE">{
                                            sn.title
                                        }</span>
                                        <span className="SPAN_SEP"></span>
                                        {
                                            (this.props.APP_DATA.allNotifs as I_NOTIF[]).filter(n => n.noteId === sn.idnote && !n.readed).length > 0 &&
                                            <div style={{ fontSize: "11px", textAlign: "left", paddingLeft: "18px" }}>
                                                <span>
                                                    {
                                                        sn.editor
                                                    }
                                                </span>
                                                <span className="SPAN_SEP"></span>
                                                <span>{
                                                    sn.modified
                                                }</span>
                                            </div>
                                        }

                                    </div>
                                    <div className="HOME_STATE_COMENT_ZONE">
                                        {
                                            this.state.stateComments.filter(c => c.idNote === sn.idnote).map((c, index) => {
                                                return <div key={c.idComent + "-" + index}>
                                                    <span dangerouslySetInnerHTML={{ __html: GetIconFromType(ENU_ICON_TYPE.talk, 11) }}></span>
                                                    <span>{c.author}</span>
                                                    <span>.</span>
                                                    <span>{c.created}</span>
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                            })
                    }
                </div>
            </div>



        </div>
    }
}